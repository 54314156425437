import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import masterProgram from "../../../Components/assets/lithuMaster.json";
import { NavLink } from 'react-router-dom';

const MastersProgram = () => {
  return (
    <div className="md:-mt-[40px] w-full">
      <h4 className="text-[#1DB459] md:leading-[30px] text-[27px] md:text-[40px] font-[500] ">Masters Degree</h4>
      <div className="flex flex-col gap-7">
    {masterProgram?.program?.map((datas:any, index:any) => {
      return (
        <div key={index} className="grid md:grid-cols-3 gap-5 shadow-xl p-3 ">
        <div>
          <h4 className="text-[#1DB459] leading-[40px] text-[30px]">{datas.name}</h4>
          <div className="pt-1">
            <h5>
              Duration: <span className="font-[600]">{datas.duration}</span>
            </h5>
            <h5>
              Application period:{" "}
              <span className="font-[600]">{datas.application_period}</span>
            </h5>
            <h5>
              Start studies: <span className="font-[600]">{datas.start_studies}</span>
            </h5>
          </div>
        </div>

        <div>
          <h4 className="text-[#1DB459] text-[20px]">Requirement</h4>
          <div className="pt-1">
            <h5 className="text-[16px] max-w-[300px]">
             {datas.requirement.map((dat:any) => (
              <div className="flex flex-col">{dat}</div>
             ))}
            </h5>
          </div>
        </div>

        <div>
          <h4 className=" text-[27px]">Tuition in Euros</h4>
          <div className="pt-1">
            <h5 className="text-[27px] lg:pl-6 font-[500] text-[#1DB459] ">{datas.tuition}</h5>
            <NavLink
      to={`/study-in-lithuania/study-program/${(datas?.name).replace(/ /g, "-")}`}
      state={{ datas }} className="flex gap-2 items-center w-fit text-white rounded-full bg-[#FFBA40] px-5 py-2">
              Proceed
              <FaChevronRight />
            </NavLink>
          </div>
        </div>
          </div>
      )
    }

    )}

      </div>
    </div>
  )
}

export default MastersProgram