import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { AuthApis } from "../../../apis/authApis";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../reducer/loginSlice";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function Login() {

  const [loader, setLoader] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   window.location.reload()
  // }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [showPassword, setShowPassword] = useState(false);

  const [userData, setUserdata] = useState({
    'email': "",
    'password': "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };
  console?.log(userLoginData)

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)

    const formData = new FormData()
    formData.append('email', userData?.email)
    formData.append('password', userData?.password)

  

    AuthApis.login(formData).then(
      (response) => {
        if (response?.data) {
          console.log(response.data.data.account_id)
          if (response?.data?.success === true) {
      setLoader(false)
      toast.success(response?.data?.message);

            // console?.log(response.data.data)
            if (dispatch(login({ email: userData?.email,isVerified:response.data.data?.isVerified, token: response.data.data.token, id: response.data.data.account_id, name: response.data.data.name, data: response.data.data }))) {
              if (response?.data?.data?.user_data?.role == 'admin') {
                navigate('/admin-dashboard');
              } else if(response?.data?.data?.user_data?.role == 'agent'){
                navigate('/agent-portal');
              } else if(response?.data?.data?.user_data?.address !== null && response?.data?.data?.user_data?.d_o_b !== null && response?.data?.data?.user_data?.interested_course !== null && response?.data?.data?.user_data?.place_of_birth !== null) {
                 navigate('/wallet');
                console?.log(response?.data?.data?.user_data)
              } else {
                // console?.log('no')
                navigate('/user-profile');
              }

              // 
              window.location.reload()
            }




          }
        } else {
      setLoader(false)
      console.log(response);

      toast.error("An error occured");

          // toast.warn('Invalid Login Credentials');
        }
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      toast.error(error.response.data?.message);
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }



  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-white  body-font font-poppins ">
        <NavLink to={"/"}>
          <div className=" flex space-x-2 px-8 pt-8 font-semibold text-[#48B774] cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M21 11H6.83l3.58-3.59L9 6l-6 6l6 6l1.41-1.42L6.83 13H21v-2Z" /></svg>
            <p>Go back </p>
          </div>
        </NavLink>
        <div className="grid  max-w-screen-xl  lg:gap-8 xl:gap-0 lg:grid-cols-12 lg:mt-8">
          {/* grid max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 */}

          <div className="hidden lg:col-span-6 lg:flex w-full">
            <div className="relative">
              <img src="/images/sign-inn.png" alt="sign-in" className=" h-[800px]" />
              <div className="absolute bottom-0 left-0 right-0 px-10 py-14 bg-gradient-to-t from-[#05401C]">
                <h3 className="text-3xl text-white font-bold">
                  Welcome Back ! <br />
                  Lets get it right
                </h3>
                <p className="mt-6 text-sm text-gray-300">
                  You have chosen a great path to invest in your future and we are with you on this Journey from start to finish.
                </p>
              </div>
            </div>
          </div>

          <div className="lg:px-24 lg:col-span-6 lg:mt-40 mt-10 px-8">
            <h1 className="max-w-2xl text-3xl text-[#48B774] font-semibold leading-10 md:text-3xl xl:text-4xl text-center ">
              Welcome Back
            </h1>
            <p className="text-xs mb-10 text-center">
              Enter the fields below to get started
            </p>
            {/* <div className="mt-6 text-center">
              <button
                type="button"
                className="w-full text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 font-medium rounded-lg text-sm lg:px-36 px-2 py-2.5 text-center inline-flex items-center  mr-2 mb-2"
              >
                <img src="/images/logo-google-icon.svg" className="w-[20px] text-center mr-2" alt="sign-in" />
               <span className=""> Sign in with Google</span>
              </button>
              
            </div> */}

            {/* <div class="flex items-center py-4">
              <!-- The left line -->
              <div class="flex-grow h-px bg-[#D9D9D9]"></div>

              <!-- Your text here -->
              <span class="flex-shrink text-base text-gray-500 px-4 font-light">
                or
              </span>

              <!-- The right line -->
              <div class="flex-grow h-px bg-[#D9D9D9]"></div>
            </div> */}

            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="mb-6 w-full">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className=" mb-6">
                  <label className="block mb-2 mt-2 text-sm font-semibold text-[#0A2E04]">
                    Password
                  </label>
                  <div className="relative">
                  <input
                   type={!showPassword ? "password" : "text"}
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                    required
                  />
                     <button
                  type="button"
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-4 top-3"
                >
                  {!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </button>
                </div>
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                {loader ? <LoadingSpinner /> : "Log In"} 
                </button>
                <NavLink to={"/forgot-password"} className="mt-2 text-right">
                  <p className="text-[#48B774] text-sm font-semibold">Forgot Password?</p>
                </NavLink>

                <p className="text-center text-xs font-semibold mt-4">
                  Don't have an account?{" "}
                  <NavLink to={'/terms'}>
                    <span className="text-[#48B774] cursor-pointer font-bold">sign up</span>
                  </NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      {/* <!-- End block --> */}
    </>
  );
}

export default Login;
