import React, { useState } from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthApis } from '../../../apis/authApis';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { AdminApis } from '../../../apis/adminApi';
import { useNavigate } from "react-router-dom";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import configs from '../../../configs';
//@ts-ignore
import Modal from 'react-awesome-modal';

function EditCourse() {

  const [schoolList, setSchoolList] = React.useState<any>([])
  React.useEffect(() => {
    AdminApis.getPartnerSchool('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data?.success) {
          setSchoolList(response?.data?.data)
        } else {
          // dispatch(login([]))
          // navigate('/login')
        }
      }
    );

  }, [])

  var fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = ['12px', '14px', '20px', '24px'];
  Quill.register(fontSizeStyle, true);


  

  var toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'size': fontSizeStyle.whitelist }],
    [{ 'font': [5, 6, 7, 8, 9, 10, 11, 12, 131, 4, 16, 19, 23] }],
    ['bold', 'italic', 'underline', 'link'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ['clean']                                         // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions
  }



  const params: any = useParams();
  let partnerId: string = params?.courseId;



  let [visible, setVisible] = React.useState(false);
  function toggleModal() {
    setVisible(!visible)
  }

  const deleteCourse = React.useCallback(
    (e: any) => {
      AdminApis.deletePartnerCourse(partnerId).then(
        (response: any) => {
          if (response?.data) {
            console.log(response?.data?.data)
            toast.success(response?.data?.message);
            setVisible(false)
            navigate('/course-list');
          }
        }
      ).catch((err: any) => {
        if (err?.response?.status == 422) {
          //   toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          //   toast.error('Some error occured.Please try again');
        }


      })
    },
    []
  );

  const [fetchedData, setFetchedData] = React.useState<any>([])
  const [fetchedCareerData, setFetchedCareerData] = React.useState<any>([])
  const [careerOverview, setCareerOverview] = React.useState<any>([])






  const [coverPhotoName, SetCoverPhotoName] = React.useState<any>(fetchedData?.cover_photo)
  const [courseOverview, setCourseOverview] = React.useState<any>(fetchedData?.overview)
  const [aimOfTraining, setAimOfTraining] = React.useState<any>(fetchedData?.training_aim)
  const [adminssionRequirement, setAdmissionrequirement] = React.useState<any>(fetchedData?.admission_requirement)
  const [courseOutline, setCourseOutline] = React.useState<any>(fetchedData?.course_outline)
  const [career, setCareer] = React.useState<any>(fetchedData?.career)
  const [careerPath, setCareerPath] = React.useState<any>(fetchedData?.career_paths)
  const [learning, setLearning] = React.useState<any>(fetchedData?.learning_outcomes)
  const [learningAdd, setLearningAdd] = useState([{ title: '', description: '' }]); // State to manage multiple courses

  const [eligibility, setEligibility] = React.useState<any>(fetchedData?.eligibilities)
  const [eligibilityAdd, setEligibilityAdd] = useState([{ name: '' }]); // State to manage multiple courses
  const [language, setLanguage] = React.useState<any>(fetchedData?.language_proficiencies)
  const [languageAdd, setLanguageAdd] = useState([{ type: '', requirement: '' }]); // State to manage multiple courses


  React.useEffect(() => {
    AdminApis.getSingleCourse(partnerId).then(
      (response: AxiosResponse<any>) => {
        if (response?.data?.success) {
          console?.log(response?.data?.data)
          setFetchedData(response?.data?.data)
          SetCoverPhotoName(response?.data?.data?.cover_photo)
          setCourseOverview(response?.data?.data?.overview)
          setAimOfTraining(response?.data?.data?.training_aim)
          setAdmissionrequirement(response?.data?.data?.admission_requirement)
          setCourseOutline(response?.data?.data?.course_outline)
          setCareer(response?.data?.data?.career)
          setCareerPath(response?.data?.data?.career_paths)
          setLearning(response?.data?.data?.learning_outcomes)
          setEligibility(response?.data?.data?.eligibilities)
          setLanguage(response?.data?.data?.language_proficiencies)
        } else {
          // dispatch(login([]))
          // navigate('/login')
        }
      }
    );

  }, [])

  // console?.log(learning.length)


  const [errorMes, setErrorMes] = React.useState<any>("")
  const navigate = useNavigate();


  let [userData, setUserData] = React.useState({
    title: '', product_cost: "",
    tags: " ", school: "",
    category: "", ects: "",
    duration: "", website_url: "",
    twitter_link: "", instagram_link: "",
    facebook_link: "", video_url: "",
    key_1: "", value_1: "",
    key_2: "", value_2: "",
    key_3: "", value_3: "",
    key_4: "", value_4: "",
    key_5: "", value_5: "",
    key_6: "", value_6: "",
  });

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }

  const [coverPhoto, setCoverPhoto] = React.useState('No selected file');
  const [coverPhotoPreview, setCoverPhotoPreview] = React.useState('No selected file');
  const coverPhotohandler = (e: any) => {
    // console?.log(e.target.files)
    const target = e.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name

    SetCoverPhotoName(e.target.files[0].name)
    // console?.log(size)
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == 'coverPhoto') {
          setErrorMes("File too big!")
          toast.error("File is greater than 3MB!");
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 3) {
        if (targetName == 'coverPhoto') {
          setErrorMes(" ")
          setCoverPhoto(e.target.files[0]);
          setCoverPhotoPreview(URL.createObjectURL(e.target.files[0]));

        }
      }
    }
  };


  let [showScreen, setShowScreen] = React.useState<any>(1);
  const [createdCourseId, setCreatedCourseId] = React.useState<any>(null); // Add this state to store the course ID

  const createSchoolProgram = React.useCallback(
    (e: any) => {
      e.preventDefault();
      if (showScreen === 1) {
        const blogData = new FormData();
        blogData.append('title', (userData?.title == '' ? fetchedData?.title : userData?.title));
        blogData.append('overview', courseOverview);
        blogData.append('training_aim', aimOfTraining);
        blogData.append('admission_requirement', adminssionRequirement);
        blogData.append('course_outline', courseOutline);
        blogData.append('career', career);
        blogData.append('id', partnerId);
        blogData.append('category', (userData?.category == '' ? fetchedData?.category : userData?.category));
        blogData.append('product_cost', (userData?.product_cost == '' ? fetchedData?.product_cost : userData?.product_cost));
        blogData.append('tags', (userData?.tags == '' ? fetchedData?.tags : userData?.tags));
        blogData.append('school', (userData?.school == '' ? fetchedData?.school : userData?.school));
        blogData.append('ects', (userData?.ects == '' ? fetchedData?.ects : userData?.ects));
        blogData.append('duration', (userData?.duration == '' ? fetchedData?.duration : userData?.duration));
        blogData.append('website_url', (userData?.website_url == '' ? fetchedData?.website_url : userData?.website_url));
        blogData.append('twitter_link', (userData?.twitter_link == '' ? fetchedData?.twitter_link : userData?.twitter_link));
        blogData.append('instagram_link', (userData?.instagram_link == '' ? fetchedData?.instagram_link : userData?.instagram_link));
        blogData.append('facebook_link', (userData?.facebook_link == '' ? fetchedData?.facebook_link : userData?.facebook_link));
        blogData.append('cover_photo', coverPhoto);
        blogData.append('video_url', (userData?.video_url == '' ? fetchedData?.video_url : userData?.video_url));
        blogData.append('key_1', (userData?.key_1 == '' ? fetchedData?.key_1 : userData?.key_1));
        blogData.append('value_1', (userData?.value_1 == '' ? fetchedData?.value_1 : userData?.value_1));
        blogData.append('key_2', (userData?.key_2 == '' ? fetchedData?.key_2 : userData?.key_2));
        blogData.append('value_2', (userData?.value_2 == '' ? fetchedData?.value_2 : userData?.value_2));
        blogData.append('key_3', (userData?.key_3 == '' ? fetchedData?.key_3 : userData?.key_3));
        blogData.append('value_3', (userData?.value_3 == '' ? fetchedData?.value_3 : userData?.value_3));
        blogData.append('key_4', (userData?.key_4 == '' ? fetchedData?.key_4 : userData?.key_4));
        blogData.append('value_4', (userData?.value_4 == '' ? fetchedData?.value_4 : userData?.value_4));
        blogData.append('key_5', (userData?.key_5 == '' ? fetchedData?.key_5 : userData?.key_5));
        blogData.append('value_5', (userData?.value_5 == '' ? fetchedData?.value_5 : userData?.value_5));
        blogData.append('key_6', (userData?.key_6 == '' ? fetchedData?.key_6 : userData?.key_6));
        blogData.append('value_6', (userData?.value_6 == '' ? fetchedData?.value_6 : userData?.value_6));
  
        AdminApis.updatePartnerCourse(blogData).then(
          (response: any) => {
            if (response?.data) {
              console.log(response?.data?.data)
              setCreatedCourseId(response?.data?.data?.id); // Store the course ID here
              toast.success(response?.data?.message);
              // navigate('/course-list');
              setShowScreen(2)
  
            }
          }
        ).catch((err: any) => {
          if (err?.response?.status === 422) {
            console?.log(err)
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(err?.response?.data?.message);
          }
  
  
        })
      }
      if (showScreen === 2) {
        // Create an array to hold the career paths payload
        const payload = {
          career_paths: careerPath.map((course: any) => ({
            id: course.id || null,
            course_id: fetchedData.id, // Assuming this is from state or props
            title: course.title,
            description: course.description,
          }))
        };
      
        // Log the payload to inspect its structure
        // console.log("Payload for Career Paths:", payload);
      
        // Make API calls for each career path individually using its `id`
        payload.career_paths.forEach((path:any) => {
          // Check if an `id` is present; if so, update, otherwise create a new one
          if (path.id) {
            // Update the existing career path using its `id`
            AdminApis.updateCourseCareerPath(path.id, path)
              .then((response: any) => {
                console.log(`Career Path ID ${path.id} updated successfully:`, response?.data);
                // toast.success(response?.data?.message);
                    // Show a success message once all updates are done
        toast.success("Career paths have been successfully updated!");
        setShowScreen(3); // Switch to another screen on success
              })
              .catch((error: any) => {
            toast.error(error?.response?.data?.message);

                console.error(`Error updating Career Path ID ${path.id}:`, error);
              });
          } else {
            // If `id` is not present, create a new career path
            AdminApis.createCourseCareerPath(path)
              .then((response: any) => {
                console.log("New Career Path created successfully:", response?.data);
              })
              .catch((error: any) => {
                console.error("Error creating new Career Path:", error);
              });
          }
        });
      

      }

      if (showScreen === 3) {
        if (learning && learning.length  > 0 ) {

      
        // Create an array to hold the career paths payload
        const payload = {
          learning_outcomes: learning?.map((course: any) => ({
            id: course.id || null,
            course_id: fetchedData.id, // Assuming this is from state or props
            title: course.title,
            description: course.description,
          }))
        };
      
        // Log the payload to inspect its structure
        // console.log("Payload for Career Paths:", payload);
      
        // Make API calls for each career path individually using its `id`
        payload.learning_outcomes.forEach((path:any) => {
          // Check if an `id` is present; if so, update, otherwise create a new one
          if (path.id) {
            // Update the existing career path using its `id`
            AdminApis.updateCourseLearningOutcome(path.id, path)
              .then((response: any) => {
                console.log(`Career learning outcome ${path.id} updated successfully:`, response?.data);
                // toast.success(response?.data?.message);
                    // Show a success message once all updates are done
        toast.success("Learning Outcomes have been successfully updated!");
        setShowScreen(4); // Switch to another screen on success
              })
              .catch((error: any) => {
            toast.error(error?.response?.data?.message);

                console.error(`Error updating Career Path ID ${path.id}:`, error);
              });
          } 
          // else {
          //   // If `id` is not present, create a new career path
          //   AdminApis.createCourseLearningOutcome(payload.learning_outcomes)
          //     .then((response: any) => {
          //       console.log("New Career Path created successfully:", response?.data);
          //     })
          //     .catch((error: any) => {
          //       console.error("Error creating new Career Path:", error);
          //     });
          // }
        });
      } else {

        const learningOutcomes = learningAdd.map((course, index) => ({
          course_id: fetchedData.id, // Assuming this is from state or props
          title: course.title,
          description: course.description,
      }));
// Send the JSON payload to the API
AdminApis.createCourseLearningOutcome(learningOutcomes)
.then((response: any) => {
    console.log(response?.data);
    toast.success(response?.data?.message);
    setShowScreen(4); // Switch to another screen on success
})
.catch((error: any) => {
    console.error(error);
});


      }


      }

      if (showScreen === 4) {
        if (eligibility && eligibility.length  > 0 ) {

      
        // Create an array to hold the career paths payload
        const payload = {
          eligibilities: eligibility?.map((course: any) => ({
            id: course.id || null,
            course_id: fetchedData.id, // Assuming this is from state or props
            name: course.name,
          }))
        };
      
        // Log the payload to inspect its structure
        // console.log("Payload for Career Paths:", payload);
      
        // Make API calls for each career path individually using its `id`
        payload.eligibilities.forEach((path:any) => {
          // Check if an `id` is present; if so, update, otherwise create a new one
          if (path.id) {
            // Update the existing career path using its `id`
            AdminApis.updateCourseEligibility(path.id, path)
              .then((response: any) => {
                console.log(`eligibility outcome ${path.id} updated successfully:`, response?.data);
                // toast.success(response?.data?.message);
                    // Show a success message once all updates are done
        toast.success("Eligibility have been successfully updated!");
        setShowScreen(5); // Switch to another screen on success
              })
              .catch((error: any) => {
            toast.error(error?.response?.data?.message);

                console.error(`Error updating Career Path ID ${path.id}:`, error);
              });
          } 
          // else {
          //   // If `id` is not present, create a new career path
          //   AdminApis.createCourseLearningOutcome(payload.learning_outcomes)
          //     .then((response: any) => {
          //       console.log("New Career Path created successfully:", response?.data);
          //     })
          //     .catch((error: any) => {
          //       console.error("Error creating new Career Path:", error);
          //     });
          // }
        });
      } else {

        const eligible = eligibilityAdd.map((course, index) => ({
          course_id: fetchedData.id, // Assuming this is from state or props
          name: course.name,
      }));
// Send the JSON payload to the API
AdminApis.createCourseEligibility(eligible)
.then((response: any) => {
    console.log(response?.data);
    toast.success(response?.data?.message);
    setShowScreen(5); // Switch to another screen on success
})
.catch((error: any) => {
    console.error(error);
});


      }


      }

      if (showScreen === 5) {
        if (language && language?.length  > 0 ) {

      
        // Create an array to hold the career paths payload
        const payload = {
         lang: language?.map((course: any) => ({
            id: course.id || null,
            course_id: fetchedData.id, // Assuming this is from state or props
            type: course.type,
            requirement: course.requirement,
          }))
        };
      
        // Log the payload to inspect its structure
        // console.log("Payload for Career Paths:", payload);
      
        // Make API calls for each career path individually using its `id`
        payload.lang.forEach((path:any) => {
          // Check if an `id` is present; if so, update, otherwise create a new one
          if (path.id) {
            // Update the existing career path using its `id`
            AdminApis.updateLanguageCourse(path.id, path)
              .then((response: any) => {
                console.log(`eligibility outcome ${path.id} updated successfully:`, response?.data);
                // toast.success(response?.data?.message);
                    // Show a success message once all updates are done
        toast.success("Eligibility have been successfully updated!");
        setShowScreen(5); // Switch to another screen on success
              })
              .catch((error: any) => {
            toast.error(error?.response?.data?.message);

                console.error(`Error updating Career Path ID ${path.id}:`, error);
              });
          } 
          // else {
          //   // If `id` is not present, create a new career path
          //   AdminApis.createCourseLearningOutcome(payload.learning_outcomes)
          //     .then((response: any) => {
          //       console.log("New Career Path created successfully:", response?.data);
          //     })
          //     .catch((error: any) => {
          //       console.error("Error creating new Career Path:", error);
          //     });
          // }
        });
      } else {

        const lang = languageAdd.map((course, index) => ({
          course_id: fetchedData.id, // Assuming this is from state or props
          type: course.type,
          requirement: course.requirement,
      }));
// Send the JSON payload to the API
AdminApis.createLanguageCourse(lang)
.then((response: any) => {
    console.log(response?.data);
    toast.success(response?.data?.message);
    setShowScreen(5); // Switch to another screen on success
})
.catch((error: any) => {
    console.error(error);
});


      }


      }
     
    },
    [userData, coverPhoto, courseOutline, courseOverview, aimOfTraining, career]
  );

  React.useEffect(() => {
    if (showScreen === 2 && createdCourseId) {
      AdminApis.getSingleCourse(createdCourseId).then((response: AxiosResponse<any>) => {
        if (response?.data?.success) {
          console.log('Fetched Course Data:', response?.data);
          // Handle the fetched course data here, e.g., update state
        } else {
          // Handle failure
        }
      }).catch((err: any) => {
        console.log('Error fetching course data:', err);
      });
    }
  }, [showScreen, createdCourseId]);
  
  const [courses, setCourses] = useState([{ title: '', careerDescription: '' }]); // State to manage multiple courses
  // const [showScreen, setShowScreen] = useState(1); // Control screens


  const handleAddCourse = () => {
    setCareerPath([...careerPath, { title: '', description: '' }]); // Add a new empty course object
  };

  const handleAddLearning = () => {
    setLearningAdd([...learningAdd, { title: '', description: '' }]); // Add a new empty course object
  };

  const handleAddEligibility = () => {
    setEligibilityAdd([...eligibilityAdd, { name: '' }]); // Add a new empty course object
  };

  const handleAddLanguage = () => {
    setLanguageAdd([...languageAdd, { type: '', requirement: '' }]); // Add a new empty course object
  };

  const handleRemoveCourse = (index: number) => {
    const newCourses = careerPath.filter((_:any, i:any) => i !== index);
    setCareerPath(newCourses);
  };

  const handleRemoveLearning = (index: number) => {
    const newCourses = learningAdd.filter((_:any, i:any) => i !== index);
    setLearningAdd(newCourses);
  };

  const handleRemoveEligibility = (index: number) => {
    const newCourses = eligibilityAdd.filter((_:any, i:any) => i !== index);
    setEligibilityAdd(newCourses);
  };

  const handleRemoveLanguage = (index: number) => {
    const newCourses = languageAdd.filter((_:any, i:any) => i !== index);
    setLanguageAdd(newCourses);
  };
  
  const handleChange2 = (index: number, field: string, value: string) => {
    const newCourses:any = [...careerPath];
    newCourses[index][field] = value;
    setCareerPath(newCourses);
  };

  const handleChange3 = (index: number, field: string, value: string) => {
    const newCourses:any = [...learningAdd];
    newCourses[index][field] = value;
    setLearningAdd(newCourses);
  };

  const handleChange4 = (index: number, field: string, value: string) => {
    const newCourses:any = [...eligibilityAdd];
    newCourses[index][field] = value;
    setEligibilityAdd(newCourses);
  };

  const handleChange5 = (index: number, field: string, value: string) => {
    const newCourses:any = [...languageAdd];
    newCourses[index][field] = value;
    setLanguageAdd(newCourses);
  };

  const handleChangeEdit3 = (index: number, field: string, value: string) => {
    const newCourses:any = [...learning];
    newCourses[index][field] = value;
    setLearning(newCourses);
  };

  const handleChangeEdit4 = (index: number, field: string, value: string) => {
    const newCourses:any = [...eligibility];
    newCourses[index][field] = value;
    setEligibility(newCourses);
  };

  const handleChangeEdit5 = (index: number, field: string, value: string) => {
    const newCourses:any = [...language];
    newCourses[index][field] = value;
    setLanguage(newCourses);
  };

  // React.useEffect(() => {
  //   AdminApis.getCourseCareerPath(partnerId).then(
  //     (response: AxiosResponse<any>) => {
  //       if (response?.data?.success) {
  //          console?.log(response?.data?.data)
  //         setFetchedCareerData(response?.data?.data)
  //         // SetCoverPhotoName(response?.data?.data?.cover_photo)
  //         setCourseOverview(response?.data?.data?.overview)
          
  //       } else {
  //         // dispatch(login([]))
  //         // navigate('/login')
  //       }
  //     }
  //   );

  // }, [])

  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();

    // Create FormData object and append each course
    const formData = new FormData();
    careerPath.forEach((course:any, index:any) => {
      formData.append('course_id', createdCourseId);
      formData.append(`career[${index}][title]`, course.title);
      formData.append(`career[${index}][careerDescription]`, course.careerDescription);
    });

    // Send data to your endpoint
    AdminApis.updatePartnerCourse( formData) // Adjust the API method accordingly
      .then((response: any) => {
        // Handle response
        console.log(response?.data);
        toast.success(response?.data?.message);
        setShowScreen(3); // Switch to another screen after successful submission
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const deleteEachEligibility = (data:any) =>  {
      AdminApis.deleteCourseEligibility(data).then(
        (response: any) => {
          if (response?.data) {
            console.log(response?.data?.data)
            toast.success(response?.data?.message);
            // setVisible(false)
            // navigate('/course-list');
          }
        }
      ).catch((err: any) => {
        if (err?.response?.status == 422) {
          //   toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          //   toast.error('Some error occured.Please try again');
        }


      })
    }

    const deleteEachLanguage = (data:any) =>  {
      AdminApis.deleteLanguageCourse(data).then(
        (response: any) => {
          if (response?.data) {
            console.log(response?.data?.data)
            toast.success(response?.data?.message);
            // setVisible(false)
            // navigate('/course-list');
          }
        }
      ).catch((err: any) => {
        if (err?.response?.status == 422) {
          //   toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          //   toast.error('Some error occured.Please try again');
        }


      })
    }
  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />
        <div className="lg:pt-10 lg:px-10 px-6 ">
          <div className='flex justify-between'>
            <h3 className=' font-bold text-[31px]'>Edit Course</h3>
            <span> <button type='button' onClick={toggleModal} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md"> Delete</button></span>

          </div>
        </div>
        
        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#D9D9D9] mx-6 lg:mt-4 lg:mx-10 mt-5">
          <form 
          onSubmit={createSchoolProgram}
          >
          <div className={showScreen === 1 ? "block " : "hidden"}>

            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
              <div className='basis-8/12'>
                {/* upload image */}
                <div className="w-full lg:w-12/12">
                  <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col items-center justify-center w-full h-[96px] rounded-[5px] cursor-pointer my-4 mb-6">
                      {/* <div className="flex flex-col items-center justify-center pt-6 "> */}
                      <img className="" src={coverPhoto == 'No selected file' ? fetchedData?.image_url : coverPhotoPreview} alt={fetchedData?.cover_photo} style={{ maxHeight: "10vh", minWidth: "50%", maxWidth: '50%', minHeight: "20vh" }} />
                      {/* </div> */}
                      <input id="dropzone-file" name='coverPhoto' onChange={coverPhotohandler} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                    </label>
                  </div>
                </div>
                <div className='mt-[30px]'>
                  <h3 className='text-[#1DB459] text-[15px] font-semibold'>Basic Information</h3>
                  <div className="w-full lg:w-12/12  pt-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                        htmlFor="grid-password"
                      >
                        Course Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        defaultValue={fetchedData?.title}
                        onChange={handleChange}
                        className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                        placeholder=""

                      />
                    </div>
                  </div>
                </div>

                <div className="w-full lg:w-12/12 md:mb-[60px] mb-[120px]">
                  <div className='mb-[10px] text-[#303840] text-[13px] font-medium'>Course overview</div>
                  <ReactQuill theme="snow"
                    modules={module}
                    value={courseOverview}
                    onChange={setCourseOverview}
                    placeholder={'Content of the Editor'}
                    className='h-60' />
                </div>


                <div className='mt-[120px]'>
                   <div className='mb-[10px] text-[#303840]  font-medium'>Key Information</div>
                  <div className='  border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 1
                        </label>
                        <input
                          type="text"
                          name="key_1" onChange={handleChange}
                          defaultValue={fetchedData?.key_1}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 1
                        </label>
                        <input
                          type="text"
                          name="value_1" onChange={handleChange}
                          defaultValue={fetchedData?.value_1}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 2
                        </label>
                        <input
                          type="text"
                          name="key_2" onChange={handleChange}
                          defaultValue={fetchedData?.key_2}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 2
                        </label>
                        <input
                          type="text"
                          name="value_2" onChange={handleChange}
                          defaultValue={fetchedData?.value_2}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 3
                        </label>
                        <input
                          type="text"
                          name="key_3" onChange={handleChange}
                          defaultValue={fetchedData?.key_3}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 3
                        </label>
                        <input
                          type="text"
                          name="value_3" onChange={handleChange}
                          defaultValue={fetchedData?.value_3}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 4
                        </label>
                        <input
                          type="text"
                          name="key_4" onChange={handleChange}
                          defaultValue={fetchedData?.key_4}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 " >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 4
                        </label>
                        <input
                          type="text"
                          name="value_4" onChange={handleChange}
                          defaultValue={fetchedData?.value_4}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6 " >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 5
                        </label>
                        <input
                          type="text"
                          name="key_5" onChange={handleChange}
                          defaultValue={fetchedData?.key_5}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 5
                        </label>
                        <input
                          type="text"
                          name="value_5" onChange={handleChange}
                          defaultValue={fetchedData?.value_5}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 6
                        </label>
                        <input
                          type="text"
                          name="key_6" onChange={handleChange}
                          defaultValue={fetchedData?.key_6}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 6
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.value_6}
                          name="value_6" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                  </div>
                  </div>

              </div>
              <div className='basis-4/12 md:ml-[66px]'>
                <div className='mt-10 md:mt-0'>
                  <h3 className='mb-3 text-[#303840] font-medium'>Video</h3>
                  <div className=' flex-col border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#303840] text-sm font-medium md:pl-1 mb-2 mt-1"
                            htmlFor="grid-password"
                          >
                            URL
                          </label>
                          <input
                            type="text"
                            defaultValue={fetchedData?.video_url}
                            name="video_url" onChange={handleChange}
                            className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                            placeholder=""

                          />
                          <span className='text-[12px] font-medium text-[#6E768C]'>Enter a valid video URL.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-10'>
                  <h3 className='text-[#303840] my-3 font-medium'>Options</h3>
                  <div className=' flex-col border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                            htmlFor="grid-password"
                          >
                            Category
                          </label>
                          <select
                            id="category"

                            name="category" onChange={handleChange}
                            className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option selected>{fetchedData?.title}</option>
                            <option value="vocational">Vocational</option>
                            {/* <option value="high school">High School</option> */}
                            <option value="degree program">Degree Program</option>
                            <option value="master's program">Master's Program</option>
                          </select>
                          <span className='text-[12px] font-medium text-[#6E768C]'>Select a category.</span>
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Product Cost
                        </label>
                        <div className="flex">
                          <span className="inline-flex items-center px-3 text-sm text-gray-900 border border-r-0 border-gray-300 rounded-l-md ">
                            €
                          </span>
                          <input type="number" defaultValue={fetchedData?.product_cost} name="product_cost" onChange={handleChange} id="website-admin" className="rounded-none rounded-r-lg  border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5" placeholder="" />
                        </div>
                        <span className='text-[12px] font-medium text-[#6E768C]'>The recommended price is between €10,700 and €12,400</span>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Tags
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.tags}
                          name="tags" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                        <span className='text-[12px] font-medium text-[#6E768C]'>Input one or more tags.</span>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Campus ({fetchedData?.school})
                        </label>
                        <select
                          id="category"
                          name="school" onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                        >
                          <option selected></option>

                          {schoolList?.data?.map(
                            (datas: any, index: any) => (
                              <option value={datas?.school_name}>{datas?.school_name}</option>
                            )
                          )
                          }

                        </select>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Location
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.ects}
                          name="ects" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Duration
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.duration}
                          name="duration" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button
                onClick={createSchoolProgram}
                 type='submit' className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">Update Course</button>
              </div>
            </div>
            </div>


            <div className={showScreen === 2 ? "block " : "hidden"}>
            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
            <div className='basis-8/12'>
            <h3 className='text-[#1DB459] mb-[30px] text-[15px] font-semibold'>Career Paths for Graduates of the Program</h3>
              
            {careerPath?.map((course:any, index:any) => (
            <div key={index} className="course-block mb-8">
              {/* Course Title */}
              <div className="w-full lg:w-12/12 ">
                <div className="relative w-full mb-6">
                  <label
                    className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                    htmlFor={`title-${index}`}
                  >
                    Career Title
                  </label>
                  <input
                    type="text"
                    name={`title-${index}`}
                    defaultValue={course.title}
                    onChange={(e) => handleChange2(index, 'title', e.target.value)}
                    className="border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                    placeholder="Enter career title"
                  />
                </div>
              </div>

              {/* Career Overview */}
              <div className="w-full lg:w-12/12 mb-[100px]">
                <div className="mb-[5px] text-[#303840] font-medium">Course Overview</div>
                <ReactQuill
                  theme="snow"
                  modules={module}
                  value={course.description}
                  onChange={(value) => handleChange2(index, 'description', value)}
                  placeholder="Content of the editor"
                  className="h-40"
                />
              </div>

              {/* Remove button if there are more than one course block */}
              {careerPath.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveCourse(index)}
                  className="text-red-500"
                >
                  Remove Course
                </button>
              )}
            </div>
          ))}

          {/* Button to add new course block */}
          <button type="button" onClick={handleAddCourse} className="mb-4 bg-blue-500 text-white p-2 rounded">
            Add Another Course
          </button>


              </div>
           
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button 
                onClick={createSchoolProgram} className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">Add Career path</button>
              </div>
            </div>

            </div>

            <div className={showScreen === 3 ? "block " : "hidden"}>
            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
            <div className='basis-8/12'>
            <h3 className='text-[#1DB459] mb-[30px] text-[15px] font-semibold'>Edit Learning Outcomes </h3>
              
            { learning && learning?.length > 0 ? 
            learning?.map((course:any, index:any) => (
            <div key={index} className="course-block mb-8">
              {/* Course Title */}
              <div className="w-full lg:w-12/12 ">
                <div className="relative w-full mb-6">
                  <label
                    className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                    htmlFor={`title-${index}`}
                  >
                    Learning Outcome Title
                  </label>
                  <input
                    type="text"
                    name={`title-${index}`}
                    defaultValue={course.title}
                    onChange={(e) => handleChangeEdit3(index, 'title', e.target.value)}
                    className="border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                    placeholder="Enter career title"
                  />
                </div>
              </div>

              {/* Career Overview */}
              <div className="w-full lg:w-12/12 mb-[100px]">
                <div className="mb-[5px] text-[#303840] font-medium">Learning outcome Overview</div>
                <ReactQuill
                  theme="snow"
                  modules={module}
                  value={course.description}
                  onChange={(value) => handleChangeEdit3(index, 'description', value)}
                  placeholder="Content of the editor"
                  className="h-40"
                />
              </div>

              {/* Remove button if there are more than one course block */}
              {/* {learning.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveLearning(index)}
                  className="text-red-500"
                >
                  Remove Learning
                </button>
              )} */}
            </div>
          )) : 
          learningAdd?.map((course:any, index:any) => (
            <div key={index} className="course-block mb-8">
              {/* Course Title */}
              <div className="w-full lg:w-12/12 ">
                <div className="relative w-full mb-6">
                  <label
                    className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                    htmlFor={`title-${index}`}
                  >
                    Learning Outcome Title
                  </label>
                  <input
                    type="text"
                    name={`title-${index}`}
                    defaultValue={course.title}
                    onChange={(e) => handleChange3(index, 'title', e.target.value)}
                    className="border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                    placeholder="Enter Learning Outcome title"
                  />
                </div>
              </div>

              {/* Career Overview */}
              <div className="w-full lg:w-12/12 mb-[100px]">
                <div className="mb-[5px] text-[#303840] font-medium">Learning outcome Overview</div>
                <ReactQuill
                  theme="snow"
                  modules={module}
                  value={course.description}
                  onChange={(value) => handleChange3(index, 'description', value)}
                  placeholder="Content of the editor"
                  className="h-40"
                />
              </div>

              {/* Remove button if there are more than one course block */}
              {learningAdd?.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveLearning(index)}
                  className="text-red-500"
                >
                  Remove Learning
                </button>
              )}
            </div>
          ))
          }

          {/* Button to add new course block */}
          {learning?.length < 1  && (
   <button type="button" onClick={handleAddLearning} className="mb-4 bg-blue-500 text-white p-2 rounded">
   Add Another Learning
 </button>
          )}
       


              </div>
           
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button 
                onClick={createSchoolProgram} className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">Update Learning Outcome</button>
              </div>
            </div>

            </div>

            <div className={showScreen === 4 ? "block " : "hidden"}>
            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
            <div className='basis-8/12'>
            <h3 className='text-[#1DB459] mb-[30px] text-[15px] font-semibold'>Edit Learning Outcomes </h3>
              
            { eligibility && eligibility?.length > 0 ? 
            eligibility?.map((course:any, index:any) => (
            <div key={index} className="course-block mb-8">
              {/* Course Title */}
              <div className="w-full lg:w-12/12 ">
                <div className="relative w-full mb-6">
                  <label
                    className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                    htmlFor={`name-${index}`}
                  >
                    Eligibility Title
                  </label>
                  <input
                    type="text"
                    name={`name-${index}`}
                    defaultValue={course.name}
                    onChange={(e) => handleChangeEdit4(index, 'name', e.target.value)}
                    className="border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                    placeholder="Enter eligibility title"
                  />
                </div>
              </div>



              {/* Remove button if there are more than one course block */}
              {/* {learning.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveLearning(index)}
                  className="text-red-500"
                >
                  Remove Learning
                </button>
              )} */}
            <span> <button type='button' onClick={() => deleteEachEligibility(course.id)} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md"> Delete eligibilty</button></span>

            </div>
          )) : 
          eligibilityAdd?.map((course:any, index:any) => (
            <div key={index} className="course-block mb-8">
              {/* Course Title */}
              <div className="w-full lg:w-12/12 ">
                <div className="relative w-full mb-6">
                  <label
                    className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                    htmlFor={`name-${index}`}
                  >
                    Eligibility Title
                  </label>
                  <input
                    type="text"
                    name={`name-${index}`}
                    defaultValue={course.name}
                    onChange={(e) => handleChange4(index, 'name', e.target.value)}
                    className="border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                    placeholder="Enter Eligible title"
                  />
                </div>
              </div>

           
              {/* Remove button if there are more than one course block */}
              {eligibilityAdd?.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveEligibility(index)}
                  className="text-red-500"
                >
                  Remove Eligibility
                </button>
              )}
            </div>
          ))
          }

          {/* Button to add new course block */}
          {eligibility?.length < 1  && (
   <button type="button" onClick={handleAddEligibility} className="mb-4 bg-blue-500 text-white p-2 rounded">
   Add Another Eligibility
 </button>
          )}
       


              </div>
           
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button 
                onClick={createSchoolProgram} className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">{eligibility?.length > 0 ? 'Update Eligibility' : 'Add Eligibility'}</button>
              </div>
            </div>

            </div>

            <div className={showScreen === 5 ? "block " : "hidden"}>
            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
            <div className='basis-8/12'>
            <h3 className='text-[#1DB459] mb-[30px] text-[15px] font-semibold'>Edit Learning Outcomes </h3>
              
            { language && language?.length > 0 ? 
            language?.map((course:any, index:any) => (
              <div key={index} >
              {/* Course Title */}
<div className="flex md:flex-row flex-col gap-5 ">
              <div className="w-full  mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor={`type-${index}`}
                        >
                          Key 
                        </label>
                        <input
                          type="text"
                          name={`type-${index}`}
                          defaultValue={course.type}
                          onChange={(e) => handleChangeEdit5(index, 'type', e.target.value)}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor={`requirement-${index}`}
                        >
                          Value 
                        </label>
                        <input
                          type="text"
                          name={`requirement-${index}`}
                          defaultValue={course.requirement}
                           onChange={(e) => handleChangeEdit5(index, 'requirement', e.target.value)}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
             </div>

              {/* Remove button if there are more than one course block */}
              {/* {language.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveLanguage(index)}
                  className="text-red-500"
                >
                  Remove Language
                </button>
              )} */}
            <span> <button type='button' onClick={() => deleteEachLanguage(course.id)} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md"> Delete eligibilty</button></span>

            </div>
          )) : 
          languageAdd?.map((course:any, index:any) => (
            <div key={index} >
              {/* Course Title */}
<div className="flex md:flex-row flex-col gap-5 ">
              <div className="w-full  mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor={`type-${index}`}
                        >
                          Key 
                        </label>
                        <input
                          type="text"
                          name={`type-${index}`}
                          defaultValue={course.type}
                          onChange={(e) => handleChange5(index, 'type', e.target.value)}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor={`requirement-${index}`}
                        >
                          Value 
                        </label>
                        <input
                          type="text"
                          name={`requirement-${index}`}
                          defaultValue={course.requirement}
                           onChange={(e) => handleChange5(index, 'requirement', e.target.value)}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
             </div>

              {/* Remove button if there are more than one course block */}
              {languageAdd.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveLanguage(index)}
                  className="text-red-500"
                >
                  Remove Language
                </button>
              )}
            </div>
          ))
          }

          {/* Button to add new course block */}
          {language?.length < 1  && (
   <button type="button" onClick={handleAddLanguage} className="mb-4 bg-blue-500 text-white p-2 rounded">
   Add Another Language
 </button>
          )}
       


              </div>
           
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button 
                onClick={createSchoolProgram} className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">{eligibility?.length > 0 ? 'Update Language' : 'Add Language'}</button>
              </div>
            </div>

            </div>


          </form>

        </div>
      </div>

      <section>
        <Modal
          visible={visible}
          width="700"
          minHeight="300"
          effect="fadeInUp"
          onClickAway={() => toggleModal}
        >
          <p className="flex justify-end mt-2 ml-2 mr-2 cursor-pointer font-bold" onClick={toggleModal}>X</p>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl rounded-lg mt-2" >
            <div className="px-6">

              <div className=''>
                <div className='flex justify-center'>Are you sure you want to delete this Partner?</div>
                <div className='flex justify-center py-10'><button type='button' onClick={deleteCourse} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md "> Proceed</button></div>
              </div>



            </div>
          </div>
        </Modal>
      </section>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </>
  )
}

export default EditCourse;