import React, { Fragment, useState } from 'react'
import { AdminApis } from '../apis/adminApi';
import { AxiosResponse } from 'axios';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';

const FaqLithuania = () => {
     const [open, setOpen] = useState(1);
      const [openTestTab, setOpenTestTab] = useState(1);
      // const [openTestTab, setOpenTestTab] = useState("general");
      const [faqLists, setFaqList] = React.useState<any>([]);
      const [searchText, setSearchText] = React.useState("");
    
      const handleOpen = (value: any) => {
        setOpen(open === value ? 0 : value);
      };

        React.useEffect(() => {
          const query: any = {
            search: searchText,
          };
          AdminApis.getAllFaq()
            .then((response: AxiosResponse<any>) => {
              if (response?.data) {
                setFaqList(response?.data?.data);
                // console.log(response?.data?.data);
                // Filter out "Master's Program" category
                // const filteredData = response?.data?.data.filter((course: any) => course.category !== "master\'s program");
                // setGroupList(filteredData);
              }
            })
            .catch(function (error) {
              // handle error
            });
        }, []);


  return (
    <div>
      {/* <!-- Start block --> */}
      <section className="bg-[#FFFFFF] body-font pt-10 md:pt-20 font-poppins">
    
        <div className="flex flex-col md:flex-row md:justify-between  md:max-w-screen-xl  px-8 lg:px-24  pb-8 mx-auto lg:gap-20 ">
          {/* <div className="my-6 lg:mt-0 lg:pl-24 flex flex-row lg:flex-col max-w-[90vw] overflow-auto">
        {categories.map((category) => (
              <div
                key={category}
                onClick={() => setOpenTestTab(category)}
                className={` ${
                  openTestTab === category ? "bg-[#E4FFEF] text-[#1DB459]" : ""
                } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </div>
            ))}
          </div> */}

          <div className="my-6 lg:mt-0 lg:pl-24 flex flex-row lg:flex-col max-w-[90vw] overflow-auto">
            <div
              onClick={() => setOpenTestTab(1)}
              className={` ${
                openTestTab === 1 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >
              General
            </div>
            <div
              onClick={() => setOpenTestTab(2)}
              className={` ${
                openTestTab === 2 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >
           Before Application
            </div>
            <div
              onClick={() => setOpenTestTab(3)}
              className={` ${
                openTestTab === 3 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >
            After Application
            </div>
        
          </div>

          <div className=" lg:mb-20 max-w-xl">
            {/* {filteredFaqs.map((faq:any, idx:any) => (
                <Accordion
                  key={faq.id}
                  open={open === faq.id}
                  className="px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg"
                >
                 
                  <AccordionHeader
                    onClick={() => handleOpen(faq.id)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    {faq.question}
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    {sanitizeHTML(faq.answer)}
                  </AccordionBody>
                </Accordion>
              ))}  */}

            <div className={openTestTab === 1 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 1}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(1)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
What makes Lithuania a good study destination?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  Lithuania offers high-quality education, affordable tuition fees, and a welcoming environment for international students. It’s also a member of the European Union, allowing students to experience European culture and access opportunities in the Schengen Area.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 2}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(2)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                What languages are programs taught in?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  Most programs are taught in English, especially at the graduate level. However, there are also programs in Lithuanian for those who are fluent.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 3}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(3)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                   Are international students allowed to work while studying?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  Yes, international students can work up to 20 hours per week during their studies and full-time during holidays.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 4}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(4)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
              What is the average cost of living in Lithuania?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  The average cost of living is €400–€700 per month, including accommodation, food, transportation, and other personal expenses.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            <div className={openTestTab === 2 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 9}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(9)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
            	What are the general admission requirements for international students?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <span className='font-semibold'>Admission typically requires:</span>
                    <ol className="space-y-1 list-disc list-inside">
                      <li>A completed application form.</li>
                      <li>Academic transcripts.</li>
                      <li>Proof of English language proficiency (e.g., English Language in either WAEC/NECO, IELTS, TOEFL).</li>
                      <li>A valid passport.</li>
                    
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 10}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(10)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
            Do I need a visa to study in Lithuania?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  Yes, most non-EU/EEA students need a National Visa (D) to study in Lithuania. EU/EEA students only need a residence permit if staying for more than 90 days.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 11}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(11)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
Are scholarships available for international students?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  Yes, many universities and the Lithuanian government offer scholarships for outstanding international students. Check specific university websites for details.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 50}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(50)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
            What documents are needed to apply for a Lithuanian university?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <span className='font-semibold'>Required documents include:</span>
                    <ol className="space-y-1 list-disc list-inside">
                      <li>Passport copy.</li>
                      <li>Academic certificates (translated if necessary).</li>
                      <li>Proof of English proficiency.</li>
                      <li>Motivation letter or statement of purpose.</li>
                    
                    </ol>
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            <div className={openTestTab === 3 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 12}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(12)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                 How long does it take to receive an admission decision?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  Admission decisions are usually communicated within 4–6 weeks after submitting a complete application.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 13}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(13)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
               	What happens after I receive my acceptance letter?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  <span className='font-semibold'>After receiving your acceptance letter, you’ll need to:</span>
                    <ol className="space-y-1 list-disc list-inside">
                      <li>Pay any required tuition deposit.</li>
                      <li>Apply for a National Visa (D).</li>
                      <li>Arrange accommodation.</li>
                     
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 14}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(14)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                  How do I secure accommodation in Lithuania?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  Many universities offer dormitory housing, which you can apply for after admission. Alternatively, you can rent private apartments or shared flats.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 15}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(15)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
              	What should I do if my visa application is denied?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  <span className='font-semibold'>If your visa is denied, you can:</span>
                    <ol className="space-y-1 list-disc list-inside">
                      <li>Appeal the decision with the Lithuanian Migration Department.</li>
                      <li>Review your application for missing or incorrect information and reapply.</li>
                  
                    </ol>
                  </AccordionBody>
                </Accordion>

          
              </Fragment>
            </div>
    
            {/* <div className={openTestTab === 7 ? "block" : "hidden"}>
              <Fragment>
                <Accordion open={open === 41} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(42)} className='border border-white text-base font-medium text-[#170F49]'>
                    What are the monthly rates on the loan?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    As mentioned above, banks give rates based on your eligibility and they are usually between
                    2.5-3.5 % per month.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 43} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(43)} className='border border-white text-base font-medium text-[#170F49]'>
                    What is the repayment time for the loan?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Repayment time usually Is between 12-18 months.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 44} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(44)} className='border border-white text-base font-medium text-[#170F49]'>
                    What is loan process?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    First student indicates interest to study the language. Then pays for the evaluation of
                    candidates’ English proficiency and soft skills. Once the student passes, an email from Finest Future
                    will be sent. Once you receive that email then you can proceed to apply for loan. If you fail, your
                    first re-take is free and subsequent ones are paid ( 50€ ) for each re-take
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 45} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(45)} className='border border-white text-base font-medium text-[#170F49]'>
                    What are the criteria to get a loan?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Various Banks with various policies. Two main factors are: <br />
                    Guarantor Loan: The guarantor must be a working-class citizen of your country. The
                    loan to be given will be 50% of the yearly income if guarantor earns 5000€ a year then
                    2500-euro loan will be offered.<br />
                    Collateral Loan: The loan will be given based on properties worth more than the
                    principal loan. Most cases are vehicles worth more than the loan you request for. You
                    can negotiate with banks for more information.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 46} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(46)} className='border border-white text-base font-medium text-[#170F49]'>
                    Do you provide loans?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Please note, we are not a financial institution that offers loans, you only apply through us to
                    our partner banks.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div> */}
          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
    </div>
  )
}

export default FaqLithuania