import React from "react";
import Navbar from "../../Navbar";
import hero from "../../../img/lith.png";
import herosmall from "../../../img/Rectangle 1433.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Carousel from "react-grid-carousel";
import "react-responsive-modal/styles.css";
import Footer from "../../Footer";

const StudyInLithuania = () => {
  return (
    <div>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className=" md:pt-20 relative">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          className="md:bg-cover relative bg-contain  md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 m pt-2  "
        >
          <div className=" text-white md:pt-[100px] pt-[130px] md:pb-5 pb-12">
            {/* <img src={configs.partnerSchools + schoolData?.logo} alt={schoolData?.logo} className='h-9 md:h-16' /> */}
            <div className="flex justify-center items-center gap-1">
              <h1 className="md:text-[62px] leading-[60px] flex justify-center text-[24px] text-center font-bold ">
                Study in Lithuania
              </h1>
              <div className="md:hidden block">
                <img src="/images/Group.png" className="w-10 h-10" alt="" />
              </div>
              <div className="hidden md:block">
                <img src="/images/Group.png" className="" alt="" />
              </div>
            </div>
            <div className="flex justify-center  md:mt-3 ">
              <h5 className="max-w-[586px] text-sm text-center">
                Lithuania is known for its welcoming atmosphere, rich cultural
                heritage, and a growing focus on innovation and research.
              </h5>
            </div>
            <div className="flex justify-center mt-5 md:mt-10">
              <Link  to={"/study-in-lithuania/study-program"} className="flex justify-center items-center px-4 py-2 rounded-full bg-[#1DB459]">
                <h5 className="text-sm">Apply Now</h5>
                <MdKeyboardArrowRight />
              </Link>
            </div>
            {/* <NavLink to={`/school-details/${schoolData?.id}`}>
                            <h1 className="md:text-2xl text-base font-bold md:mt-[12px]">
                                {schoolData?.school_name}
                            </h1>
                        </NavLink>
                        <p className='md:text-base text-sm font-semibold max-w-[430px] md:mt-[10px] capitalize'>{courseDetails?.category}</p> */}
          </div>

          {/* <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-40 absolute bottom-[-50px] rounded-[26px] md:left-28 left-5 shadow-lg md:h-40' /> */}
        </div>
      </section>
      {/* <!-- End block --> */}

      <div className="shadow-lg  py-3">
        <div className="flex items-center gap-4 justify-center">
          <Link
            className="border border-[#1DB459] text-[#1DB459] px-4 py-2 rounded-[10px]"
            to={"/study-in-lithuania"}
          >
            About Lithuania
          </Link>

          <Link className="text-[#777777]" to={"/study-in-lithuania/study-program"}>
            Study programs
          </Link>
        </div>
      </div>

      <div>
        <div className="">
        <div className="flex justify-center pt-8 pb-4 items-center gap-1">
              <h1 className="md:text-[40px] text-[#1DB459] leading-[60px] flex justify-center text-[24px] text-center font-bold ">
              About Lithuania
              </h1>
              <div className="md:hidden block">
                <img src="/images/Group.png" className="w-10 h-10" alt="" />
              </div>
              <div className="hidden md:block">
                <img src="/images/Group.png" className="" alt="" />
              </div>
            </div>
            <div className="flex justify-center mb-10">
      <iframe className="w-[70vw] md:hidden block h-full aspect-video " src="https://www.youtube.com/embed/lQLNw-4YakI?si=CJHBkC3akHnHPOWh"></iframe>
      <iframe className="w-[60vw] hidden md:block h-full aspect-video " src="https://www.youtube.com/embed/lQLNw-4YakI?si=CJHBkC3akHnHPOWh"></iframe>
      {/* <iframe className="w-[70vw] md:hidden block h-full aspect-video " src="https://www.youtube.com/embed/UXOA5W_ndYo?si=9rxKdG_nb7MxbYC5"></iframe> */}
      {/* <iframe className="w-[60vw] hidden md:block h-full aspect-video" src="https://www.youtube.com/embed/UXOA5W_ndYo?si=9rxKdG_nb7MxbYC5"></iframe> */}
      </div>
   <div className="flex justify-center px-3 text-center">
    <h3 className="max-w-[980px]">
  <span className="text-[#1DB459]">Lithuania</span> has a long-standing tradition of higher education and offers a warm environment for foreigners. A wide variety of Lithuanian universities offer more than 500 study programmes, taught in<span className="text-[#1DB459]">English</span>, that foreign students could choose from. Students can choose to study full-time or part-time. Several universities offer spring intake.
Higher education degrees are offered in:
<span className="text-[#1DB459]">Bachelors</span> and <span className="text-[#1DB459]">Masters</span>.

</h3>   </div>

<div className="flex justify-center mt-5 md:mt-10">
              <Link  to={"/study-in-lithuania/study-program"} className="flex justify-center items-center px-4 py-2 rounded-[10px] bg-[#1DB459]">
                <h5 className="text-sm text-white">Apply Now</h5>
                <MdKeyboardArrowRight className="text-white" />
              </Link>
            </div>

          {/* <!-- Start block --> */}
          <section className="max-w-screen-xl mx-auto px-8 lg:px-14 pb-5 pt-10 body-font font-poppins ">
            <div className="flex justify-center items-center text-center">
              <h2 className="md:leading-[60px] text-center md:text-[42px] pb-3 text-2xl font-semibold text-gray-900 ">
                Why{" "}
                <span className="text-[#1DB459]"> International Students</span>{" "}
                Should Study in{" "}
                <span className="text-[#1DB459] flex justify-center items-center gap-1">
                  Lithuania 
                  <img src="/images/Group.png" className="md:block hidden " alt="" />
                  <img src="/images/Group.png" className="md:hidden block w-10 h-10" alt="" />
                </span>{" "}
              </h2>
            </div>
            <div className="md:flex justify-center gap-5 md:gap-5 lg:gap-0  pt-6   relative">
              <div className="flex flex-col gap-5 lg:gap-7">
                <div className="">
                  <div className="flex justify-center">
                    <div className="border-4 border-[#1DB459] text-center items-center rounded-full h-8 w-8 flex justify-center">
                      1
                    </div>
                  </div>
                  <div className="  overflow-hidden rounded-[10px] border-[#1DB459] border-[1px] ">
                    <div className="flex justify-center text-center text-white text-[17px] bg-[#1DB459] py-2 px-4">
                      WORLD-CLASS EDUCATION
                    </div>
                    <div className="flex h-[120px] items-center px-6 justify-center text-center">
                      <div>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                          Lithuania is home to reputable universities such as
                          Vilnius University and Kaunas University of
                          Technology, offering globally recognized degrees and a
                          range of English-taught programs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="flex justify-center">
                    <div className="border-4 border-[#1DB459] text-center items-center rounded-full h-8 w-8 flex justify-center">
                      2
                    </div>
                  </div>
                  <div className="  overflow-hidden rounded-[10px] border-[#1DB459] border-[1px] ">
                    <div className="flex justify-center text-center text-white text-[17px] bg-[#1DB459] py-2 px-4">
                      AFFORDABLE TUITION FEE
                    </div>
                    <div className="flex h-[120px] items-center px-6 justify-center text-center">
                      <div>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                          Compared to other European countries, Lithuania offers
                          high-quality education at an affordable cost, making
                          it accessible to students from diverse financial
                          backgrounds.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:hidden block">
                  <div className="flex  justify-center">
                    <div className="border-4 bg-white border-[#1DB459] text-center items-center rounded-full h-8 w-8 flex justify-center">
                      5
                    </div>
                  </div>
                  <div className="  overflow-hidden rounded-[10px] border-[#1DB459] border-[1px] ">
                    <div className="flex justify-center text-center text-white text-[17px] bg-[#1DB459] py-2 px-2">
                    MULTICULTURAL ENVIRONMENT
                    </div>
                    <div className="flex h-[120px] bg-white items-center px-2 justify-center text-center">
                      <div>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                        Students are able to apply into schools after reaching level
                        </p>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                        Students attend school webinars to learn about different
                        </p>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                        school options

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="md:w-6/12 mr-auto place-self-center  lg:mb-56">
              <div className="hidden md:block mx-auto mt-14">
                <h1 className="text-white text-[30px] font-semibold px-6 leading-10">Registration Information <br />for Finnish Language Program</h1>
              </div>
              <div className="block md:hidden   ">
                <h1 className="text-white text-[26px] font-semibold">Registration Information for<br /> Finnish Language Program</h1>
              </div>
              <div className="grid  md:px-6">
                <div className="flex-col md:mt-10 mt-8">
                  <h5 className="text-white mt-[8px] md:text-[18px] text-[14px] font-medium">Benefits : </h5>
                  <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our expert language teachers</h5>
                  <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our Finnish Language certificate</h5>
                  <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to apply to our partner schools in Finland</h5>
                  <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our language materials</h5>
                  <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our learning portals and entire language learning journey.</h5>
                  <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our visa and documentation processing and support</h5>
                  <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to get support to apply for apartments and some basic supports needed on or before arrival to Finland</h5>
                </div>
              </div>
            </div> */}
              </div>
              <div className="md:w-[420px] relative hidden lg:block  md:-mt-6 ">
                <img
                  src="/images/lithgrad.svg"
                  alt="Assessment Criteria"
                  className="relative"
                />
                   <div className="lg:ml-4 absolute bottom-6">
                  <div className="flex  justify-center">
                    <div className="border-4 bg-white border-[#1DB459] text-center items-center rounded-full h-8 w-8 flex justify-center">
                      5
                    </div>
                  </div>
                  <div className="  overflow-hidden rounded-[10px] border-[#1DB459] border-[1px] ">
                    <div className="flex justify-center text-center text-white text-[17px] bg-[#1DB459] py-2 px-2">
                    MULTICULTURAL ENVIRONMENT
                    </div>
                    <div className="flex h-[120px] bg-white items-center px-2 justify-center text-center">
                      <div>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                        Students are able to apply into schools after reaching level
                        </p>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                        Students attend school webinars to learn about different
                        </p>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                        school options

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-5 lg:gap-7 md:mt-0 mt-5">
                <div className="">
                  <div className="flex justify-center">
                    <div className="border-4 border-[#1DB459] text-center items-center rounded-full h-8 w-8 flex justify-center">
                      3
                    </div>
                  </div>
                  <div className="  overflow-hidden rounded-[10px] border-[#1DB459] border-[1px] ">
                    <div className="flex justify-center text-center text-white text-[17px] bg-[#1DB459] py-2 px-4">
                      POST-GRADUATION OPPORTUNITIES
                    </div>
                    <div className="flex h-[120px] items-center px-6 justify-center text-center">
                      <div>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                          The General Intake of High School students begins
                          21.2. The deadline for applications is 21.3. Schools
                          decide who gets accepted as a student
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="flex justify-center">
                    <div className="border-4 border-[#1DB459] text-center items-center rounded-full h-8 w-8 flex justify-center">
                      4
                    </div>
                  </div>
                  <div className="  overflow-hidden rounded-[10px] border-[#1DB459] border-[1px] ">
                    <div className="flex justify-center text-center text-white text-[17px] bg-[#1DB459] py-2 px-4">
                      SCHOLARSHIP OPPORTUNITIES
                    </div>
                    <div className="flex h-[120px] items-center px-6 justify-center text-center">
                      <div>
                        <p className="font-normal  text-sm max-w-[350px] text-[#838383]">
                          The Lithuanian government and universities offer
                          scholarships to international students, reducing
                          financial barriers and promoting inclusivity.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="hidden md:block mx-auto text-center ">
            <h1 className="text-white text-3xl px-6">Assessment Criteria During Interview</h1>
          </div> */}
              {/* <div className="block md:hidden mx-auto text-center ">
            <h1 className="text-white text-2xl px-6">Assessment Criteria <br />During Interview</h1>
          </div> */}
              {/* <div className="flex justify-center md:mt-20 mt-10 px-8">
            <img src="/images/pg-bg.svg" alt="friends" />

          </div> */}
          
            </div>
            <div className="flex justify-center mt-5 md:mt-10">
              <Link  to={"/study-in-lithuania/study-program"} className="flex justify-center items-center px-4 py-2 rounded-[10px] bg-[#1DB459]">
                <h5 className="text-sm text-white">Apply Now</h5>
                <MdKeyboardArrowRight className="text-white" />
              </Link>
            </div>
          </section>
          {/* <!-- End block --> */}

          {/* <!-- Start block --> */}
          <section className="  pb-5 body-font font-poppins w-full bg-[#DCEEE1]">
            <div
            // style={{
            //   backgroundImage: `url(${backG})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundSize: "cover",
            // }}
            ></div>
            {/* <div className="text-center md:block hidden text-[#212427] md:text-2xl text-xl font-semibold py-5 md:py-10">
        Why<span className="text-[#48B774]"> Finland</span> is the best choice?
        </div> */}
            <div className="max-w-screen-xl mx-auto px-8 lg:px-14">
              <div className="flex items-center gap-1 justify-center">
                <div className="text-center  text-[#212427] md:text-2xl md:max-w-xl max-w-[200px] text-xl font-semibold py-5 md:py-10">
                  Economic Value of{" "}
                  <span className="text-[#1DB459] ">Lithuania </span>
                </div>
                <img src="/images/Group.png" className="w-12 h-12" alt="" />
              </div>
            </div>
            {/* <div className="flex justify-center mx-auto px-6 w-[80%] md:w-full">
          <img alt="mail" src="/images/finland-img.png" />
        </div> */}

            <div className="max-w-screen-xl px-8 lg:px-14  pb-8 mx-auto lg:gap-20">
              {/* <div className="flex justify-end text-white text-[24px] px-6 mb-4"> ➜</div> */}

              <Carousel cols={3} rows={1} gap={10} loop>
                <Carousel.Item>
                  <div className=" lg:mt-0 ">
                    {/* <motion.div
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 3 }}
              className="flex justify-center">
              <img alt="mail" src="/images/fin1.png" className="" />
            </motion.div> */}
                    <div className="bg-white rounded-t-[10px] h-[450px]">
                      <div className="w-full">
                        <img
                          alt="mail"
                          src="/images/valueLit.svg"
                          className="w-full"
                        />
                      </div>
                      <div className="px-5 ">
                        <div className="flex justify-center text-center">
                          <h3 className="text-[#213D4F] text-[22px] font-bold">
                            Growing Economy{" "}
                          </h3>
                        </div>
                        <div className="flex justify-center text-center">
                          <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                            Lithuania has one of the fastest-growing economies
                            in the European Union. Its GDP is driven by
                            technology, manufacturing, services, and
                            agriculture, making it an attractive destination for
                            business and innovation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className=" lg:mt-0">
                    <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                      <div className="flex  justify-center">
                        <img
                          alt="mail"
                          className="w-full rounded-[10px]"
                          src="/images/valueLit2.png"
                        />
                      </div>
                      <div className="px-5 ">
                        <div className="flex justify-center text-center">
                          <h3 className="text-[#213D4F] text-[22px] font-bold">
                            Strategic Location{" "}
                          </h3>
                        </div>
                        <div className="flex justify-center text-center">
                          <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                            Positioned at the crossroads of Europe, Lithuania
                            provides access to major European markets, making it
                            a hub for trade, logistics, and business
                            opportunities.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className=" lg:mt-0">
                    <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                      <div className="flex justify-center">
                        <img
                          alt="mail"
                          className="rounded-[10px] w-full"
                          src="/images/valueLit3.png"
                        />
                      </div>
                      <div className="px-5 ">
                        <div className="flex justify-center text-center">
                          <h3 className="text-[#213D4F] text-[22px] font-bold">
                            Digital Economy{" "}
                          </h3>
                        </div>
                        <div className="flex justify-center text-center">
                          <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                            Lithuania is recognized as a leader in fintech and
                            digital innovation. It offers a startup-friendly
                            environment with government incentives, low taxes,
                            and streamlined business regulations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className=" lg:mt-0">
                    <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                      <div className="flex justify-center max-h-[300px]">
                        <img
                          alt="mail"
                          className="w-full"
                          src="/images/valueLit4.png"
                        />
                      </div>
                      <div className="px-5 ">
                        <div className="flex justify-center text-center">
                          <h3 className="text-[#213D4F] text-[22px] font-bold">
                            Cost of Living{" "}
                          </h3>
                        </div>
                        <div className="flex justify-center text-center">
                          <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                            Lithuania offers a relatively low cost of living
                            compared to other EU countries. Affordable housing,
                            food, and transportation make it ideal for
                            international students.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className=" lg:mt-0">
                    <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                      <div className="flex justify-center max-h-[300px]">
                        <img
                          alt="mail"
                          className="w-full"
                          src="/images/valueLit6.png"
                        />
                      </div>
                      <div className="px-5 ">
                        <div className="flex justify-center text-center">
                          <h3 className="text-[#213D4F] text-[22px] font-bold">
                            High Human Development Index (HDI){" "}
                          </h3>
                        </div>
                        <div className="flex justify-center text-center">
                          <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                            Lithuania ranks high in education, healthcare, and
                            living standards, offering a quality life for
                            residents and visitors
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                {/* <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="rounded-b-[10px] w-full "
                      src="/images/Freedom V1.jpg"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Freedom
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        Freedom and democracy are the beating heart of Finland.
                        Here you can shape your own future. Finland's inclusive
                        society fosters innovation and personal growth, ensuring
                        that every voice can contribute to the nation's
                        progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="rounded-b-[10px] w-full"
                      src="/images/Cleanliness V2.jpg"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Cleanliness{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        WHO ranks the air quality in Finland best in the world.
                        UNICEF ranks Finland's lakes the purest in the world.
                        These accolades highlight Finland's dedication to
                        environmental sustainability and the preservation of its
                        natural resources.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="rounded-b-[10px] w-full"
                      src="/images/Equality V2.jpg"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Equality{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        Equality makes Finland one of the civilized countries in
                        the world.The nation's commitment to inclusivity and
                        equal opportunities for all its citizens fosters a
                        harmonious and just society.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item> */}
              </Carousel>
            </div>

            <div className="flex justify-center mt-5 md:mt-10">
              <Link  to={"/study-in-lithuania/study-program"} className="flex justify-center items-center px-4 py-2 rounded-[10px] bg-[#1DB459]">
                <h5 className="text-sm text-white">Apply Now</h5>
                <MdKeyboardArrowRight className="text-white" />
              </Link>
            </div>
          </section>
          {/* <!-- End block --> */}

          {/* <!-- Start block --> */}
          <section className="grid  lg:py-14 py-8 px-8 lg:px-14  max-w-screen-xl mx-auto items-center lg:gap-8  lg:grid-cols-12 ">
            <div className="col-span-6">
              <img src="/images/aca.svg" className="" alt="" />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-5">

                <div className="flex justify-center">
                <div className="text-center leading-[40px] text-[#212427] md:text-[29px] md:max-w-[500px] max-w-[300px] text-xl font-semibold py-5 md:py-10">
                  Academic{" "}
                  <span className="text-[#1DB459] ">Requirements </span> For{" "}
                  <span className="text-[#1DB459] ">Lithuania </span>
                </div>
                </div>
                <div className="flex items-center gap-3">
                  <img src="/images/marked.svg" className="" alt="" />
                  <h5 className="text-[#212427] md:text-[27px] font-bold md:max-w-[500px] text-xl  ">
                    WAEC/NECO
                  </h5>
                </div>

                <div className="flex items-center gap-3">
                  <img src="/images/marked.svg" className="" alt="" />
                  <h5 className="text-[#212427]  md:text-[27px] font-bold md:max-w-[520px] text-xl  ">
                National Diploma Certificate (OND or HND)
                  </h5>
                </div>

                <div className="flex items-center gap-3">
                  <img src="/images/marked.svg" className="" alt="" />
                  <h5 className="text-[#212427] md:text-[27px] font-bold md:max-w-[520px] text-xl ">
                  University/College of Education Transcript
                  </h5>
                </div>

                <div className="flex items-center gap-3">
                  <img src="/images/marked.svg" className="" alt="" />
                  <h5 className="text-[#212427] md:text-[27px] font-bold md:max-w-[500px] text-xl  ">
                   A Level Certificate
                  </h5>
                </div>
                <div className="flex items-center gap-3">
                  <img src="/images/wrong.svg" className="" alt="" />
                  <h5 className="text-[#212427]  md:text-[27px] font-bold md:max-w-[500px] text-xl ">
 No IELTS Needed
                  </h5>
                </div>

                <div className="flex justify-center ">
              <Link  to={"/study-in-lithuania/study-program"} className="flex justify-center items-center px-4 py-2 rounded-[10px] bg-[#1DB459]">
                <h5 className="text-sm text-white">Apply Now</h5>
                <MdKeyboardArrowRight className="text-white" />
              </Link>
            </div>
              </div>
            </div>
          </section>
          {/* <!-- End block --> */}


               {/* <!-- Start block --> */}
               <section className="grid  lg:py-14 py-8 px-8 lg:px-14  max-w-screen-xl mx-auto items-center lg:gap-8  lg:grid-cols-12 ">
        
            <div className="col-span-6">
              <div className="flex flex-col gap-5">
         <div className="bg-[#1DB459] w-fit">
<h6 className="text-white px-3 py-2 text-sm">We provide residence permit process guide</h6>
         </div>

         <h3 className="text-[#1DB459] text-[30px] md:text-[40px]">LITHUANIA EMBASSIES</h3>
            
            <div className="flex items-center gap-2 ">
<div className="bg-[#8E66FF] rounded-full h-5 w-5"></div>
<h5 className="text-[#1DB459] text-[30px]">PHILIPPINES</h5>
            </div>
            
            <div className="flex items-center gap-2 ">
<div className="bg-[#1DB459] rounded-full h-5 w-5"></div>
<h5 className="text-[#1DB459] text-[30px]">SINGAPORE</h5>
            </div>

            <div className="flex items-center gap-2 ">
<div className="bg-[#F14D5D] rounded-full h-5 w-5"></div>
<h5 className="text-[#1DB459] text-[30px]">SOUTH AFRICA</h5>
            </div>

            <div className="flex items-center gap-2 ">
<div className="bg-[#FFBA40] rounded-full h-5 w-5"></div>
<h5 className="text-[#1DB459] text-[30px]">BRAZIL</h5>
            </div>
            <div className="flex justify-start mb-7 ">
              <Link  to={"/study-in-lithuania/study-program"} className="flex justify-center items-center px-4 py-2 rounded-[10px] bg-[#1DB459]">
                <h5 className="text-sm text-white">Apply Now</h5>
                <MdKeyboardArrowRight className="text-white" />
              </Link>
            </div>
              </div>
            </div>

            <div className="col-span-6">
              <img src="/images/embassy.svg" className="" alt="" />
            </div>
          </section>
          {/* <!-- End block --> */}


          {/* <!-- Start block --> */}
          <div className="max-w-screen-xl px-8 lg:px-14  pb-8 mx-auto lg:gap-20">
<div className="bg-[#1DB459] py-8 text-center text-[28px] text-white ">
Resumption date and Application start and end 
</div>
<div className="grid lg:grid-cols-2">
<div className="bg-[#FFBA40] py-3 pl-3 ">
 <div className="\">

  <div className="flex justify-between">
<h5 className="text-[45px] max-w-[360px] text-white">December 1st- May 30, 2025.</h5>
</div>
<div className="flex justify-end -mt-14">
<img src="/images/fluent-mdl2_script.svg" className="" alt="" />
</div>
<div className="">
<h5 className="text-[28px] text-white">Application Start and Ends</h5>
</div>


</div>
</div>

<div className="bg-[#FF0000] py-3 pl-3">
<h5 className="text-[45px] text-white">September 2025</h5>
<div className="flex justify-end">
<img src="/images/game-icons_jet-fighter.svg" className="" alt="" />

</div>
<h5 className="text-[28px] text-white">Resumption</h5>
</div>
</div>

</div>
          {/* <!-- End block --> */}
          </div>
      </div>

      <Footer />
    </div>
  );
};

export default StudyInLithuania;
