import React from 'react'
import { useSelector } from "react-redux";
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import InformationHub from './ResidentPermitDetails/InformationHub';
import DocumentsRequired from './ResidentPermitDetails/DocumentsRequired';
import TravelInsurance from './ResidentPermitDetails/TravelInsurance';
import EnterFindland from './ResidentPermitDetails/EnterFindland';
import RPExtension from './ResidentPermitDetails/RPExtension';
import BeforeArriving from './ResidentPermitDetails/BeforeArriving';
import ImportantDocuments from './ResidentPermitDetails/ImportantDocuments';
import FlightBooking from './ResidentPermitDetails/FlightBooking';
import { Link } from 'react-router-dom';
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { DashboardApi } from '../../../apis/DashboardApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResidentPermitApp() {
    const [toggleOne, setToggleOne] = React.useState(false);
    const [toggleTwo, setToggleTwo] = React.useState(false);
    let [screen, setScreen] = React.useState<any>(1);
    const [searchParams, setSearchParams] = useSearchParams()
    let [infoHubScreen, setInfoHubScreen] = React.useState<any>(1);
    let [showScreen, setShowScreen] = React.useState<any>(1);
    const userLoginData = useSelector((state: any) => state.data.login.value);
    let [changeBtn, setChangeBtn] = React.useState<any>(1);
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [numOne, setNumOne] = React.useState(false);
    const navigate = useNavigate();


    const [open, setOpen] = React.useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    if (searchParams.get('tx_ref')) {
        DashboardApi.verifyResidentPayment(searchParams.get('tx_ref')).then(
            (response: any) => {
                if (response?.data) {
                    // navigate('/wallet');
                    console?.log(response?.data)
                    if (response?.data?.success === true) {
                        toast.success('Payment Successful');

                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }


    const handleSubmit = (amount:any,currency:any) => {
        const formData = new FormData()
        formData.append('amount',amount)
        formData.append('currency',currency)
        DashboardApi.makeResidentPayment(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        window.location.replace(response?.data?.data?.data?.link);
                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">

                <div className={showScreen === 1 ? "block " : "hidden"}>
                    {/* <InformationHub/> */}

                    <div className='md:py-3'>
                        <div className='bg-gradient-to-b from-[#1DB459] to-[#0174B4] min-h-screen max-w-screen-xl mx-auto'>
                            <div className='flex justify-center items-center py-32'>
                                {/* <div className={infoHubScreen === 1 ? 'block' : "hidden"}>
                                    <div className='border border-[#F0F5FE] rounded-[20px] p-2'>
                                        <div className='bg-white border border-[#F0F5FE] rounded-[15px] px-[32px] py-[22px]'>
                                            <div>
                                                <h3 className='text-[#1DB459] text-[24px] text-center font-bold'>Welcome to Afri Pro Edu's<br />
                                                    Information Hub</h3>
                                                <p className='mt-[20px] text-[#777777] text-[14px] font-light text-center' >Your go-to resource for everything related<br /> to entering Finland! </p>
                                                <div className='mt-[22px]'>
                                                    <form>
                                                        <div className="mb-4 w-full">
                                                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                                                Username
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" border border-[#D9D9D9] text-[#777777] text-sm rounded-[5px] block w-full p-3"
                                                                placeholder=""
                                                                name="username"
                                                                defaultValue={userLoginData?.id}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className={changeBtn === 1 ? " hidden" : " block mb-2 w-full"}>
                                                            <label className="block mb-2 mt-2 text-sm font-semibold text-[#0A2E04]">
                                                                Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className=" border border-[#D9D9D9] text-[#fcdfdf] text-sm rounded-[5px] block w-full p-3"
                                                                placeholder="Enter OTP"
                                                                name="password"

                                                            />
                                                        </div>
                                                        <span className='text-[10px] text-[#838383]'>Please check your registered mail address for a one time password upon request</span>

                                                        <div className={changeBtn === 1 ? "flex justify-center mt-3 " : "hidden"}>
                                                            <button
                                                                type="button"
                                                                onClick={() => setChangeBtn(2)}
                                                                className=" text-white bg-[#1DB459] hover:bg-[#05401C] font-medium rounded-lg text-sm px-[70px] py-3 mb-2"
                                                            >
                                                                Request OTP
                                                            </button>
                                                        </div>
                                                        <div className={changeBtn === 2 ? "flex justify-center mt-3 " : "hidden"}>
                                                            <button
                                                                type="button"
                                                                onClick={() => setInfoHubScreen(2)}
                                                                className=" text-white bg-[#1DB459] hover:bg-[#05401C] font-medium rounded-lg text-sm px-[70px] py-3 mb-2"
                                                            >
                                                                Process
                                                            </button>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className={infoHubScreen === 1 ? 'block' : "hidden"}>
                                    <div className='border border-[#F0F5FE] rounded-[20px] p-2'>
                                        <div className='bg-white border border-[#F0F5FE] rounded-[15px] px-[32px] pt-[22px] pb-[50px]'>
                                            <div>
                                                <h3 className='text-[#1DB459] text-[24px] text-center font-bold'>Please Select an Option</h3>

                                                <div className='mt-[22px]'>
                                                    <div
                                                        onClick={onOpenModal}
                                                        className='mb-5 border border-[rgb(179,179,179)] rounded-[5px] py-[16px] md:px-[48px] px-[15px] flex justify-between cursor-pointer'>
                                                        <h3 className='text-[12px] text-[#777777]'>Allow AfriProEdu continue my application<br /> process <span className='text-[#1DB459] font-semibold'>(This comes with a 400 Euros Fee )</span> </h3>
                                                        <div className='border border-[#1DB459] h-[17px] w-[17px] rounded-full place-content-center items-center flex justify-center'></div>
                                                    </div>

                                                    <div
                                                        onClick={() => setShowScreen(2)}
                                                        className='mb-5 border border-[#B3B3B3] rounded-[5px] py-[16px] px-[15px] md:px-[48px] flex space-x-10 cursor-pointer'>
                                                        <h3 className='text-[12px] text-[#777777]'>Continue Resident Permit Application Myself <br /> <span className='text-[#1DB459] font-semibold'>(This comes with a 200 Euros Fee )</span></h3>
                                                        <div className='border border-[#1DB459] h-[17px] w-[17px] rounded-full place-content-center items-center flex justify-center'></div>
                                                    </div>
                                                    <Link to="/">
                                                        <div className='mb-5 border border-[#B3B3B3] rounded-[5px] py-[16px] px-[15px] md:px-[48px] flex justify-between cursor-pointer'>
                                                            <h3 className='text-[12px] text-[#777777]'>Leave the platform and allow an external<br /> agent take over </h3>
                                                            <div className='border border-[#1DB459] h-[17px] w-[17px] rounded-full place-content-center items-center flex justify-center'></div>
                                                        </div>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className={showScreen === 2 ? "block " : "hidden"}>
                    <UserNavbar title="Resident Permit Application" />
                    <div className='md:py-3 '>
                        <div className='bg-[#F9F9F9] pt-[22px] pb-32'>
                            {/* <!-- Header --> */}
                            <div className='md:px-16 px-6'>
                                 {/* <!--Desktop view Header --> */}
                                <div className='hidden lg:flex flex-row justify-center space-x-5 '>
                                    <div className='relative'>
                                        <div
                                            onClick={() => setToggleOne(!toggleOne)}
                                            className=' border border-[#1DB459] hover:bg-[#1DB459] mb-3 hover:text-white rounded-[30px] text-[12px] py-[10px] px-[16px] cursor-pointer'>Resident Permit Documents</div>

                                        <div className={`${toggleOne ? 'block' : 'hidden'} px-4 py-6 bg-white rounded-[10px] border border-[#1DB459] text-center absolute -bottom-56 my-0 min-w-[259px]`}>
                                            <div onClick={() => { setScreen(1); setToggleOne(!toggleOne) }} className='py-[11px] text-[14px] w-full hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>
                                                Documents Required
                                            </div>
                                            <div onClick={() => { setScreen(2); setToggleOne(!toggleOne) }} className='py-[11px]  text-[14px] hover:bg-[#1DB459] hover:text-white hover:rounded-[33px] cursor-pointer'>Travel Insurance</div>
                                            <div onClick={() => { setScreen(3); setToggleOne(!toggleOne) }} className='py-[11px]  text-[14px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Apply on EnterFinland</div>

                                            <div onClick={() => { setScreen(4); setToggleOne(!toggleOne) }} className='py-[11px]  text-[14px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>RP Extension</div>
                                        </div>
                                    </div>

                                    <div className='relative '>
                                        <div
                                            onClick={() => setToggleTwo((prev) => (!prev))}
                                            className='border border-[#1DB459] hover:bg-[#1DB459] mb-3 hover:text-white rounded-[30px] text-[12px] py-[10px] px-[50px] cursor-pointer'>Pre-departure</div>

                                        <div className={`${toggleTwo ? 'block' : 'hidden'} px-4 py-8 bg-white rounded-[10px] border border-[#1DB459] text-center absolute -bottom-48 my-0 min-w-[259px]`}>
                                            <div onClick={() => { setScreen(5); setToggleTwo(!toggleTwo) }} className='py-[11px] pl-[8px] text-[14px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Before Arriving</div>
                                            <div onClick={() => { setScreen(6); setToggleTwo(!toggleTwo) }} className='py-[11px] pl-[8px] text-[14px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Important Documents</div>
                                            <div onClick={() => { setScreen(7); setToggleTwo(!toggleTwo) }} className='py-[11px] pl-[8px] text-[14px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Flight Booking</div>

                                        </div>
                                    </div>

                                    <div className='relative '>
                                        <div
                                            onClick={onOpenModal}
                                            className='border border-[#1DB459] hover:bg-[#1DB459] mb-3 hover:text-white rounded-[30px] text-[12px] py-[10px] px-[50px] cursor-pointer'>Request Assistance </div>


                                    </div>

                                </div>
                                 {/* <!--Mobile view Header --> */}
                                <div className='lg:hidden grid grid-cols-2 justify-center space-x-3'>
                                    <div className='relative'>
                                        <div
                                            onClick={() => setToggleOne(!toggleOne)}
                                            className=' border border-[#1DB459] hover:bg-[#1DB459] mb-3 hover:text-white rounded-[30px] text-[10px] py-[8px] px-[14px] cursor-pointer'>Resident Permit Documents
                                            </div>

                                        <div className={`${toggleOne ? 'block' : 'hidden'} px-4 py-6 bg-white rounded-[10px] border border-[#1DB459] text-center absolute lg:-bottom-56 -bottom-60 my-0 lg:min-w-[259px]`}>
                                            <div onClick={() => { setScreen(1); setToggleOne(!toggleOne) }} className='py-[11px] lg:text-[14px] text-[12px] w-full hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>
                                                Documents Required
                                            </div>
                                            <div onClick={() => { setScreen(2); setToggleOne(!toggleOne) }} className='py-[11px]  lg:text-[14px] text-[12px] hover:bg-[#1DB459] hover:text-white hover:rounded-[33px] cursor-pointer'>Travel Insurance</div>
                                            <div onClick={() => { setScreen(3); setToggleOne(!toggleOne) }} className='py-[11px]  lg:text-[14px] text-[12px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Apply on EnterFinland</div>

                                            <div onClick={() => { setScreen(4); setToggleOne(!toggleOne) }} className='py-[11px]  lg:text-[14px] text-[12px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>RP Extension</div>
                                        </div>
                                    </div>

                                    <div className='relative '>
                                        <div
                                            onClick={() => setToggleTwo((prev) => (!prev))}
                                            className='border border-[#1DB459] hover:bg-[#1DB459] mb-3 hover:text-white rounded-[30px] text-[10px] py-[8px] px-[14px] cursor-pointer'>Pre-departure</div>

                                        <div className={`${toggleTwo ? 'block' : 'hidden'} px-4 py-8 bg-white rounded-[10px] border border-[#1DB459] text-center absolute -bottom-48 my-0 lg:min-w-[259px]`}>
                                            <div onClick={() => { setScreen(5); setToggleTwo(!toggleTwo) }} className='py-[11px] lg:pl-[8px] lg:text-[14px] text-[12px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Before Arriving</div>
                                            <div onClick={() => { setScreen(6); setToggleTwo(!toggleTwo) }} className='py-[11px] lg:pl-[8px] lg:text-[14px] text-[12px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Important Documents</div>
                                            <div onClick={() => { setScreen(7); setToggleTwo(!toggleTwo) }} className='py-[11px] lg:pl-[8px] lg:text-[14px] text-[12px] hover:bg-[#1DB459] hover:rounded-[33px] hover:text-white cursor-pointer'>Flight Booking</div>

                                        </div>
                                    </div>

                                    <div className='relative '>
                                        <div
                                            onClick={onOpenModal}
                                            className='border border-[#1DB459] hover:bg-[#1DB459] mb-3 hover:text-white rounded-[30px] text-[10px] py-[8px] px-[16px] cursor-pointer'>Request Assistance </div>


                                    </div>

                                </div>
                            </div>

                            {/* <!-- body Content / Documents Required  --> */}
                            <div className={screen === 1 ? "block " : "hidden"}>
                                <DocumentsRequired />
                            </div>

                            {/* <!-- body Content / Travel Insurance  --> */}
                            <div className={screen === 2 ? "block " : "hidden"}>
                                <TravelInsurance />
                            </div>

                            {/* <!-- body Content / EnterFinland  --> */}
                            <div className={screen === 3 ? "block " : "hidden"}>
                                <EnterFindland />
                            </div>

                            {/* <!-- body Content / RP Extension   --> */}
                            <div className={screen === 4 ? "block " : "hidden"}>
                                <RPExtension />
                            </div>

                            {/* <!-- body Content / Before Arriving   --> */}
                            <div className={screen === 5 ? "block " : "hidden"}>
                                <BeforeArriving />
                            </div>

                            {/* <!-- body Content / Important Documents   --> */}
                            <div className={screen === 6 ? "block " : "hidden"}>
                                <ImportantDocuments />
                            </div>

                            {/* <!-- body Content / Important Documents   --> */}
                            <div className={screen === 7 ? "block " : "hidden"}>
                                <FlightBooking />
                            </div>

                        </div>


                    </div>
                </div>





                <Modal open={open} onClose={onCloseModal} center>
                    <div className='md:max-w-md  body-font font-poppins'>
                        <div className="flex flex-wrap justify-center mt-4">
                            <h2 className='text-center text-[#1DB459] font-bold md:text-[32px] text-[22px]'>Request Assistance</h2>
                            <p className='text-center text-[#777777] md:text-[14px] text-[12px] mt-5 font-light'>By seeking assistance, you acknowledge and agree to the payment of a one-time <span className='font-bold'>non-refundable</span> fee of </p>
                            <h1 className='text-center mt-6 md:text-[20px] text-[14px] text-[#1DB459] font-semibold'>€400.00</h1>
                            {/* <h3 className='text-center md:text-[20px] text-[14px] text-[#1DB459] font-semibold mt-5'> €400.00</h3> */}
                            <p className='text-center text-[#777777] md:text-[14px] text-[12px]  font-light mt-3'>for the processing of your documents for entry into Finland. </p>
                            <p className='text-center text-[#777777] md:text-[14px] text-[12px]  font-light mt-6'>Upon your request, our Customer Service team will assume responsibility for guiding you through the processing procedures.</p>
                            
                            <div className='flex justify-center mx-auto w-full mt-[23px]'>
                                <button
                                    type="button"
                                    onClick={()=>handleSubmit('400','EUR')}
                                    className=" text-white bg-[#1DB459] rounded-[33px] font-medium text-sm md:px-[20px] px-3 md:py-3 py-2.5 mr-2 mb-2"
                                >
                                   Pay in Euro
                                </button>

                                <button
                                    type="button"
                                    onClick={()=>handleSubmit('660000','NGN')}
                                    className=" text-white bg-[#1DB459] rounded-[33px] font-medium text-sm md:px-[20px] px-3 md:py-3 py-2.5 mr-2 mb-2"
                                >
                                    Pay in Naira
                                </button>

                            </div>
                            <div className='flex justify-center mx-auto w-full mt-[20px]'>
                                <button
                                    type="button"
                                    className=" text-black bg-[#EEEEEE] rounded-[33px]  font-medium text-sm md:px-[112px] px-8 md:py-3 py-2.5 mr-2 mb-2"
                                    onClick={onCloseModal}
                                >
                                    No, Thank You
                                </button>
                            </div>

                        </div>
                    </div>

                </Modal>

            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default ResidentPermitApp