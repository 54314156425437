import React from 'react'
import { Link } from 'react-router-dom'
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthApis } from "../../apis/authApis";
import { login } from "../../reducer/loginSlice";


function AgentNavbar({ setOpenTestTab }) {
  const [toggle, setToggle] = React.useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLoginData = useSelector((state) => state?.data?.login?.value);

  React.useEffect(() => {
    if (!userLoginData?.token) {
      navigate('/become-an-agent-login')
    }
  }, []);

  // React.useEffect((e) => {
  //  (userLoginData?.token && (userLoginData?.data?.user_data?.isVerified == 'true' || userLoginData?.data?.user_data?.isVerified == null)) ?
  //     ''
  //     :
  //     logOut();
  //   // navigate('/sign-in');
  // }, []);

  const logOut = () => {
    AuthApis.logout('').then(
      (response) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/become-an-agent-login');

        }
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      console.log("new error");
    })

  };
  return (
    <>
      <div className="relative bg-lightBlue-600 md:pt-12 pb-2">
        <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center  p-4 lg:shadow-lg">
          <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
            <div className='flex space-x-1 divide-x-2 divide-[#1DB459]'>
              <Link to="/">
                <img src="/images/Dashboard/logo2.svg" alt="hero" />
              </Link>
              <div className='pl-1 mt-3 text-[#1DB459] text-[14px]'>Agent Portal</div>
            </div>

            {/* <div className="md:pr-14 md:block hidden">
          <Link to="/">
          <img src="/images/Dashboard/logo2.svg" alt="hero" />
          </Link>
          </div> */}
            <div className="md:pr-14 md:flex space-x-2 hidden">

              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="none" d="M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0M20.5 12.5A4.5 4.5 0 1 1 16 8a4.5 4.5 0 0 1 4.5 4.5" /><path fill="#6e7093" d="M26.749 24.93A13.99 13.99 0 1 0 2 16a13.899 13.899 0 0 0 3.251 8.93l-.02.017c.07.084.15.156.222.239c.09.103.187.2.28.3c.28.304.568.596.87.87c.092.084.187.162.28.242c.32.276.649.538.99.782c.044.03.084.069.128.1v-.012a13.901 13.901 0 0 0 16 0v.012c.044-.031.083-.07.128-.1c.34-.245.67-.506.99-.782c.093-.08.188-.159.28-.242c.302-.275.59-.566.87-.87c.093-.1.189-.197.28-.3c.071-.083.152-.155.222-.24ZM16 8a4.5 4.5 0 1 1-4.5 4.5A4.5 4.5 0 0 1 16 8M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0" /></svg>
              <div onClick={() => setToggle((prev) => (!prev))} className="flex space-x-2 cursor-pointer relative">

                <span className="mt-1.5">
                  {toggle ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#6E7093" d="m16.9 13.4l-4.2-4.2c-.4-.4-1-.4-1.4 0l-4.2 4.2c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.5-3.5l3.5 3.5c.2.2.4.3.7.3c.3 0 .5-.1.7-.3c.4-.4.4-1 0-1.4z" /></svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#6E7093" d="M12 15.121a.997.997 0 0 1-.707-.293L7.05 10.586a1 1 0 0 1 1.414-1.414L12 12.707l3.536-3.535a1 1 0 0 1 1.414 1.414l-4.243 4.242a.997.997 0 0 1-.707.293Z" /></svg>
                  }
                </span>
              </div>

              <div className={`${toggle ? 'flex' : 'hidden'} absolute -bottom-56 right-4 z-10 border border-gray-100 rounded-[10px] bg-white shadow-lg`}>
                <div >
                  <div className="pt-4 px-5  pb-4">
                    <h3 className='text-[14px] font-bold'>{userLoginData?.name}</h3>
                    <h5 className='text-[12px] text-[#777777]'>{userLoginData?.email}</h5>
                   
                      <div  onClick={() => setOpenTestTab(3)} className='mt-3'>
                        <button className='border border-[#E2E8F0] text-[#777777] text-[12px] py-[10px] px-[40px] rounded-[29px]'>View Profile Settings</button>
                      </div>
                      

                  </div>
                  <hr className='mt-1.5' />
                  <div className="py-3 px-5 ">
                    <div className="  text-[14px] cursor-pointer font-medium">
                      <Link to="/" >
                        FAQ
                      </Link>
                    </div>
                  </div>
                  <hr className='' />
                  <div className="py-4 px-5 ">
                    <div className=" text-[14px] cursor-pointer font-medium text-[#FF0000]">
                      <div onClick={() => logOut()}>
                        Sign Out
                      </div>
                    </div>
                  </div>




                </div>
                {/* <div className=' font-poppins flex flex-col justify-center flex-1'>
                   <NavLink to={"/study-in-finnish"} onClick={() => { setIsOpen(!isOpen); setToggle(false) }} className='block font-normal text-[12px] cursor-pointer text-green-700 hover:text-gray-900 hover:font-semibold mb-4'>Learn in Finnish (No Tuition Fees Paid)</NavLink>
                   <NavLink to={"/study-in-english"} onClick={() => { setIsOpen(!isOpen); setToggle(false) }} className=' block text-[12px] font-normal cursor-pointer text-green-700 hover:text-gray-900 hover:font-semibold mb-3'>Learn in English (Tuition Fees Paid)</NavLink>
                 </div> */}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default AgentNavbar