import React from 'react'
import Navbar from '../Navbar'
import businessBanner from "../../img/business-man-banner.png";
import Footer from '../Footer';
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { AuthApis } from "../../apis/authApis";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../reducer/loginSlice";
import LoadingSpinner from '../UI/LoadingSpinner';

function BecomeAnAgentLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [loader, setLoader] = React.useState<boolean>(false);

  const [userData, setUserdata] = React.useState({
    'company_email': "",
    'password': "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };
  console?.log(userLoginData)

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    const formData = new FormData()
    formData.append('email', userData?.company_email)
    formData.append('password', userData?.password)

    AuthApis.login(formData).then(
      (response) => {
        if (response?.data) {
          console.log(response.data.data.account_id)
          toast.success(response?.data?.message);
          if (response?.data?.success === true) {
            // console?.log(response.data.data)
    setLoader(false)

            if (dispatch(login({ email: userData?.company_email,isVerified:response.data.data?.isVerified, token: response.data.data.token, id: response.data.data.account_id, name: response.data.data.name, data: response.data.data }))) {
              navigate('/agent-portal');
              // 
              window.location.reload()
            }




          }
        } else {
    setLoader(false)

          toast.warn('Invalid Login Credentials');
        }
      }
    ).catch(function (error) {
      // handle error
    setLoader(false)

      console.log(error.response.data);
      toast.error(error.response.data?.message);
    }).finally(() => {
      // toast.error("No Internet Connection");
      setLoader(false)

    });
  }



  return (
    <div>
    <Navbar />

    <section className='pt-4'>
      <div
        style={{
          backgroundImage: `url(${businessBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className=" lg:px-14 md:pb-24 pb-10 md:pt-16 md:mx-auto px-6 flex flex-col md:flex-row md:justify-between lg:space-x-14 relative"
      >
        <div className='md:w-6/12 mt-24'>
          <h1 className='text-white md:text-[60px] text-[40px] font-bold'>Partner with Us <br />and enjoy great<br /> benefits</h1>
          <p className='text-white font-light mt-3'>Together we could achieve a lot and realize the <br />dreams of millions of African students. </p>
        </div>

        <div className='md:w-6/12'>
          <form onSubmit={handleSubmit}>
            <div className='bg-white mt-[70px] p-6 border border-[#C4C4C4] rounded-[20px]'>
              <div className="mb-6 w-full md:px-4">
                <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                  Company's Email Address
                </label>
                <input
                  type="email"
                  className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3 rounded-[10px]"
                  placeholder=""
                  name="company_email"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4 w-full md:px-4">
                <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                  Password
                </label>
                <input
                  type="password"
                  className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3 rounded-[10px]"
                  placeholder=""
                  name="password"
                  onChange={handleChange}
                  required
                />
              </div>
            
<div className='flex justify-end'>
    <NavLink to="/forgot-password">
    <p className="text-[#48B774] text-[12px] font-semibold">Forgot Password?</p>
    </NavLink>
</div>
             

              <div className='md:px-4 flex justify-center'>
                <button
                  type="submit"

                  className=" text-white bg-[#1DB459] rounded-[10px] font-medium text-sm md:px-6 px-3 md:py-3 py-2.5 mb-2"
                >
             {loader ? <LoadingSpinner /> : "Log In"}   
                </button>
              </div>
              <p className="text-center text-xs font-semibold">
              Don't have an account? 
                <NavLink to="/become-an-agent">
                  <span className="text-[#48B774] cursor-pointer font-bold"> Sign Up</span>
                </NavLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
    <Footer />

    <ToastContainer
              position="bottom-left"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover />
  </div>
  )
}

export default BecomeAnAgentLogin