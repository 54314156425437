import React, { useState } from "react";
import Navbar from "../Navbar";
import { useParams } from "react-router-dom";
import { AuthApis } from "../../apis/authApis";
import { ToastContainer, toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'

const DiplomaApplication = () => {
  const params: any = useParams();
  let details: string = params?.diploma;

  // console.log(details)
  const programs = details.split(",");
  let [qualification, setQualification] = React.useState<any>("");
  let [certificate, setCertificate] = React.useState<any>("");
  let [profession, setProfession] = React.useState<any>("");
  let [firstTimeAbroad, setFirstTimeAbroad] = React.useState<any>("");
  let [oneYear, setOneYear] = React.useState<any>("");
  let [canAfford, setCanAfford] = React.useState<any>("");
  let [whoIsPaying, setWhoIsPaying] = React.useState<any>("");
  let [hasDependent, setHasDependednt] = React.useState<any>("");
const [open, setOpen] = useState(true);
const onOpenModal = () => setOpen(true);
const onCloseModal = () => setOpen(false);

  const [userData, setUserdata] = React.useState({
    full_names: "",
    phone_number: "",
    email: "",
    has_dependent: "",
    dependent_sponsor: "",
    can_afford_tuition: "",
    interested_in_program: [],
    first_time_abroad: [],
    age: [],
    gender: [],
    current_certificate: [],
    profession: [],
    academic_qualifications: [],
    country_of_residence: [],
  });

  const [loader, setLoader] = React.useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
    console.log(userData)
    console.log(name)
  };

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const userData2 = {
        full_names: userData?.full_names,
        phone_number: userData?.phone_number,
        email: userData?.email,
        country_of_residence: [userData?.country_of_residence],
        has_dependent: hasDependent,
        dependent_sponsor: whoIsPaying,
        can_afford_tuition: canAfford,
        interested_in_program: [userData?.interested_in_program],
        first_time_abroad: [firstTimeAbroad],
        age: [userData?.age],
        gender: [userData?.gender],
        current_certificate: [certificate],
        profession: [profession],
        academic_qualifications: [qualification],
        // country_of_residence: userData?.country_of_residence,
      };

      console.log(userData2);

      AuthApis.createDiplomaCourse(userData2)
        .then((response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            console.log(response?.data);
            setLoader(false);

            // Redirect to payment link if available
            if (response?.data?.payment_link) {
              window.location.replace(response?.data?.payment_link);
            } else {
              toast.error("Something went wrong!");
            }
          }
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            toast.error("Form not rightly filled. Kindly cross-check.");
          } else {
            toast.error("Some error occurred. Please try again.");
          }
        })
        .finally(() => {
          setLoader(false); // Ensure loader is turned off
        });
    },
    [
      qualification,
      certificate,
      profession,
      firstTimeAbroad,
      canAfford,
      whoIsPaying,
      hasDependent,
      userData,
    ]
  );

  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="md:pt-20 pt-20">
        <div className="bg-[#FBFBFB] sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-8">
          <div className=" w-full mx-auto">
            <div className="flex justify-center">
              <h1 className="md:text-[30px] text-center text-[20px] font-semibold">
                Welcome to the application page
              </h1>
            </div>
            <div className="md:pr-40 md:pl-40 mx-4 mt-10">
              <form onSubmit={handleSubmit}>
                <hr />
                <h3 className="mt-5 mb-6 font-medium text-[20px]">
                  APPLICATION OPTIONS
                </h3>
                <div className="mb-6 w-full">
                  <label
                    htmlFor="interested_in_program"
                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                  >
                    Add Study Program
                    <span className="text-[#EE1D52] font-bold"> *</span>
                  </label>

                  <select
                    id="interested_in_program"
                    name="interested_in_program"
                    onChange={handleChange}
                    required
                    className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                  >
                    <option selected></option>
                    {programs.map((program: string, index: number) => (
                      <option key={index} value={program}>
                        {program}
                      </option>
                    ))}
                  </select>
                </div>

                <hr />
                <h3 className="mt-5 mb-6 font-medium text-[20px] text-[1DB459]">
                  PERSONAL INFORMATION
                </h3>
                {/* <div className='grid md:grid-cols-2 gap-4'> */}
                <div className="mb-6 w-full ">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Full Name<span className="text-[#EE1D52] font-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                    placeholder="Enter Name"
                    name="full_names"
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* </div> */}

                <div className="grid md:grid-cols-2 gap-4">
                  <div className="mb-6 w-full ">
                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                      Location (City, Country)
                      <span className="text-[#EE1D52] font-bold">*</span>
                    </label>
                    <input
                      type="text"
                      className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                      placeholder=""
                      name="country_of_residence"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-6 w-full ">
                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                      Phone Number
                      <span className="text-[#EE1D52] font-bold">*</span>
                    </label>
                    <input
                      type="number"
                      className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                      placeholder=""
                      name="phone_number"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-4">
                  <div className="mb-6 w-full ">
                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                      E-mail Address
                      <span className="text-[#EE1D52] font-bold">*</span>
                    </label>
                    <input
                      type="email"
                      className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                      placeholder=""
                      name="email"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-6 w-full">
                    <label
                      htmlFor="gender"
                      className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                    >
                      Gender<span className="text-[#EE1D52] font-bold"> *</span>
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      onChange={handleChange}
                      required
                      className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                    >
                      <option selected></option>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </select>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-4">
                  <div className="mb-6 w-full ">
                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                      Age<span className="text-[#EE1D52] font-bold">*</span>
                    </label>
                    <input
                      type="number"
                      className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                      placeholder=""
                      name="age"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <hr />
                <h3 className="mt-5 mb-6 font-medium text-[20px]">
                  ACADEMIC QUALIFICATION
                </h3>

                <div className=" grid md:grid-cols-3 gap-3 mb-6 w-full ">
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="academic_qualifications"
                      type="radio"
                      value=""
                      name="academic_qualifications"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setQualification("High School Graduate")}
                    />
                    <label
                      htmlFor="academic_qualifications"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      High School Graduate
                    </label>
                  </div>
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="academic_qualifications"
                      type="radio"
                      value=""
                      name="academic_qualifications"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setQualification("Polytechnic Graduate")}
                    />
                    <label
                      htmlFor="academic_qualifications"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Polytechnic Graduate
                    </label>
                  </div>
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="academic_qualifications"
                      type="radio"
                      value=""
                      name="academic_qualifications"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setQualification("University Graduate")}
                    />
                    <label
                      htmlFor="academic_qualifications"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      University Graduate
                    </label>
                  </div>
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="academic_qualifications"
                      type="radio"
                      value=""
                      name="academic_qualifications"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setQualification("Masters Graduate")}
                    />
                    <label
                      htmlFor="academic_qualifications"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Master's Graduate
                    </label>
                  </div>

                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="academic_qualifications"
                      type="radio"
                      value=""
                      name="academic_qualifications"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setQualification("PhD Graduate")}
                    />
                    <label
                      htmlFor="academic_qualifications"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      PhD Graduate
                    </label>
                  </div>
                </div>

                <h3 className="mt-5 mb-6 font-medium text-[20px]">
                  ACADEMIC CERTIFICATE
                </h3>

                <div className=" grid md:grid-cols-3 gap-3 mb-6 w-full ">
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="current_certificate"
                      type="radio"
                      value=""
                      name="current_certificate"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() =>
                        setCertificate("High School(WAEC/NECO/NABTEB)")
                      }
                    />
                    <label
                      htmlFor="current_certificate"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      High School(WAEC/NECO/NABTEB)
                    </label>
                  </div>
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="current_certificate"
                      type="radio"
                      value=""
                      name="current_certificate"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setCertificate("Polytechnic Graduate")}
                    />
                    <label
                      htmlFor="current_certificate"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Tertiary (Polytechnic/University)
                    </label>
                  </div>
                </div>

                <h3 className="mt-5 mb-6 font-medium text-[20px]">
                  WHAT IS YOUR PROFESSION?
                </h3>

                <div className=" grid md:grid-cols-3 gap-3 mb-6 w-full ">
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="profession"
                      type="radio"
                      value=""
                      name="profession"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setProfession("Student")}
                    />
                    <label
                      htmlFor="profession"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Student
                    </label>
                  </div>
                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="profession"
                      type="radio"
                      value=""
                      name="profession"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setProfession("Employed")}
                    />
                    <label
                      htmlFor="profession"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Employed
                    </label>
                  </div>

                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="profession"
                      type="radio"
                      value=""
                      name="profession"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setProfession("Unemployed")}
                    />
                    <label
                      htmlFor="profession"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Unemployed
                    </label>
                  </div>

                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="profession"
                      type="radio"
                      value=""
                      name="profession"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setProfession("Entrepreneur/Self empoyed")}
                    />
                    <label
                      htmlFor="profession"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Entrepreneur/Self empoyed
                    </label>
                  </div>

                  <div className="flex items-center w-full px-4 mb-1 ">
                    <input
                      id="profession"
                      type="radio"
                      value=""
                      name="profession"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onClick={() => setProfession("Retired/Pensioner")}
                    />
                    <label
                      htmlFor="profession"
                      className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                    >
                      Retired/Pensioner
                    </label>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <h3 className="mt-5 mb-6 font-medium text-[20px]">
                      Is this your first time applying to study abroad?
                    </h3>

                    <div className=" md:flex gap-3 mb-6 w-full ">
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="first_time_abroad"
                          type="radio"
                          value=""
                          name="first_time_abroad"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setFirstTimeAbroad("YES")}
                        />
                        <label
                          htmlFor="first_time_abroad"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          YES
                        </label>
                      </div>
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="first_time_abroad"
                          type="radio"
                          value=""
                          name="first_time_abroad"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setFirstTimeAbroad("No")}
                        />
                        <label
                          htmlFor="first_time_abroad"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="mt-5 mb-6 font-medium text-[20px]">
                      This program is for one year, are you interested?
                    </h3>

                    <div className=" md:flex gap-3 mb-6 w-full ">
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="lang_skills_1"
                          type="radio"
                          value=""
                          name="lang_skil"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setOneYear("YES")}
                        />
                        <label
                          htmlFor="lang_skills_1"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          YES
                        </label>
                      </div>
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="IELTS-test-2"
                          type="radio"
                          value=""
                          name="lang_skil"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setOneYear("No")}
                        />
                        <label
                          htmlFor="IELTS-test-2"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="mt-5 mb-6 font-medium text-[20px]">
                    The tuition fee is 9000 euros one time payment, can you
                    afford it?
                  </h3>

                  <div className=" md:fle gap-3 mb-6 w-full ">
                    <div className="flex items-center w-full px-4 mb-1 ">
                      <input
                        id="can_afford_tuition"
                        type="radio"
                        value=""
                        name="can_afford_tuition"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                        onClick={() => setCanAfford("YES")}
                      />
                      <label
                        htmlFor="can_afford_tuition"
                        className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                      >
                        YES
                      </label>
                    </div>
                    <div className="flex items-center w-full px-4 mb-1 ">
                      <input
                        id="can_afford_tuition"
                        type="radio"
                        value=""
                        name="can_afford_tuition"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                        onClick={() => setCanAfford("No")}
                      />
                      <label
                        htmlFor="can_afford_tuition"
                        className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <h3 className="mt-5 mb-6 font-medium text-[20px]">
                      If your answer is YES, who is paying?
                    </h3>

                    <div className=" gap-3 mb-6 w-full ">
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="dependent_sponsor"
                          type="radio"
                          value=""
                          name="dependent_sponsor"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setWhoIsPaying("Myself")}
                        />
                        <label
                          htmlFor="dependent_sponsor"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          Myself
                        </label>
                      </div>
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="dependent_sponsor"
                          type="radio"
                          value=""
                          name="dependent_sponsor"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setWhoIsPaying("Sponsor")}
                        />
                        <label
                          htmlFor="dependent_sponsor"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          Sponsor
                        </label>
                      </div>

                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="dependent_sponsor"
                          type="radio"
                          value=""
                          name="dependent_sponsor"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setWhoIsPaying("My Partner")}
                        />
                        <label
                          htmlFor="dependent_sponsor"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          My Partner
                        </label>
                      </div>

                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="dependent_sponsor"
                          type="radio"
                          value=""
                          name="dependent_sponsor"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setWhoIsPaying("My Parent(s)")}
                        />
                        <label
                          htmlFor="dependent_sponsor"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          My Parent(s)
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="mt-5 mb-6 font-medium text-[20px]">
                      When coming to Finland, are you coming alone or with a
                      dependent?
                    </h3>

                    <div className=" md:fle gap-3 mb-6 w-full ">
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="has_dependent"
                          type="radio"
                          value=""
                          name="has_dependent"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() => setHasDependednt("I am coming alone")}
                        />
                        <label
                          htmlFor="has_dependent"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          I am coming alone
                        </label>
                      </div>
                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="has_dependent"
                          type="radio"
                          value=""
                          name="has_dependent"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() =>
                            setHasDependednt("I am coming with my partner")
                          }
                        />
                        <label
                          htmlFor="has_dependent"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          I am coming with my partner
                        </label>
                      </div>

                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="has_dependent"
                          type="radio"
                          value=""
                          name="has_dependent"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() =>
                            setHasDependednt("I am coming with my kid(s)")
                          }
                        />
                        <label
                          htmlFor="has_dependent"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          I am coming with my kid(s)
                        </label>
                      </div>

                      <div className="flex items-center w-full px-4 mb-1 ">
                        <input
                          id="has_dependent"
                          type="radio"
                          value=""
                          name="has_dependent"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          onClick={() =>
                            setHasDependednt(
                              "I am coming with my partner and kid(s)"
                            )
                          }
                        />
                        <label
                          htmlFor="has_dependent"
                          className="w-full py-4 ml-3 text-sm font-medium text-gray-900  "
                        >
                          I am coming with my partner and kid(s)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-start mt-5">
                  <button
                    type="submit"
                    className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium text-[16px] px-6 py-2.5 mr-2 mb-2"
                  >
                    Submit Application
                  </button>

                  <div className="flex justify-center">
                    <MutatingDots
                      height="70"
                      width="100"
                      color="#4fa94d"
                      secondaryColor="#4fa94d"
                      radius="9.5"
                      ariaLabel="mutating-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loader}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

           <Modal open={open} onClose={() => null} center>
                            <div className='md:max-w-md  body-font font-poppins'>
                                <div className="flex flex-wrap mt-4">
                                    
                                    <h2 className='text-center text-[#1DB459] font-bold md:text-[26px] text-[22px]'>Accept Terms and Conditions</h2>
                                    <p className='text-center text-[#838383] md:text-[14px] text-[12px]'>Before you proceed , please take a moment to review and accept our Terms and Conditions.</p>
                                    <hr className="h-px mb-8 mt-[10px] bg-[#F0F4F9] border-0" />
                                    <h3 className='md:text-[16px] text-[14px] text-black font-light '><span className='font-bold'>Payment:</span>  By proceeding, you agree to pay $100 for the Registration fee.</h3>
                                    <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Refunds:</span>  The 100€ being paid will be a one time  non refundable fee</h3>
                                    <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Data privacy:</span>  Please be aware that any information provided will be used  to verify your identity for future referencing and also for your Visa and Residence Permit processing.</h3>
                                    <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Note:</span> Please be aware that AFRIPROEDU does not have the authority to grant admissions to students or influence any school's decision to accept them.
                                   <br/> Our role is to enhance your chances by carefully reviewing your documents to ensure they meet the eligibility criteria set by the school.</h3>
                                    <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                                   
                                    {/* <div className="flex justify-center items-center mb-3 mt-4">
                                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" required />
                                                    <label htmlFor="default-checkbox" className="ml-2 md:text-[14px] text-[12px] font-light text-black "> I accept the Terms and Conditions</label>
                                                </div> */}
                                    <div className='flex mx-auto space-x-4 mt-6'>
                                        <button
                                            type="button"
                                            onClick={onCloseModal}
                                            className=" text-white bg-[#1DB459] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                                        >
                                            Proceed
                                        </button>
        
                                    </div>
        
                                </div>
                            </div>
        
                        </Modal>
      </section>
      {/* <!-- Start block --> */}

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default DiplomaApplication;
