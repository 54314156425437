import React, { useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import wallet from "../../../img/wallet.svg"
import { DashboardApi } from '../../../apis/DashboardApi';
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from 'axios';
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
//@ts-ignore
import Clock from "./clock";


const customStyles = {
    modalContainer: {
        maxWidth: '30vw',
        minWidth: '30vw',
        // top: '30vh',
        // left: '40vw',
        // right: 'auto',
        // bottom: 'auto',
        //marginRight: '-50%',
        // borderRadius: '4%',
        // transform: 'translate(-50%, -50%)',
    },
};

// Modal.setAppElement('#yourAppElement');


function Wallet() {
    var currencyFormatter = require('currency-formatter');
    const userLoginData = useSelector((state: any) => state.data.login.value);

    const [toggleBtn, setToggleBtn] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const [availableBalance, setAvailableBalance] = React.useState(0);
    const [accountDetails, setAccountDetails] = React.useState<any>([]);
    const [instalAmount, setInstallAmount] = React.useState(1200);
    const [oneTimeAmount, setOneTimeAmount] = React.useState(6000);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [course, setCourse] = React.useState('');
    const [payCourseModal, setPayCourseModal] = React.useState(false);
    const [modalPayment, setModalPayment] = React.useState(false);
    const [newDate, setNewDate] = React.useState<any>(new Date().toISOString().slice(0, 10));
    const [before, setBefore] = React.useState<any>(accountDetails?.due_date)?.slice(0, 10);



    // const daysLeft = dateCoun(2020, 7, 24)
    // console.log(daysLeft) // Days left until　"2020 Tokyo Olympics"

    // console?.log(accountDetails?.due_date?.toDate());
    // const date = new Date();
    // const before  = new Date(accountDetails?.due_date);

    //  console.log(newDate)

    // var dif = (before?.getDate() - newDate?.getDate());
    // //  const d = dif / (1000 * 3600 * 24)
    // //  console.log("Subtracting days")
    // console.log(new Date(before)?.toISOString());

    // const diffTime = Math.abs((newDate - before));
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffTime + " milliseconds");
    // console.log(diffDays + " days");



    function openModal() {
        setIsOpen(true);
    }

    function openPayCourseModal() {
        setPayCourseModal(true);
    }

    React.useEffect(() => {
        DashboardApi.getWalletDetails().then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setAccountDetails(response?.data?.data)
                    setBefore(response?.data?.data?.due_date)
                    setAvailableBalance(response?.data?.data?.account_balance)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);

    if (searchParams.get('tx_ref')) {
        DashboardApi.getCallback(searchParams.get('tx_ref')).then(
            (response: any) => {
                if (response?.data) {
                    navigate('/wallet');
                    if (response?.data?.success === true) {
                        //navigate(response?.data?.data);
                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }



    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //   subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        setPayCourseModal(false);
    }

    function togglePayment() {
        setModalPayment(!modalPayment);
    }



    // const data = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    //     console?.log(e.target.value)
    // };

    const [userData, setUserdata] = useState({
        'amount': "",
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('amount', userData?.amount)

        DashboardApi.makePayment(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        window.location.replace(response?.data?.data?.data?.link);
                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }

    const payForLanguage = () => {
        const formData = new FormData()
        formData.append('course_price', (availableBalance < oneTimeAmount ? instalAmount?.toString() : oneTimeAmount?.toString()))
        formData.append('language_type', course)
        DashboardApi.payForCourse(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        setPayCourseModal(false);
                        setModalPayment(false);
                        toast.success(response?.data?.message)
                        DashboardApi.getWalletDetails().then(
                            (response: AxiosResponse<any>) => {
                                if (response?.data) {
                                    setAccountDetails(response?.data?.data)
                                    setAvailableBalance(response?.data?.data?.account_balance)
                                    window.location.reload()
                                }
                            }
                        ).catch(function (error) {
                            console.log(response?.data)
                            toast.warn(response?.data?.message);

                        })

                        //navigate(response?.data?.data);
                        // window.location.replace(response?.data?.data);
                    } else {
                        console.log(response?.data)
                        toast.warn(response?.data?.message);
                    }
                } else {
                    toast.warn(response?.data?.message);
                }
            }
        ).catch(function (error) {
            console.log(error?.response)
            toast.warn(error?.response?.data?.message);
        });
    }





    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar title="Wallet" />



                {/* <!-- Start block --> */}
                <div className='md:py-16 md:px-16 pl-3 pr-4'>

                    <div className="flex flex-wrap mt-4">
                        <div
                            style={{
                                backgroundImage: `url(${wallet})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                            className="w-full py-3 md:w-8/12 mb-12 xl:mb-0 px-4 rounded-lg">
                            <div className='flex justify-end mt-2'>
                                <button type="button"
                                    disabled={true}
                                    onClick={() => setToggleBtn((prev) => !prev)}
                                    className="text-white  border-white border font-medium rounded-lg text-sm px-4 py-1 mr-2 mb-2 focus:outline-none ">
                                    {!toggleBtn
                                        ? "Euro"
                                        : "Naira"}</button>
                            </div>
                            <div className='pl-5'>
                                <h3 className='text-white text-lg'>Available Balance </h3>
                                <h1 className='text-white text-4xl font-bold'>
                                    {!toggleBtn
                                        ? currencyFormatter.format(availableBalance, { code: 'EUR' })
                                        : "€ 5,500.00"

                                    }
                                </h1>
                            </div>

                            <div className='flex justify-between pl-5 mt-5'>
                                {/* <NavLink to={"/payment"}> */}
                                <button onClick={openModal} className="inline-flex items-center px-4 py-3 text-sm font-normal text-center text-white bg-[#1DB459] border border-[#1DB459] rounded-lg">
                                    Deposit Funds
                                    <svg
                                        aria-hidden="true"
                                        className="w-4 h-4 ml-2 -mr-1"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                                {/* </NavLink> */}

                                <button onClick={openPayCourseModal} className="inline-flex items-center px-4 py-3 text-sm font-normal text-center text-white bg-[#1DB459] border border-[#1DB459] rounded-lg">
                                    Pay for Course
                                    <svg
                                        aria-hidden="true"
                                        className="w-4 h-4 ml-2 -mr-1"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>

                        </div>
                        {userLoginData?.data?.user_data?.programs_type == 'study in finnish'
                            ?
                            <Clock deadline={before} />
                           :''}
                       
                    </div>
                </div>
                {/* <!-- End block --> */}


                {/* <!-- Start block --> */}

                <div className="flex flex-wrap mt-4 md:px-8 pl-3 pr-4">
                    <div className="w-full md:w-8/12 mb-12 xl:mb-0 px-4">
                        <div className="space-y-2 lg:grid lg:grid-cols-2 sm:gap-5  lg:space-y-0 lg:py-4 p-6 ">

                            <div className="  bg-[#FBFBFB] rounded-lg shadow pb-8 ">
                                <div className="pr-4 pl-3 flex justify-between">
                                    <h3 className=" text-lg text-[#01002E] mt-3 font-normal">Repayment ratio</h3>
                                    <p className="text-xs text-[#1DB459] cursor-pointer mt-5 uppercase font-semibold">
                                        View More
                                    </p>
                                </div>
                                <div className='pr-4 pl-3 flex space-x-5 mt-3'>
                                    <img src="/images/Dashboard/expenses.svg" alt="hero" />
                                    <div>

                                        <div className="flex justify-between space-x-16 mb-1 mt-3">
                                            <p className="text-xs font-medium text-[#01002E] ">Paid</p>
                                            <p className="text-xs font-semibold text-black ">{currencyFormatter.format(accountDetails?.amount_spent, { code: 'EUR' })}</p>
                                        </div>
                                        <div className="w-full bg-[#FDA741] rounded-full h-2.5 mt-3">
                                            <div className="bg-[#1DB459] h-2.5 rounded-l-full" style={{ width: "0%" }}></div>
                                        </div>
                                        <p className='text-[9px] mt-2'>You have paid back  25% of your Loan </p>

                                    </div>
                                </div>
                            </div>

                            <div className="  bg-[#FBFBFB] rounded-lg shadow pb-8">
                                <div className="pr-4 pl-3 flex justify-between">
                                    <h3 className=" text-base text-[#01002E] mt-3 font-normal">Next Payment Amount</h3>
                                    <p className="text-xs text-[#1DB459] cursor-pointer mt-4 uppercase font-semibold">
                                        View More
                                    </p>
                                </div>
                                <div className='pr-4 pl-3 flex justify-between space-x-7 mt-3'>
                                    <img src="/images/Dashboard/expenses.svg" alt="hero" />
                                    <div className='mt-5'>
                                        <h1 className='text-2xl font-bold'> {currencyFormatter.format(accountDetails?.next_paymemt_amount, { code: 'USD' })}</h1>
                                        <p className='text-[9px] mt-2'>Make minimum of one  payment(s) in a month </p>

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                    <div className="w-full md:w-4/12 px-4">
                        <div className="  bg-[#FBFBFB] rounded-lg shadow pb-8 ">
                            <div className="pr-4 pl-3 flex justify-between">
                                <h3 className=" text-base text-[#01002E] mt-3 font-normal">Next Repayment Dates </h3>
                                <p className="text-xs text-[#1DB459] cursor-pointer mt-4 font-semibold">
                                    Amount
                                </p>
                            </div>
                            <div className="pr-4 pl-3 flex justify-between mt-5 ">
                                <h3 className=" text-sm text-[#01002E] mt-3 font-normal">{(accountDetails?.due_date)?.slice(0, 10)} </h3>
                                <p className="text-xs text-[#1DB459] cursor-pointer mt-4 font-semibold">
                                    {currencyFormatter.format(accountDetails?.next_paymemt_amount, { code: 'USD' })}
                                </p>

                            </div>


                        </div>
                    </div>

                </div>
                {/* <!-- End block --> */}
                {/* <!-- Start block --> */}
                <div className="flex flex-wrap mt-4 md:px-8 pl-3 pr-4 pb-6 ">
                    <div className="w-full md:w-8/12 mb-12 xl:mb-0 px-4">
                        <div className=" pr-4 pl-5 pb-8 pt-4 bg-[#FBFBFB] rounded-lg shadow ">
                            <div className=''>
                                <h3 className=' font-bold text-base'>Loan Calculator</h3>
                            </div>
                            <div className='flex flex-col lg:flex-row lg:justify-between'>
                                <div className='mt-3 w-[50%]'>
                                    <div>
                                        <h5 className='text-xs text-[#838383] font-semibold'>Program Cost</h5>
                                        <h1 className='font-bold text-2xl mt-2'><sup>$</sup> 0</h1>
                                        <div className="w-full bg-[#D9D9D9] rounded-full h-1">
                                            <div className="bg-[#1DB459] h-1 rounded-full " style={{ width: "0%" }}></div>
                                        </div>
                                        <div className="flex justify-between ">
                                            <span className="text-xs font-medium text-[#C4C4C4] ">$0.00</span>
                                            <span className="text-xs font-medium text-[#C4C4C4] ">$7,000.00</span>
                                        </div>
                                    </div>

                                    <div className='mt-4'>
                                        <h5 className='text-xs text-[#838383] font-semibold'>Down Payment</h5>
                                        <h1 className='font-bold text-2xl mt-2'><sup>$</sup> 0</h1>
                                        <div className="w-full bg-[#D9D9D9] rounded-full h-1 ">
                                            <div className="bg-[#1DB459] h-1 rounded-full " style={{ width: "0%" }}></div>
                                        </div>
                                        <div className=" ">
                                            <span className="text-xs font-medium text-[#C4C4C4] ">20% of Real Program Cost</span>

                                        </div>
                                    </div>

                                    <div className='mt-5'>
                                        <h5 className='text-[#838383] text-xs font-bold'>Period</h5>
                                        <div className="flex items-center mt-2">
                                            <input id="default-radio-1" type="radio" value="" name="default-radio" className="w-3 h-3 text-blue-600 bg-[#F0F5FE] focus:ring-2" />
                                            <label htmlFor="default-radio-1" className="ml-2 text-xs font-medium text-[#838383] ">One Time Payment at € 6,000 </label>
                                        </div>
                                        {/* <div className="flex items-center  mt-2">
                                            <input id="default-radio-1" type="radio" value="" name="default-radio" className="w-3 h-3 text-blue-600 bg-[#F0F5FE]    focus:ring-2" />
                                            <label htmlFor="default-radio-1" className="ml-2 text-xs font-medium text-[#838383] ">Every month at € 1,500 for 4 months</label>
                                        </div> */}
                                        <div className="flex items-center  mt-2">
                                            <input id="default-radio-1" type="radio" value="" name="default-radio" className="w-3 h-3 text-blue-600 bg-[#F0F5FE]    focus:ring-2" />
                                            <label htmlFor="default-radio-1" className="ml-2 text-xs font-medium text-[#838383] ">Once a Month at € 1,200 for 5 months</label>
                                        </div>
                                    </div>

                                </div>

                                <div className=" pr-8 pl-5 bg-[#07421E] rounded-lg shadow pb-8 pt-4 lg:mt-0 mt-8">
                                    <div>
                                        <h3 className='text-white font-semibold text-sm'>Your total payment will be</h3>

                                    </div>
                                    <div className='flex justify-center mt-6'>
                                        <h1 className='font-bold text-4xl mt-2 text-white'><sup>$</sup> 0</h1>
                                    </div>
                                    <div className="flex justify-between mt-6">
                                        <span className="text-xs font-medium text-white ">Rate</span>
                                        <span className="text-xs font-medium  text-white ">Payment Date</span>
                                    </div>
                                    <div className="flex justify-between mt-3">
                                        <span className="text-xs font-medium  text-white ">0%</span>
                                        <span className="text-xs font-medium  text-white">00-00-0000</span>
                                    </div>
                                    <div className='flex justify-center mt-8'>
                                        <button type="button" className="text-black bg-white   font-bold rounded-lg text-xs px-5 py-2.5 mb-2 ">Continue to Payment</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="w-full md:w-4/12 px-4">
                        <div className="  pb-8 pt-4 bg-[#FBFBFB] rounded-lg shadow  ">
                            <div className='pl-2'>
                                <h3 className='pr-4 pl-5 font-bold text-base text-[#01002E]'>Recent Activities</h3>
                            </div>
                            <hr className="w-48 h-px mx-auto my-4 bg-[#E8E8E8] border-0 rounded md:my-6" />
                            <div>
                                <p className='pr-4 pl-5 text-xs font-normal'>Empty </p>

                            </div>
                            <hr className="h-px my-2 bg-[#E8E8E8] border-0" />
                            {/* <div>
                                <p className='pr-4 pl-5 text-xs font-normal'>You made deposit of 3 Million Naira into your Afripro Wallet </p>

                            </div> */}
                            <hr className="h-px my-2 bg-[#E8E8E8] border-0" />

                        </div>
                    </div>
                </div>
                {/* <!-- End block --> */}

            </div>



            <section>
                <Modal
                    open={modalIsOpen} onClose={closeModal} center
                >
                    <div className='flex justify-between mb-4'>
                        <p className='text-green-600 text-lg'>Please input Amount </p>
                        {/* <button onClick={closeModal} className="float-right">X</button> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-wrap mt-5">
                            <div className="w-full lg:w-12/12 ">
                                <div className="relative w-full mb-6">
                                    <label
                                        className="block text-[#404D61] text-sm font-medium mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Amount in Euro
                                    </label>
                                    <input
                                        type="number"
                                        name='amount'
                                        onChange={handleChange}
                                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                        placeholder=""
                                    />
                                </div>
                            </div>

                            <div className=' mt-8 '>
                                <button
                                    type="submit"
                                    className="flex justify-content-center text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-4 mr-2 mb-2"
                                >
                                    Continue
                                </button>
                            </div>

                        </div>

                    </form>
                </Modal>
            </section>



            {/* <section >
                <Modal
                    isOpen={payCourseModal}
                    className="flex justify-center mt-20 border-black"
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    //  style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className='flex justify-between mb-4'>
                        <p className='text-green-600 text-lg'>How would you like to pay? </p>
                        <button onClick={closeModal} className="float-right">X</button>
                    </div>
                    <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                        <div className='flex justify-content-start gap-4'>
                            <input onClick={togglePayment} id="checkbox-1" type="checkbox" name='payment_type' value="jhjh" className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                            <p className="font-normal text-gray-700 ">One time payment </p>
                        </div>
                    </div>
                    <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 my-3">
                        <div className='flex justify-content-start gap-4'>
                            <input id="checkbox-1" disabled type="radio" value=" " name='payment_type' className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                            <p className="font-normal text-gray-700 ">Installment Payment </p>
                        </div>
                    </div>

                    {modalPayment ?
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-wrap mt-5">
                                <div className="w-full lg:w-12/12 ">

                                    <p className='text-green-600 text-lg flex justify-center'>Select Language Course </p>
                                    <p className='flex justify-center w-auto text-xs'>Please Select the type of Language Course you would like to take </p>

                                    <div className="block mt-4 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                                        <div className='flex justify-content-start gap-4'>
                                            <input id="checkbox-1" name='language' type="radio" value="english-to-finnish" onClick={e => (setCourse('english-to-finnish'))} className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                            <p className="font-normal text-gray-700 ">English to Finnish </p>
                                        </div>
                                    </div>

                                    <div className="block mt-4 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                                        <div className='flex justify-content-start gap-4'>
                                            <input id="checkbox-1" name='language' type="radio" value="english-to-swedish" onClick={e => (setCourse('english-to-swedish'))} className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                            <p className="font-normal text-gray-700 ">English to Swedish </p>
                                        </div>
                                    </div>

                                    <div className="block mt-4 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                                        <div className='flex justify-content-start gap-4'>
                                            <input id="checkbox-1" name='language' type="radio" value="finnish-to-finnish" onClick={e => (setCourse('finnish-to-finnish'))} className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                            <p className="font-normal text-gray-700 ">Finnish to Finnish </p>
                                        </div>
                                    </div>

                                    <div className="relative w-full mb-6">
                                        <label
                                            className="block text-[#404D61] text-sm font-medium mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Amount
                                        </label>
                                        <input
                                            type="number"
                                            name='amount'
                                            onChange={handleChange}
                                            className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                            placeholder=""
                                        />
                                    </div>
                                </div>

                                <div className=' mt-8 '>
                                    <button
                                        type="submit"
                                        className="flex justify-content-center text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-4 mr-2 mb-2"
                                    >
                                        Make Payment
                                    </button>
                                </div>

                            </div>

                        </form>

                        :

                        ''
                    }
                </Modal>
            </section> */}


            <section>
                <Modal
                    open={payCourseModal} onClose={closeModal} center
                >
                    <div className='md:max-w-sm'>


                        {/* <div className='flex justify-between mb-4'>
                            <p className='text-green-600 text-sm'>How would you like to pay? </p>
                            
                        </div>
                        <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                            <div className='flex justify-content-start gap-4'>
                                <input id="checkbox-1" type="radio" value="jhjh" name='payment_type'  className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                <p className="font-normal text-gray-700 ">One time payment </p>
                            </div>
                        </div>
                        <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 my-3">
                            <div className='flex justify-content-start gap-4'>
                                <input id="checkbox-1" type="radio" value="" onClick={(e)=>console?.log(e.target)} name='payment_type' className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                <p className="font-normal text-gray-700 ">Installment Payment </p>
                            </div>
                        </div> */}



                        <div className="flex flex-wrap mt-5">
                            <div className="w-full lg:w-12/12 ">

                                <p className='text-red-600 text-sm flex justify-center align-middle'>{availableBalance < oneTimeAmount ? 'You have less than 6000 dollars in your wallet,we will activate installmental payment for you' : ''}</p>

                                <p className='text-green-600 text-sm flex justify-center'>Select Language Course </p>
                                <p className='flex justify-center w-auto text-xs'>Please Select the type of Language Course you would like to take </p>

                                <div className="block mt-4 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                                    <div className='flex justify-content-start gap-4'>
                                        <input id="checkbox-1" name='language' type="radio" value="english-to-finnish" onClick={e => (setCourse('english-to-finnish'))} className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                        <p className="font-normal text-gray-700 ">English to Finnish </p>
                                    </div>
                                </div>

                                <div className="block mt-4 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                                    <div className='flex justify-content-start gap-4'>
                                        <input id="checkbox-1" name='language' type="radio" value="english-to-swedish" onClick={e => (setCourse('english-to-swedish'))} className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                        <p className="font-normal text-gray-700 ">English to Swedish </p>
                                    </div>
                                </div>

                                <div className="block mt-4 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                                    <div className='flex justify-content-start gap-4'>
                                        <input id="checkbox-1" name='language' type="radio" value="finnish-to-finnish" onClick={e => (setCourse('finnish-to-finnish'))} className="w-4 h-4 mt-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 " />
                                        <p className="font-normal text-gray-700 ">Finnish to Finnish </p>
                                    </div>
                                </div>


                            </div>

                            <div className=' mt-8 '>
                                <button

                                    onClick={payForLanguage}
                                    className="flex justify-content-center text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-4 mr-2 mb-2"
                                >
                                    Make Payment
                                </button>
                            </div>

                        </div>





                    </div>
                </Modal>
            </section>

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default Wallet