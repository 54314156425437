import React, { useState } from 'react'
import Faq from '../Faq'
import Navbar from '../Navbar'
import Footer from "../Footer";
import FaqLithuania from '../FaqLithuania';

function FaqPage() {
    const [openFaqTab, setOpenFaqTab] = useState(1);
  
  return (
    <>
    <Navbar/>
    <div className='pt-20' >
       {/* <!-- Start block --> */}
       <section className="block">
      <div className="mx-auto text-center pt-5 md:pt-16">
          <p className="hidden md:block text-[#000000] md:text-3xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#48B774]">Questions</span>
          </p>
          <p className="block md:hidden text-[#000000] text-2xl px-6 font-semibold text-center">
            Frequently Asked{" "}
            <span className="text-[#48B774]">
              <br />
              Questions
            </span>
          </p>
        </div>

        <div className="md:mx-auto mx-6 xl:max-w-[400px] rounded-full text-sm font-medium text-center divide-x divide-[#1DB459] border mt-10 border-[#1DB459] flex ">
          <div
            onClick={() => setOpenFaqTab(1)}
            className={` ${
              openFaqTab === 1
                ? "bg-[#1DB459] text-[#ffffff] rounded-l-full"
                : ""
            }  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}
          >
           FINLAND
          </div>
          <div
            onClick={() => setOpenFaqTab(2)}
            className={` ${
              openFaqTab === 2
                ? "bg-[#1DB459] text-[#ffffff] rounded-r-full"
                : ""
            }  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}
          >
           LITHUANIA {" "}
          </div>
        </div>
        <div className={openFaqTab === 1 ? "block" : "hidden"}>
        <Faq />
        </div>
        <div className={openFaqTab === 2 ? "block" : "hidden"}>
        <FaqLithuania />
        </div>
      </section>

      {/* <!-- End block --> */}
    <Footer/>
    </div>

    </>
  )
}

export default FaqPage