import React, { useState } from "react";
// @ts-ignore
import Carousel from "react-grid-carousel";
import { Link, NavLink } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Animate, AnimateGroup, AnimateKeyframes } from "react-simple-animate";
// @ts-ignore
import { AnimateOnChange } from "react-animation";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "./../Navbar";
import image from "../../img/revamp-bg.png";
import bgbottom from "../../img/bottom.svg";
import Faq from "../Faq";
import WFooter from "../WFooter";
import Lottie from "lottie-react";
import animationData from "../assets/play.json";
import backG from "../../img/92d446ea4fc30bee8edaecb08ba8acce 1.svg";
import TestCarouselPage from "./TestCarouselPage";
import ProgramHomeModal from "./ProgramHomeModal";
import FaqLithuania from "../FaqLithuania";
import diplomaProgram from "../../Components/assets/diploma.json";


function Home() {
  const [toggleText, setToggleText] = useState(1);
  const [openTestTab, setOpenTestTab] = useState(1);
  const [openFaqTab, setOpenFaqTab] = useState(1);

  // console?.log(toggleText)

  const [openTestVid, setOpenTestVid] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  const [openSession, setOpenSession] = useState(false);

  const onOpenModalSession = () => setOpenSession(true);
  const onCloseModalSession = () => setOpenSession(false);

  React.useEffect(() => {
    onOpenModalSession();
  }, []);

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  };
  const prevBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft - 500;
  };

  const carouselItems = [
  
    {
      imgSrc: "/images/AfriProEdu - Masters Programs.jpg",
      link: "/list-of-courses",
      buttonText: "Apply Now",
    },
    {
      imgSrc: "/images/AfriProEdu - BSc Programs.jpg",
      link: "/list-of-courses",
      buttonText: "Apply Now",
    },
    {
      imgSrc: "/images/AfriProEdu - Notif 02.jpg",
      link: "/easygoedu-with-afriproedu",
      buttonText: "Apply Now",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
    },
  };
  return (
    <>
      <Navbar />
      <div className="md:pt-[90px] pt-[85px]">

     
      <div className="bg-[#0A2E04]  ">
        <div className="md:flex hidden justify-center">

    
        <div className="flex items-center gap-4">
<div className="flex justify-center items-center text-white">
<img src="/images/foundation_burst-new.png" alt="" />
<h4 className="text-[20px] font-[500]">Education Management </h4>
{/* <h4 className="text-[20px] font-[500]">Study in Lithuania </h4> */}
{/* <img src="/images/Lithuania (LT).png" alt="" /> */}
<h5 className="text-[12px]">(Diploma)</h5>
{/* <h5 className="text-[12px]">(Masters and Degree Programs)</h5> */}


</div>
{/* <Link to={"/study-in-lithuania"} className="bg-[#FFBA40] rounded-[12px] text-[10px] text-white px-2 py-2">
  Check Available Programs
</Link> */}
{diplomaProgram?.courses?.map((datas:any) => (
 <NavLink
 to={`/course-details/${(datas?.name).replace(/ /g, "-")}`}
 // key={index}
 state={{ category: "diploma program" }} className="bg-[#FFBA40] rounded-[12px] text-[10px] text-white px-2 py-2">
Check Available Programs
</NavLink>
))}

</div>
</div>
<div className="flex md:hidden py-1 justify-center">

    
<div className="flex items-center ">
<img src="/images/foundation_burst-new.png" className="" alt="" />
<div className="flex flex-col justify-center items-center text-white">
  <div className="flex ">
<h4 className="text-[18px] font-[400]">Education Management </h4>
{/* <h4 className="text-[18px] font-[400]">Study in Lithuania </h4> */}
{/* <img src="/images/Lithuania (LT).png" alt="" /> */}
</div>
<h5 className="text-[12px]">(Diploma)</h5>
{/* <h5 className="text-[12px]">(Masters and Degree Programs)</h5> */}

{/* <Link to={"/study-in-lithuania"} className="bg-[#FFBA40] rounded-[12px] text-[8px] text-white px-2 py-2">
Check Available Programs
</Link> */}
{diplomaProgram?.courses?.map((datas:any) => (
 <NavLink
 to={`/course-details/${(datas?.name).replace(/ /g, "-")}`}
 // key={index}
 state={{ category: "diploma program" }}
  className="bg-[#FFBA40] rounded-[12px] text-[10px] text-white px-2 py-2">
  Check Available Programs
</NavLink>
))}
</div>

</div>
</div>
      </div>
       </div>
      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins ">
        
        <div className="hidden md:block">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="grid  px-8 lg:px-14  pb-8  lg:gap-8 xl:gap-0 lg:pt-10 lg:grid-cols-12 "
          >
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-[48px] text-[#171717] font-[500] md:leading-[52px] tracking-normal  md:text-3xl xl:text-5xl">
                {/* Empowering African
                <h1>students to build the future</h1>
                <h1>of Africa through Finnish</h1>{" "}
                <div
                  className="md:w-[300px] md:h-[70px] w-[200px] h-[50px] rounded-[50%] 
                flex items-center justify-center 
                 border-[3px] border-[#FCD27C] font-[500]"
                >
                  Education
                </div> */}
                Experience Tuition-Free Education: Study in Finland with AfriProEdu
              </h1>

              <p className=" max-w-2xl   mb-6 mt-3 font-normal text-gray-500 lg:mb-8 text-base ">
                {/* Unlocking Opportunities through Finnish Education */}
                Study for free in Finland and access world-class education by choosing AfriProEdu. We will walk with you through every step of the process—from mastering the Finnish language to relocating to Finland. Join thousands of students who are transforming their futures with free education in the happiest country in the world.
              </p>

              <div className="flex ">
                <NavLink to="/terms" className="">
                  <button
                    type="submit"
                    className="py-2.5 px-5  text-sm font-medium text-white bg-[#48B774] rounded-lg border border-[#48B774] "
                  >
                  Get Started
                  </button>
                </NavLink>
                <NavLink to="/programs-overview">
                  <button
                    type="button"
                    className="ml-5 text-[#48B774] bg-[#D7F5DC]  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                  >
                   Find Programs
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="hidden md:block my-6 lg:mt-0 lg:col-span-5 lg:flex w-full">
              <img src="/images/revamp-hero.png" alt="hero" />
            </div>
          </div>
        </div>

        <div className="">
          <div
            className="block md:hidden bg-gradient-to-b from-[#D5FEDB] to-[#FBFBFB] pt-8 pb-8 px-6"
            // style={{
            //   backgroundImage: `url(${homemobile})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundSize: "",
            // }}
          >
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-2xl text-[#171717] font-[600] md:leading-10 tracking-normal  md:text-3xl xl:text-5xl">
                <span className="">Empowering</span> African <br />
                students <span className="">to</span> build the future <br />
                <span className=""> of</span> Africa through Finnish
                <div
                  className=" md:h-[70px] w-[180px] h-[50px] rounded-[50%] 
                flex items-center justify-center 
                 border-[3px] border-[#FCD27C] font-[600]"
                >
                  Education
                </div>
              </h1>

              <p className="max-w-[350px]   mb-6 mt-3 font-normal text-gray-500 lg:mb-8 text-base ">
                Unlocking Opportunities through Finnish Education
              </p>
              <div className="flex ">
                <NavLink to="/programs-overview" className="">
                  <button
                    type="submit"
                    className="py-2.5 px-5  text-sm font-medium text-white bg-[#48B774] rounded-lg border border-[#48B774] "
                  >
                    Find Programs
                  </button>
                </NavLink>
                <NavLink to="/programs-overview">
                  <button
                    type="button"
                    className="ml-5 text-[#48B774] bg-[#D7F5DC]  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                  >
                    How it works
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      {/* <section className="bg-[#ffffff] body-font font-poppins"> */}
      

     
        {/* <div className="max-w-screen-xl px-8 lg:px-14 md:pt-10 pb-8 mx-auto lg:gap-20">
        <div className="grid md:grid-cols-2">
          
          
       
      <div className="flex justify-center ">
      <iframe className="w-full hidden md:block h-[50vh] aspect-video " src="https://www.youtube.com/embed/8x1IGsdKqyE?si=YZil-va8bat0FxHn" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
      <iframe className="w-[70vw] md:hidden block h-full aspect-video " src="https://www.youtube.com/embed/8x1IGsdKqyE?si=YZil-va8bat0FxHn" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
     </div>
        
          <Carousel cols={2} rows={1} gap={30} loop>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
            
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  <img
                    src="/images/peter.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      I recently had a remarkable language experience that I
                      would like to share. It was a transformative experience
                      that I highly recommend to anyone seeking personal and
                      linguistic growth. A very big thank you to AfriProEdu for
                      helping me with my academic journey to Finland.
                    </p>
                    <h1 className="text-base  font-semibold">Peter</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
              
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  <img
                    src="/images/matilda.svg"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      I’m very happy I decided to apply to learn Finnish
                      language through AfriProedu. I heard about the program
                      from AfriProedu and they have being of huge help to this
                      achievement.They are very legit and safe and I highly
                      recommend them for everyone. Kiitos tosi paljon AfriProedu
                      .
                    </p>
                    <h1 className="text-base  font-semibold">Matilda</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
         
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  <img
                    src="/images/favour.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Applying to learn the Finnish language program through
                      AfriproEdu was a wonderful decision I made. I heard about
                      the program through AfriProEdu, and they have been of
                      great help to this achievement. They are very legit and
                      safe. And I recommend AfriproEdu for everyone. Kiitos
                      AfriproEdu!
                    </p>
                    <h1 className="text-base  font-semibold">Favour</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
             
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                <img
                    src="/images/Osose.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Learning The Finnish language through AfriProEdu with
                      Finest Future has been a lot more fun and helpful than I
                      could have ever imagined. Not only have I learnt the
                      language and Finnish culture but I’ve learnt about
                      teamwork and social skills in the classes.
                    </p>
                    <h1 className="text-base  font-semibold">Osose</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
            
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                <img
                    src="/images/Ornela.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Studying Finnish under AfriProEdu and Finest Future has
                      been an awesome and fascinating program for all foreigners
                      who want to five, stay and work in Finland. Studying
                      Finnish for all this time has been captivating.{" "}
                    </p>
                    <h1 className="text-base  font-semibold">Ornela</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
           
                <div className="flex flex-col lg:flex-row lg:space-x-6">
             <img
                    src="/images/Phoebe.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className=" mt-5">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      AfriProEdu and Finnest future program has highly competent
                      teachers who made learning easy and interesting, as well
                      as provide opportunities for foreign students to achieve
                      their dreams.{" "}
                    </p>
                    <h1 className="text-base  font-semibold">Phoebe</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
           
                <div className="flex flex-col lg:flex-row lg:space-x-6">
             <img
                    src="/images/Elodie.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className=" mt-5">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Learning Finnish has been a great experience so far,
                      thanks to AfriProEdu and Finnest future. Before being part
                      of this program, I wouldn't have never imagined learning a
                      new language. So, I would say that AfriProEdu and Finnest
                      future made me believe in myself.{" "}
                    </p>
                    <h1 className="text-base  font-semibold">Elodie</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
          </div> 
        </div> */}
      {/* </section> */}
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#ffffff]  pb-10 body-font font-poppins">
        <div className="grid max-w-screen-xl px-8 lg:px-14  pb-8 mx-auto lg:gap-8 xl:gap-0 lg:pb-10 lg:grid-cols-12 ">
          <div className=" my-6 lg:mt-0 lg:col-span-7 lg:flex w-full">
            <img src="/images/img2.svg" alt="location" />
          </div>

          <div className="mr-auto place-self-center lg:col-span-5 md:mb-[40px]">
            <h1 className="max-w-2xl mb-4 md:text-[38px] text-[32px] text-[#171717] md:leading-[42px] leading-10 font-semibold ">
              Finnish Education made easier just for you
            </h1>
            <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 text-base">
              Helping students study in Finland is one of our top priorities.
              Not only do we want to help, but we also want to make the
              experience easier and more seamless. So we introduced two
              pathways:
            </p>
            <div className="flex justify-between mb-6">
              <NavLink to="/study-in-english">
              <div className="flex space-x-2">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1db459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[16px] text-[13px] font-normal">
                  English Pathway
                </h3>
              </div>
              </NavLink>

              <NavLink to="/study-in-finnish">
              <div className="flex space-x-2 ">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1db459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[16px] text-[13px] font-normal">
                  Finnish Pathway
                </h3>
              </div>
</NavLink>

            </div>

            <NavLink to="/programs-overview" className="mt-5">
              <button
                type="button"
                className=" text-[#ffffff] bg-[#48B774]  font-medium rounded-[5px] text-sm px-5 py-2.5 text-center inline-flex items-center"
              >
                Learn More
              </button>
            </NavLink>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      {/* <section className="  pb-5 body-font font-poppins bg-[#ffffff]">    
        <div className="bg-black md:mx-auto mx-4  shadow rounded-[15px] md:max-w-[1200px] md:mt-6">
          <div className={toggleText === 1 ? "block " : "hidden"}>
            <div className="flex flex-col-reverse items-center md:flex-row  ">
              <div className="flex flex-col justify-between py-6 px-6 lg:px-6 md:px-[50px] leading-normal">
                <h3 className="mb-2 md:text-[32px] text-[25px] font-bold tracking-tight text-white">Why Finland is the<br /> best choice?</h3>
                <h5 className="mb-2 md:text-[28px] text-[22px] font-bold tracking-tight text-white">Best Education in the World</h5>
                <p className=" max-w-2xl mb-3 font-light text-white text-[14px]">Eight of the 2019 top ten economies were part of this elite group last year. Finland remains at the apex, with strength across each category, and edged out its nordic neighbour Sweden, which advanced to runner-up.</p>
                <div className={toggleText === 1 ? " flex space-x-6 mt-14" : "hidden"}>
                  <div
                    onClick={() => setToggleText(1)}
                    className={` ${toggleText === 1 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}     font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>01</div>
                  <div
                    onClick={() => setToggleText(2)}
                    className={` ${toggleText === 2 ? "bg-[#1DB459] text-[#ffffff] " : "text-white "} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>02</div>
                  <div
                    onClick={() => setToggleText(3)}
                    className={` ${toggleText === 3 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}     font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>03</div>
                  <div
                    onClick={() => setToggleText(4)}
                    className={` ${toggleText === 4 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}    font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>04</div>
                  <div
                    onClick={() => setToggleText(5)}
                    className={` ${toggleText === 5 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}     font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>05</div>
                </div>
              </div>
              <div>
                <img src="/images/finn.png" alt="location" />
              </div>
            </div>
          </div>
          <div className={toggleText === 2 ? "block " : "hidden"}>
            <div className="flex flex-col-reverse items-center   md:flex-row  ">
              <div className="flex flex-col justify-between py-6 px-6 lg:px-6 md:px-[50px] leading-normal">
                <h3 className="mb-2 md:text-[32px] text-[25px] font-bold tracking-tight text-white">Why Finland is the<br /> best choice?</h3>
                <h5 className="mb-2 md:text-[28px] text-[22px] font-bold tracking-tight text-white">Safest country to live in</h5>
                <p className=" max-w-2xl mb-3 font-light text-white text-[14px]">Finland ranks first in the world for Environmental Health (99.3) and Air Quality (98.8), as well as achieving perfect 100s for Sanitation & Drinking Water and Heavy Metals Exposure.</p>
                <div className={toggleText === 2 ? " flex space-x-6 mt-14" : "hidden"}>
                  <div
                    onClick={() => setToggleText(1)}
                    className={` ${toggleText === 1 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>01</div>
                  <div
                    onClick={() => setToggleText(2)}
                    className={` ${toggleText === 2 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}    font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>02</div>
                  <div
                    onClick={() => setToggleText(3)}
                    className={` ${toggleText === 3 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}  font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>03</div>
                  <div
                    onClick={() => setToggleText(4)}
                    className={` ${toggleText === 4 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}  font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>04</div>
                  <div
                    onClick={() => setToggleText(5)}
                    className={` ${toggleText === 5 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>05</div>
                </div>
              </div>
              <div>
                <img src="/images/finn2.png" alt="location" />
              </div>
            </div>
          </div>

          <div className={toggleText === 3 ? "block " : "hidden"}>
            <div className="flex flex-col-reverse items-center   md:flex-row  ">
              <div className="flex flex-col justify-between py-6 px-6 lg:px-6 md:px-[50px] leading-normal">
                <h3 className="mb-2 md:text-[32px] text-[25px] font-bold tracking-tight text-white">Why Finland is the<br /> best choice?</h3>
                <h5 className="mb-2 md:text-[28px] text-[22px] font-bold tracking-tight text-white">Happiest nation in the world</h5>
                <p className=" max-w-2xl mb-3 font-light text-white text-[14px]">Since 2002, the world Happiness Report has used statistical analysis to determine the world's happiest countries. In it's 2021 update, the report concluded that finland is the Happiest country in the world.</p>
                <div className={toggleText === 3 ? " flex space-x-6 mt-14" : "hidden"}>
                  <div
                    onClick={() => setToggleText(1)}
                    className={` ${toggleText === 1 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}  font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>01</div>
                  <div
                    onClick={() => setToggleText(2)}
                    className={` ${toggleText === 2 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}  font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>02</div>
                  <div
                    onClick={() => setToggleText(3)}
                    className={` ${toggleText === 3 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}  font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>03</div>
                  <div
                    onClick={() => setToggleText(4)}
                    className={` ${toggleText === 4 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}   font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>04</div>
                  <div
                    onClick={() => setToggleText(5)}
                    className={` ${toggleText === 5 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>05</div>
                </div>
              </div>
              <div>
                <img src="/images/finn3.png" alt="location" />
              </div>
            </div>
          </div>

          <div className={toggleText === 4 ? "block " : "hidden"}>
            <div className="flex flex-col-reverse items-center   md:flex-row  ">
              <div className="flex flex-col justify-between py-6 px-6 lg:px-6 md:px-[50px] leading-normal">
                <h3 className="mb-2 md:text-[32px] text-[25px] font-bold tracking-tight text-white">Why Finland is the<br /> best choice?</h3>
                <h5 className="mb-2 md:text-[28px] text-[22px] font-bold tracking-tight text-white">Least Corruption in the world</h5>
                <p className=" max-w-2xl mb-3 font-light text-white text-[14px]">The CPI ranks 180 countries and territories around the world by their perceived levels of public sector corruption. The results are given on a scale of 0 (highly corrupt) to 100 (very clean).</p>
                <div className={toggleText === 4 ? " flex space-x-6 mt-14" : "hidden"}>
                  <div
                    onClick={() => setToggleText(1)}
                    className={` ${toggleText === 1 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>01</div>
                  <div
                    onClick={() => setToggleText(2)}
                    className={` ${toggleText === 2 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>02</div>
                  <div
                    onClick={() => setToggleText(3)}
                    className={` ${toggleText === 3 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>03</div>
                  <div
                    onClick={() => setToggleText(4)}
                    className={` ${toggleText === 4 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>04</div>
                  <div
                    onClick={() => setToggleText(5)}
                    className={` ${toggleText === 5 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>05</div>
                </div>
              </div>
              <div>
                <img src="/images/finn4.png" alt="location" />
              </div>
            </div>
          </div>

          <div className={toggleText === 5 ? "block " : "hidden"}>
            <div className="flex flex-col-reverse items-center   md:flex-row  ">
              <div className="flex flex-col justify-between py-6 px-6 lg:px-6 md:px-[50px] leading-normal">
                <h3 className="mb-2 md:text-[32px] text-[25px] font-bold tracking-tight text-white">Why Finland is the<br /> best choice?</h3>
                <h5 className="mb-2 md:text-[28px] text-[22px] font-bold tracking-tight text-white">Highest standard of living</h5>
                <p className=" max-w-2xl mb-3 font-light text-white text-[14px]">Finland is the 3rd best country in the worldwhen it comes to quality of life. Out of 169 countries, Finland scores highly on almost every index on the report, from basic needs and foundations of wellbeing to personal freedoms.</p>
                <div className={toggleText === 5 ? " flex space-x-6 mt-14" : "hidden"}>
                  <div
                    onClick={() => setToggleText(1)}
                    className={` ${toggleText === 1 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}  font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>01</div>
                  <div
                    onClick={() => setToggleText(2)}
                    className={` ${toggleText === 2 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"}  font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>02</div>
                  <div
                    onClick={() => setToggleText(3)}
                    className={` ${toggleText === 3 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>03</div>
                  <div
                    onClick={() => setToggleText(4)}
                    className={` ${toggleText === 4 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>04</div>
                  <div
                    onClick={() => setToggleText(5)}
                    className={` ${toggleText === 5 ? "bg-[#1DB459] text-[#ffffff] " : "text-white"} font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2 cursor-pointer`}>05</div>
                </div>
              </div>
              <div>
                <img src="/images/finn5.png" alt="location" />
              </div>
            </div>
          </div>
        </div>

      </section> */}

      {/* <!-- Start block --> */}

      <section className="  pb-5 body-font font-poppins w-full bg-[#DCEEE1]">
        <div
          style={{
            backgroundImage: `url(${backG})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        {/* <div className="text-center md:block hidden text-[#212427] md:text-2xl text-xl font-semibold py-5 md:py-10">
        Why<span className="text-[#48B774]"> Finland</span> is the best choice?
        </div> */}
        <div className="flex justify-center">
          <div className="text-center  text-[#212427] md:text-2xl md:max-w-xl max-w-[200px] text-xl font-semibold py-5 md:py-10">
            Why is<span className="text-[#48B774]"> Finland</span> the best
            choice?
          </div>
        </div>
        {/* <div className="flex justify-center mx-auto px-6 w-[80%] md:w-full">
          <img alt="mail" src="/images/finland-img.png" />
        </div> */}

        <div className="max-w-screen-xl px-8 lg:px-14  pb-8 mx-auto lg:gap-20">
          {/* <div className="flex justify-end text-white text-[24px] px-6 mb-4"> ➜</div> */}

          <Carousel cols={3} rows={1} gap={10} loop>
            <Carousel.Item>
              <div className=" lg:mt-0 ">
                {/* <motion.div
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 3 }}
              className="flex justify-center">
              <img alt="mail" src="/images/fin1.png" className="" />
            </motion.div> */}
                <div className="bg-white rounded-t-[10px] h-[450px]">
                  <div className="w-full">
                    <img
                      alt="mail"
                      src="/images/finn1.png"
                      className="w-full"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Best education system{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        The educational system in Finland is highly esteemed on
                        a global scale, with Finnish schools frequently ranking
                        at the top of international lists. The 2019 PISA
                        (Programme for International Student Assessment) results
                        reaffirmed Finland's strong performance in education.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex  justify-center">
                    <img
                      alt="mail"
                      className="w-full rounded-[10px]"
                      src="/images/fin2 1.png"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Safest country to live in{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        Finland ranks first in the world for environmental
                        health (99.3) and air quality (98.8), as well as
                        achieving perfect 100s for sanitation, drinking water,
                        and heavy metal exposure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center">
                    <img
                      alt="mail"
                      className="rounded-[10px] w-full"
                      src="/images/finnn3.png"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Happiest nation in the world{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        Since 2002, the World Happiness Report has used
                        statistical analysis to determine the world's happiest
                        countries. In its 2021 update, the report concluded that
                        Finland is the Happiest country in the world.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="w-full"
                      src="/images/finnn4.png"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Least Corruption in the world{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        The CPI ranks 180 countries and territories around the
                        world by their perceived levels of public sector
                        corruption. The results are given on a scale of 0
                        (highly corrupt) to 100 (very clean).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="w-full"
                      src="/images/finnn6.png"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Highest standard of living{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        Finland is the 3rd best country in the world when it
                        comes to quality of life. Out of 169 countries, Finland
                        scores highly on almost every index on the report, from
                        basic needs and foundations of wellbeing to personal
                        freedoms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="rounded-b-[10px] w-full "
                      src="/images/Freedom V1.jpg"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Freedom
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        Freedom and democracy are the beating heart of Finland.
                        Here you can shape your own future. Finland's inclusive
                        society fosters innovation and personal growth, ensuring
                        that every voice can contribute to the nation's
                        progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="rounded-b-[10px] w-full"
                      src="/images/Cleanliness V2.jpg"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Cleanliness{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        WHO ranks the air quality in Finland best in the world.
                        UNICEF ranks Finland's lakes the purest in the world.
                        These accolades highlight Finland's dedication to
                        environmental sustainability and the preservation of its
                        natural resources.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className=" lg:mt-0">
                <div className="bg-white overflow-hidden rounded-t-[10px] h-[450px]">
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      alt="mail"
                      className="rounded-b-[10px] w-full"
                      src="/images/Equality V2.jpg"
                    />
                  </div>
                  <div className="px-5 ">
                    <div className="flex justify-center text-center">
                      <h3 className="text-[#213D4F] text-[22px] font-bold">
                        Equality{" "}
                      </h3>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="  mt-3 font-normal max-w-[330px] text-xs lg:p-0">
                        Equality makes Finland one of the civilized countries in
                        the world.The nation's commitment to inclusivity and
                        equal opportunities for all its citizens fosters a
                        harmonious and just society.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="lg:bg-[#FBFBFB] pt-16  body-font font-poppins">
        <div className="grid max-w-screen-xl px-8 lg:px-14  mx-auto lg:gap-8 xl:gap-0 lg:py-10 lg:grid-cols-12 ">
          <div className=" my-6 lg:mt-10 lg:col-span-6 lg:flex w-full">
            <img src="/images/img1.svg" alt="location" />
          </div>

          <div className="mr-auto place-self-center lg:col-span-5 mb-20 lg:ml-10">
            <h1 className="max-w-2xl mb-4 md:text-[38px] text-[32px] text-[#171717] md:leading-[42px] leading-10 font-semibold ">
              Why you should <br />
              <span className="">Choose AfriProEdu</span>
            </h1>
            <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 text-base">
              AfriProEdu is the best choice for African students who want to
              study in Finland because it offers a unique combination of
              features and benefits that are specifically designed to support
              their success.
            </p>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="flex space-x-2">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1db459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">
                  Tuition-free Education
                </h3>
              </div>
              <div className="flex space-x-2 ">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1db459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">
                  High-quality Education
                </h3>
              </div>
              <div className="flex space-x-2">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1db459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">
                  Support for African Students
                </h3>
              </div>
              <div className="flex space-x-2 ">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1db459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">
                  Finnish Language Mastery
                </h3>
              </div>
              <div className="flex space-x-2">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1db459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">
                  Community & Network
                </h3>
              </div>
            </div>
            {/* <div className="flex justify-between mb-6">
            <div className="flex space-x-2">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#1db459" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z" /></svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">Tuition-free Education</h3>
              </div>
              <div className="flex space-x-2 ">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#1db459" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z" /></svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">High-quality Education</h3>
              </div>
            </div>
            <div className="flex justify-between mb-6">
            <div className="flex space-x-2">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#1db459" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z" /></svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">Support for African Students</h3>
              </div>
              <div className="flex space-x-2 ">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#1db459" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z" /></svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">Finnish Language Mastery</h3>
              </div>
            </div>
            <div className="flex justify-between mb-6">
            <div className="flex space-x-2">
                <div className="bg-[#D7F5DC] rounded-md w-[35px] h-[35px] inline-flex items-center justify-center ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#1db459" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z" /></svg>
                </div>
                <h3 className="md:mt-1 mt-2 text-[#777777] md:text-[14px] text-[12px] font-normal">Community & Network</h3>
              </div>
            </div> */}

            {/* <NavLink to="/programs-overview" className="mt-5">
              <button
                type="button"
                className=" text-[#ffffff] bg-[#48B774]  font-medium rounded-[5px] text-sm px-5 py-2.5 text-center inline-flex items-center"
              >
                Learn More
              </button>
            </NavLink> */}
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}
      <TestCarouselPage />
      {/* <!-- Start block --> */}
      <section className=" lg:py-16 py-10 bg-[#E7F2F3] body-font font-poppins">
        <div className="flex  justify-center items-center">
          <h3 className="flex-shrink font-semibold text-[#171717] md:text-[38px] text-[32px]">
            How it works
          </h3>
        </div>
        <div className="flex  justify-center items-center mt-2">
          <p
            className={
              openTestTab === 2
                ? "hidden md:block mb-6 text-center max-w-[950px] font-normal text-gray-500  lg:mb-8 text-base px-6"
                : "hidden"
            }
          >
            Students in the language learning program have the chance to
            participate in interactive sessions and adopt effective educational
            strategies that facilitate rapid and consistent progress.
          </p>
          <p
            className={
              openTestTab === 2
                ? "md:hidden block mb-6 text-center font-normal text-gray-500  lg:mb-8 text-base px-6"
                : "hidden"
            }
          >
            Students in the language learning program have the chance to
            participate in interactive sessions and adopt effective educational
            strategies that facilitate rapid and consistent progress.
          </p>

          <p
            className={
              openTestTab === 1
                ? "hidden md:block mb-6 text-center max-w-[950px] font-normal text-gray-500  lg:mb-8 text-base px-6"
                : "hidden"
            }
          >
            Start a transformative educational journey and discover a brighter
            future through world-class learning opportunities.
          </p>
          <p
            className={
              openTestTab === 1
                ? "md:hidden block mb-6 text-center font-normal text-gray-500  lg:mb-8 text-base px-6"
                : "hidden"
            }
          >
            Start a transformative educational journey and discover a brighter
            future through world-class learning opportunities.
          </p>
        </div>
        <div className="md:mx-auto mx-6 xl:max-w-[400px] rounded-full text-sm font-medium text-center divide-x divide-[#1DB459] border border-[#1DB459] flex ">
          <div
            onClick={() => setOpenTestTab(1)}
            className={` ${
              openTestTab === 1
                ? "bg-[#1DB459] text-[#ffffff] rounded-l-full"
                : ""
            }  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}
          >
            English Pathway
          </div>
          <div
            onClick={() => setOpenTestTab(2)}
            className={` ${
              openTestTab === 2
                ? "bg-[#1DB459] text-[#ffffff] rounded-r-full"
                : ""
            }  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}
          >
            Finnish Pathway{" "}
          </div>
        </div>

        <div className="xl:max-w-[1280px] mt-10 px-6 lg:px-14 mx-auto flex flex-col md:flex-row md:justify-between gap-10">
          <div>
            <img src="/images/eng-path.png" alt="location" />
          </div>
          <div className={openTestTab === 1 ? "block md:mr-[200px]" : "hidden"}>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                1
              </div>
              <div>
                <h3 className="mt-2 text-[#838383] text-[15px]">
                  {" "}
                  Create an account
                </h3>
                {/* <p className="text-[12px]">Get started by setting up your personalized account</p> */}
              </div>
            </div>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                2
              </div>
              <h3 className="mt-2 text-[#838383] text-[15px]">
                {" "}
                Complete your profile
              </h3>
            </div>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                3
              </div>
              <h3 className="mt-2 text-[#838383] text-[15px]">
                {" "}
                Select a course
              </h3>
            </div>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                4
              </div>
              <h3 className="mt-2 text-[#838383] text-[15px]">
                {" "}
                Secure your enrollment
              </h3>
            </div>
          </div>

          <div className={openTestTab === 2 ? "block md:mr-[150px]" : "hidden"}>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                1
              </div>
              <div>
                <h3 className="mt-2 text-[#838383] text-[15px]">
                  {" "}
                  Register With Us
                </h3>
                {/* <p className="text-[12px]">Get started by setting up your personalized account</p> */}
              </div>
            </div>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                2
              </div>
              <h3 className="mt-2 text-[#838383] text-[15px]">
                {" "}
                Lauguage Education Begins
              </h3>
            </div>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                3
              </div>
              <h3 className="mt-2 text-[#838383] text-[15px]">
                {" "}
                Reaching B1.1 Level
              </h3>
            </div>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                4
              </div>
              <h3 className="mt-2 text-[#838383] text-[15px]">
                {" "}
                Applying For Schools
              </h3>
            </div>
            <div className="flex space-x-2 p-4 mb-4 border border-[#1DB459]  rounded-[10px]">
              <div className="border border-[#1DB459] w-10 h-10 text-center text-[30px] rounded-[5px]">
                5
              </div>
              <h3 className="mt-2 text-[#838383] text-[15px]"> To Finland</h3>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      {/* <section className=" lg:py-16 py-10 body-font font-poppins">
        <div className="flex  justify-center items-center">
          <h3 className="flex-shrink font-semibold text-[#171717] md:text-[38px] text-[32px]">
            Our Events
          </h3>
        </div>

        <div className="bg-black md:mx-auto mx-4 py-6 px-6 lg:px-6 shadow md:rounded-[30px] rounded-[15px] md:max-w-[1200px] md:mt-6">
        <div className="flex flex-col items-center   md:flex-row  ">
            <div></div>
            <img src="/images/event2.png" alt="location" className="w-[40%]" />
            <div className="flex flex-col justify-between md:px-[50px] leading-normal">
              <h5 className="mb-2 md:text-[32px] text-[23px] font-bold tracking-tight text-white mt-2">Winter Camp in Finland</h5>
              <p className="mb-3 font-light md:text[16px] text-[14px] text-white">The Winter Camp is a program designed to offer international students the opportunity to explore Finland - “The Country of Happiness" - up close and see if this is the right country for them to study in the near future. 
              </p>
              <div className="flex space-x-5 mt-5">
                <NavLink to="/winter-camp">
                <button type="button" className="py-3 px-5 mr-2 mb-2 text-sm font-medium text-[#05401C] bg-white rounded-full border border-gray-200">See More</button>
                </NavLink>
              comment
               
                <button type="button" className="py-3 px-5 mr-2 mb-2 text-sm font-medium text-[#ffffff]  border border-[#ffffff] rounded-full ">See Other Events</button>
              comment
             
              </div>

              comment
              <div className="flex justify-end space-x-6 mt-6">
                <button

                  type="button" className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2">01</button>
                <button type="button" className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2">02</button>
                <button type="button" className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2">03</button>
              </div>
              comment

            </div>
          </div>

comment
          <div className="flex flex-col items-center   md:flex-row  ">
            <div></div>
            <img src="/images/event.jpeg" alt="location" className="w-[40%]" />
            <div className="flex flex-col justify-between md:px-[50px] leading-normal">
              <h5 className="mb-2 md:text-[32px] text-[23px] font-bold tracking-tight text-white mt-2">Afriproedu 1st Anniversary</h5>
              <p className="mb-3 font-light md:text[16px] text-[14px] text-white">It's been one year of  AfriProEdu making a real difference in the lives of African students. By providing access to high-quality Finnish education, and helping to create a brighter future for Africa. On October 27th, 2023, AfriProEdu will celebrate its one-year anniversary
              </p>
              <div className="flex space-x-5 mt-5">
                <button type="button" className="py-3 px-5 mr-2 mb-2 text-sm font-medium text-[#05401C] bg-white rounded-full border border-gray-200">Start Registration</button>
                <button type="button" className="py-3 px-5 mr-2 mb-2 text-sm font-medium text-[#ffffff]  border border-[#ffffff] rounded-full ">See Other Events</button>
              </div>
              <div className="flex justify-end space-x-6 mt-6">
                <button

                  type="button" className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2">01</button>
                <button type="button" className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2">02</button>
                <button type="button" className="text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-2 mr-2 mb-2">03</button>
              </div>
            </div>
          </div>
          comment
         

        </div>

        comment
        <div className="bg-black xl:max-w-[1200px] px-6 lg:px-8 py-6 mx-auto rounded-[30px]">
          <div className="flex flex-col md:flex-row md:justify-between ">
            <img src="/images/event1.png" alt="location" />
            <div className="flex flex-col justify-between">
              <h3 className="text-white">Name of Event to be added</h3>
            </div>
          </div>
        </div>
        comment
      </section> */}
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="  lg:px-16 p-3 ">
        <div className=" lg:block">
          <div className="flex justify-center pt-6">
            <div className="pb-6">
              <h5 className=" hidden lg:block text-[32px] text-center font-[600] text-gray-900 leading-7 ">
                Our Educational Partners
              </h5>
              <h5 className=" lg:hidden block text-center text-[26px]  font-semibold text-gray-900 leading-7 ">
                Our Educational Partners
              </h5>
{/* <div className="flex items-center gap-3">
              <img
                src="./images/logoerfg-removebg-preview.png"
                className=""
                alt="Afripro Logo"
              />
               
               
                </div> */}
            </div>
          </div>
          <div className=" w-full px-4 flex flex-col lg:flex-row lg:justify-between  bg-[#FBFBFC] border border-gray-100 rounded-lg shadow-md sm:p-8">
            {/* <div className=" lg:w-6/12">
              <hr className="w-20 h-2 mb-2 bg-[#48B774] border-0 rounded  " />
              <h5 className=" hidden lg:block text-[25px]  font-[500] text-gray-900 leading-7 ">
                Our Trusted Partners<br /> around
                the globe
              </h5>
              <h5 className=" lg:hidden block text-center text-[15px] pb-3  font-semibold text-gray-900 leading-7 ">
                Our Trusted Partners around
                the globe
              </h5>
            </div> */}
            <Marquee direction="right" speed={50}>
              <div className="flex flex-row items-center space-x-5 lg:space-x-10">
              
                {/* <img
                  src="/images/edusampo-logo.jpeg"
                  alt="paystack"
                  className="w-[150px]"
                /> */}
                {/* <img
                  src="/images/josa-removebg.png"
                  alt="josa"
                  className="w-[50%] lg:w-full"
                /> */}
              <a href="https://lab.fi/en/lab-separate-application" target="_blank">
                <img
                  src="/images/lab-logo.svg"
                  alt="afripro"
                  className="w-full"
                />

</a>
<a href="/" target="_blank">
          <img
                src="./images/logoerfg-removebg-preview.png"
                className="w-[160px] h-[20%] lg:h-[30%] lg:w-[200px]"

                alt="Afripro Logo"
              />

</a>
<a href="https://www.seamk.fi/en/" target="_blank">
           <img
                  src="/images/f9c1a37a-seamk-logo-valkoinen.svg"
                  alt="finest-future"
                  className="lg:w-full lg:h-full w-[80%]"
                />

</a>
<a href="https://www.takk.fi/fi/etusivu" target="_blank">
       <img
                  src="/images/takk-logo-removebg.png"
                  alt="takk"
                       className="w-full"
                />

</a>
<a href="https://www.uwasa.fi/fi" target="_blank">
        <img
                  src="/images/vaasa.png"
                  alt="takk"
                      className="w-full"
                />

</a>
                <a  target="_blank" href="https://www.lut.fi/fi">
                 <img
                  src="/images/lut1.png"
                  alt="takk"
                  className="lg:w-full lg:h-full w-[80%] lg:pr-5"
                />
                </a>
              </div>
            </Marquee>
        


          </div> 
        </div>

        {/* <div className=" block px-3">
          <div className="flex space-x-4 bg-white border border-gray-100 rounded-lg shadow-md p-4">

            <div>
              <hr className="w-20 h-2 mb-2 bg-[#48B774] border-0 rounded  " />
              <h5 className="mb-2 text-base font-semibold text-gray-900 ">
                Our Trusted Partners <br />
                around the globe
              </h5>
            </div>

            <div className="flex justify-center items-center" id="gallary">
              <img
                src="/images/edusampo-logo.jpeg"
                alt="hero"
                className="w-[150px] opacity-0"
              />
            </div>

          </div>
        </div> */}
      </section>

      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="block">
      <div className="mx-auto text-center pt-5 md:pt-16">
          <p className="hidden md:block text-[#000000] md:text-3xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#48B774]">Questions</span>
          </p>
          <p className="block md:hidden text-[#000000] text-2xl px-6 font-semibold text-center">
            Frequently Asked{" "}
            <span className="text-[#48B774]">
              <br />
              Questions
            </span>
          </p>
        </div>

        <div className="md:mx-auto mx-6 xl:max-w-[400px] rounded-full text-sm font-medium text-center divide-x divide-[#1DB459] border mt-10 border-[#1DB459] flex ">
          <div
            onClick={() => setOpenFaqTab(1)}
            className={` ${
              openFaqTab === 1
                ? "bg-[#1DB459] text-[#ffffff] rounded-l-full"
                : ""
            }  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}
          >
           FINLAND
          </div>
          <div
            onClick={() => setOpenFaqTab(2)}
            className={` ${
              openFaqTab === 2
                ? "bg-[#1DB459] text-[#ffffff] rounded-r-full"
                : ""
            }  w-full py-3 px-2 text[#777777] text-sm cursor-pointer`}
          >
           LITHUANIA {" "}
          </div>
        </div>
        <div className={openFaqTab === 1 ? "block" : "hidden"}>
        <Faq />
        </div>
        <div className={openFaqTab === 2 ? "block" : "hidden"}>
        <FaqLithuania />
        </div>
      </section>

      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins">
        <div
          style={{
            backgroundImage: `url(${bgbottom})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="  lg:px-14 pt-10 mx-auto px-6 flex flex-col lg:flex-row lg:space-x-14  relative"
        >
          <div className="mr-auto place-self-center lg:mb-20 mb-8">
            <h1 className="max-w-2xl mb-2 text-2xl text-white font-semibold leading-10 md:text-3xl xl:text-4xl">
              What are you waiting for?
            </h1>
            <h1 className="max-w-2xl mb-2 text-2xl text-[#48B774] font-semibold leading-10 md:text-3xl xl:text-4xl">
              Apply Now
            </h1>

            <div className=" lg:px-0">
              <p className="max-w-2xl  mt-6 mb-6 font-light text-white  text-sm leading-relaxed">
                Experience the finest opportunity Finland has to offer. Gain
                invaluable knowledge from our team of expert teachers to study
                tuition-free. The time to unlock your future is now!
              </p>
            </div>

            <NavLink to="/terms" className="mt-16">
              <button
                type="submit"
                className="py-3 px-16  text-sm font-medium text-white  rounded border border-white "
              >
                Apply Now
              </button>
            </NavLink>
          </div>
          <div className="hidden lg:block  lg:mt-0   px-6">
            <img src="/images/boy-girl.png" alt="location" />
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="md:max-w-2xl body-font font-poppins">
          <div className={openTestVid === 1 ? "flex flex-wrap mt-8" : "hidden"}>
            <iframe
              className="w-[50vw] h-full aspect-video "
              src="https://www.youtube.com/embed/UXOA5W_ndYo?si=9rxKdG_nb7MxbYC5"
            ></iframe>
          </div>

          <div className={openTestVid === 2 ? "flex flex-wrap mt-8" : "hidden"}>
            <iframe
              className="w-[50vw] h-full aspect-video "
              src="https://www.youtube.com/embed/L6o5KxTVTz4?si=JP9uqz1nmYRJ124Y"
            ></iframe>
          </div>
        </div>
      </Modal>
      {/* <!-- Start block --> */}

      <Modal open={openSession} onClose={onCloseModalSession} center>
        {/* <div className="md:max-w-md  body-font font-poppins">
          <div className=" mt-4">
            <h2 className="text-center text-[#1DB459] font-bold md:text-[20px] text-[18px] leading-8">
              Book a free consultation session with us now
            </h2>

        
            <ul className="flex flex-col gap-2">
              <span className=" md:text-[16px] text-[14px]">Timeline</span>
              <ol className="text-black  md:text-[14px] text-[12px]">
                <h3>
                  <span className="font-bold">Start Date:</span> July 8, 2024
                </h3>
              </ol>
              <ol className="text-black  md:text-[14px] text-[12px]">
                <h3>
                  <span className="font-bold">End Date:</span> July 19, 2024
                </h3>
              </ol>
              <ol className="text-black  md:text-[14px] text-[12px]">
                <h3>
                  <span className="font-bold">Daily Availability:</span> 12:00PM
                  - 4:00PM
                </h3>
              </ol>
            </ul>
            <div className="flex  space-x-4 mt-6">
              <a
                className=" text-white bg-[#1C8B48] rounded-[5px]  font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                href="https://calendly.com/stephanie-afriproedu/free-consultation-session-with-afriproedu"
                target="_blank"
              >
                Proceed
              </a>

              <button
                type="button"
                className=" text-white bg-[#FF0000] rounded-[5px]  font-medium text-sm md:px-5 px-8 md:py-3 py-2.5 mr-2 mb-2"
                onClick={onCloseModalSession}
              >
                Cancel
              </button>
            </div>
          </div>
        </div> */}

     <ProgramHomeModal />
      </Modal>
      <WFooter />

      {/* <!-- End block --> */}
    </>
  );
}

export default Home;
