import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { AdminApis } from "../apis/adminApi";
import { AxiosResponse } from "axios";

function Faq() {
  const [open, setOpen] = useState(1);
  const [openTestTab, setOpenTestTab] = useState(1);
  // const [openTestTab, setOpenTestTab] = useState("general");
  const [faqLists, setFaqList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState("");

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  // Function to sanitize HTML content
  const sanitizeHTML = (html: any) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    AdminApis.getAllFaq()
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setFaqList(response?.data?.data);
          // console.log(response?.data?.data);
          // Filter out "Master's Program" category
          // const filteredData = response?.data?.data.filter((course: any) => course.category !== "master\'s program");
          // setGroupList(filteredData);
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  // Define categories explicitly
  const categories = [
    "general",
    "payment",
    "english pathway",
    "finish pathway",
    "before application",
    "after application",
  ];

  // Filter FAQs based on the selected category
  const filteredFaqs = faqLists.filter(
    (faq: any) => faq.faq_category.toLowerCase() === openTestTab
  );
  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#FFFFFF] body-font pt-10 md:pt-20 font-poppins">
    
        <div className="flex flex-col md:flex-row md:justify-between  md:max-w-screen-xl  px-8 lg:px-24  pb-8 mx-auto lg:gap-20 ">
          {/* <div className="my-6 lg:mt-0 lg:pl-24 flex flex-row lg:flex-col max-w-[90vw] overflow-auto">
        {categories.map((category) => (
              <div
                key={category}
                onClick={() => setOpenTestTab(category)}
                className={` ${
                  openTestTab === category ? "bg-[#E4FFEF] text-[#1DB459]" : ""
                } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </div>
            ))}
          </div> */}

          <div className="my-6 lg:mt-0 lg:pl-24 flex flex-row lg:flex-col max-w-[90vw] overflow-auto">
            <div
              onClick={() => setOpenTestTab(1)}
              className={` ${
                openTestTab === 1 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >
              General
            </div>
            <div
              onClick={() => setOpenTestTab(2)}
              className={` ${
                openTestTab === 2 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >
              Payment
            </div>
            <div
              onClick={() => setOpenTestTab(3)}
              className={` ${
                openTestTab === 3 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >
              English Pathway
            </div>
            <div
              onClick={() => setOpenTestTab(4)}
              className={` ${
                openTestTab === 4 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] rounded-lg text-sm cursor-pointer`}
            >
              Finnish Pathway
            </div>

            <div
              onClick={() => setOpenTestTab(5)}
              className={` ${
                openTestTab === 5 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] rounded-lg text-sm cursor-pointer`}
            >
              Before Application
            </div>
            <div
              onClick={() => setOpenTestTab(6)}
              className={` ${
                openTestTab === 6 ? "bg-[#E4FFEF] text-[#1DB459]" : ""
              } pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] rounded-lg text-sm cursor-pointer`}
            >
              After Application
            </div>
          </div>

          <div className=" lg:mb-20 max-w-xl">
            {/* {filteredFaqs.map((faq:any, idx:any) => (
                <Accordion
                  key={faq.id}
                  open={open === faq.id}
                  className="px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg"
                >
                 
                  <AccordionHeader
                    onClick={() => handleOpen(faq.id)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    {faq.question}
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    {sanitizeHTML(faq.answer)}
                  </AccordionBody>
                </Accordion>
              ))}  */}

            <div className={openTestTab === 1 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 1}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(1)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can parents opt to arrange accommodation for their Children?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes. It is recommended to consider the distance from the
                    accommodation to the school to avoid too long travel to and
                    from the school.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 2}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(2)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the processes we offer?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>Access to our expert language teachers</li>
                      <li>Access to our Finnish Language certificate</li>
                      <li>Access to apply to our partner schools in Finland</li>
                      <li>Access to our language materials</li>
                      <li>
                        Access to our learning portals and entire language
                        learning journey.
                      </li>
                      <li>
                        Access to our visa and documentation processing and
                        support
                      </li>
                      <li>
                        Access to accommodation support and some necessities
                        needed on or before arrival to Finland
                      </li>
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 3}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(3)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the products we offer ( Long and Short)
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <div className="font-semibold">We have two programs:</div>
                    <span className="font-semibold">Short program:</span> You
                    will study the Finnish language in your home country for two
                    months before travelling to Finland, where you will continue
                    your studies for an additional four months. During this
                    time, accommodation and lunch will be provided. Following
                    the four-month program, you will underg o interviews with
                    our partner schools in Finland. Upon acceptance, you will
                    receive a school acceptance letter, which is a required
                    document for obtaining a residence permit in Finland.
                    <div>
                      <span className="font-semibold">Long program:</span> You
                      will undergo an extensive program to learn the Finnish
                      language in your home country for a duration of 8 to 10
                      months before embarking on your journey to Finland. This
                      program involves online Finnish language lessons conducted
                      by our experienced teachers, alongside fellow students
                      from various parts of the globe. The entire learning
                      process takes place in a virtual classroom setting. Upon
                      successful completion of the 8-month program, you will
                      advance to the next stage, which involves applying for an
                      interview with our affiliated schools. Upon acceptance,
                      you will receive an official acceptance letter, a crucial
                      document required for the processing of your residence
                      permit in Finland.
                    </div>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 4}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(4)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can I travel to Finland with my Family?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, you can travel with your family. We have the family
                    package option, and our service charge is completely free
                    for children at the age of 10 and below.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 5}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(5)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can an individual bring a dependant on a student visa?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, you have the option to bring a dependent with you while
                    on a study visa. The eligibility is based on the specific
                    dependent and their age.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 6}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(6)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Are the students allowed to work part-time?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, in Finland, students are allowed to work part-time from
                    the age of 15 years. This arrangement helps to cover most
                    (if not all) of the living costs. Students may work a
                    maximum of 25 hours per week during school time and a
                    maximum of 40 hours per week during the summer holiday. Many
                    Finnish students have had part-time jobs to experience the
                    work environment since high school.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 7}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(7)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    If the students are underage, do they need to be accompanied
                    by a guardian while traveling to Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Not necessarily. While all students have the option to
                    travel independently, those under 15 years old may have the
                    opportunity to utilize a minor-escort service provided by
                    the Airline. This service availability and age restrictions
                    vary depending on the airline, such as the case with Qatar
                    Airways. During our pilot year, all students aged 14-17 flew
                    unaccompanied, although parents could still arrange for an
                    escort if desired.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 8}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(8)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Is there any other expense to be covered by parents?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Yes, after successful acceptance from school: -
                    <ol className="space-y-1 list-disc list-inside">
                      <li> Visa Application Fee.</li>
                      <li> Air ticket to Finland.</li>
                      <li>
                        {" "}
                        International insurance – EUR 376 for 1st Year and goes
                        down to EUR 310 from 2nd Year.
                      </li>
                      <li>
                        {" "}
                        Student Residence Permit application fee (online) – EUR
                        240 for 1st Year, EUR 180 from 2nd Year.
                      </li>
                      <li>
                        {" "}
                        Residence Permit documentation fee (notarize etc.) – EUR
                        100.
                      </li>
                    </ol>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 46}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(46)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the application requirements for universities in
                    Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    While different for each university, here are some general
                    requirements for every Finnish university:
                    <ol className="space-y-1 list-disc list-inside">
                      <li>High school diploma or equivalent</li>
                      <li>Standardized test scores (SAT)</li>
                      <li>English language proficiency (TOEFL)</li>
                      <li>Motivation letter and recommendation letters</li>
                    </ol>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 47}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(47)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the scholarship opportunities available for
                    international students in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Several scholarship opportunities are available for
                    international students studying in Finland, such as:
                    <ol className="space-y-1 list-disc list-inside">
                      <li>
                        Finnish Government Scholarships: Offered to outstanding
                        international students pursuing postgraduate studies in
                        specific fields.
                      </li>
                      <li>
                        Erasmus+ Scholarships: Provides funding for exchange
                        students from European universities.
                      </li>
                      <li>
                        University-specific scholarships: Many universities
                        offer their scholarships for international students.
                      </li>
                    </ol>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 48}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(48)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the cost of living and studying in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Finland is generally more affordable compared to other
                    Western European countries. Given below are the cost of
                    living and studying in the country:
                    <ol className="space-y-1 list-disc list-inside">
                      <li>
                        Tuition fees: Public universities in Finland offer free
                        tuition for EU/EEA students, while international
                        students pay tuition fees ranging from €5,000 to
                        €20,000/ year.
                      </li>
                      <li>
                        Living expenses: Accommodation, food, and transportation
                        costs can range from €500 to €800/month.
                      </li>
                    </ol>
                    Many universities offer student housing options and
                    financial aid resources to help manage expenses.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 49}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(49)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    When should I apply for funding?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You can request AfriPay funding if you’ve applied to, been
                    accepted at, or are currently enrolled in an eligible
                    school. But we can’t finalize your application until you’ve
                    been accepted to your program. You can apply for funding up
                    to 12 months before your program starts. Our loans don’t
                    cover students with more than two years remaining in their
                    program. If this is you, check out our scholarships or
                    contact your school’s financial aid office
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 50}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(50)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    How much can I borrow?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    AfriPay offers loans between US$2,001 and US$50,000 per
                    academic period, with a lifetime limit of US$100,000. For
                    example, a student might borrow US$50,000 in their fall
                    semester and another US$50,000 in their spring semester.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 51}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(51)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are AfriPay’s interest rates?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Our interest rates are fixed, which means they won't change
                    as market interest rates change. Our current rates are
                    between 7.99% (8.89% APR¹) and 13.99% (14.98% APR¹).
                    Although we don’t negotiate interest rates, we do offer
                    three interest rate discounts to reduce your rate by up to
                    1.50%. You’ll get a 0.50% discount when you enroll in
                    autopay; another 0.50% discount when you make six
                    consecutive, on-time payments using autopay; and a final
                    0.50% deduction when you start a full-time, salaried job
                    after graduation!
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 52}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(52)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What else can I expect from an AfriPay loan?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    These are some top highlights when funding your education
                    with us:
                    <ol className="space-y-1 list-disc list-inside">
                      <li>
                        No cosigner or collateral required
                      </li>
                      <li>
                        Fixed interest rates with up to 1.50% in discounts
                        (details above)
                      </li>
                      <li>
                        Build your U.S. credit history with on-time payments
                      </li>
                      <li>
                        No fees to apply, free visa and career support to
                        customers
                      </li>
                      <li>100% digital application process</li>
                      <li>All loans are in USD</li>
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 53}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(53)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Do I need a credit history or cosigner to apply?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    AfriPay doesn’t require a credit history or cosigner to
                    apply because we make loan decisions based on your future
                    potential. If you do have a credit history, we’ll review it
                    looking for things like late payments and collections.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 54}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(54)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the repayment terms?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <b>
                      {" "}
                      While you’re in school (and six months after you
                      graduate),
                    </b>{" "}
                    you’re only required to make interest payments each month.
                    That way you can focus on your studies - while building
                    credit and getting used to making on-time payments!
                    <br />
                    <b>Six months after you graduate,</b> your 10-year repayment
                    term begins. At this point, you’ll start making full
                    principal and interest payments each month until your entire
                    balance is paid off.
                    <br />
                    <br />
                    There’s no prepayment penalty if you pay off part or all of
                    your loan ahead of time! Just contact your servicer to
                    request the correct payoff amount.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 55}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(55)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Does AfriPay offer visa support?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes! AfriPay offers free support letters to all our approved
                    students to assist their visa process. For students studying
                    in the U.S., our support letters will help you receive your
                    I-20 from your school and the visa application afterwards.
                    For those studying in Canada, our support letter can help
                    prove that you have enough funds to support your education
                    to both your school and Canadian government.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 56}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(56)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Which U.S. and Canadian schools are eligible?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    AfriPay supports 400+ schools in the U.S. and Canada! Check
                    out the full list on our website, or check your eligibility
                    to see if your school’s eligible. Schools in other countries
                    are not currently on our school list, and you’ll need to
                    reside in the country of your school while completing your
                    program
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 57}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(57)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What documents and personal information do I need to apply?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You’ll need your passport or other national ID to submit
                    your application. You’ll also need to submit information
                    about your program and how you’ll fund it in addition to an
                    AfriPay loan. Once you submit your application, you’ll
                    upload additional documents to your AfriPay account. We’re
                    happy to help if you have any issues uploading your
                    documents.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 58}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(58)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Why was my AfriPay application denied?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    We’re sorry we couldn’t approve your application. Please
                    know that AfriPay reviews each application carefully before
                    making a loan decision. The specific reason for decline
                    would have been communicated to you through your registered
                    email with the subject line “We are sorry, your loan
                    application was not approved”. If you need additional
                    information, please reach out to your dedicated relationship
                    manager. To start a new loan application, you can easily
                    begin the process online.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 59}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(59)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    How do I Know if I’m Eligible?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>
                        You want to study at a school & programme we support
                      </li>
                      <li>You’re from a country or state we support</li>
                      <li>You plan to study abroad (UK residents excepted)</li>
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 60}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(60)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    How do I Start my application?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>Receive an instant provisional offer</li>
                      <li>
                        You can complete the entire process online & get a
                        conditional offer within 5 business days!
                      </li>
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 61}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(61)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    How much can I borrow?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>
                        {" "}
                        Depending on your programme, you may request up to the
                        full cost of tuition
                      </li>
                      <li>
                        {" "}
                        For US & some other programmes you may also request for
                        living expenses
                      </li>
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 62}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(62)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What documents are required?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li> Proof of ID & proof of address</li>
                      <li> Proof of admission</li>
                      <li> Proof of income & savings (if applicable)</li>
                      <li> Proof of savings</li>
                      <li> Credit Report</li>
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 63}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(63)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the interest rate?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>
                        {" "}
                        Our variable rates are comprised of an individual fixed
                        margin rate plus a quarterly averaged US SOFR base rate
                      </li>
                    </ol>
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 64}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(64)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can my loan be used as proof of funds?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>
                        {" "}
                        Yes! Our loan confirmation letter is valid proof of
                        funds during your visa application
                      </li>
                      <li>
                        {" "}
                        We do not charge any fees for your loan
                        confirmation/sanction letter
                      </li>
                    </ol>
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            <div className={openTestTab === 2 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 9}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(9)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the Payment plan possibilities?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You can make payment of the service charges at once or 5
                    instalments
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 10}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(10)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the Payment Terms and Conditions ?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You must pay at least the first instalment of the service
                    charge to be enrolled to the Finnish program and begin
                    classes -If you decide to opt for payment by instalments,
                    you must ensure not to default on making payments as at when
                    due or you will be removed from the class till you can pay
                    your next instalment.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 11}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(11)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    In what Currency can I make payment?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You can make payment in Euro, Dollars . For Nigerian
                    citizens who want to pay into our local bank accounts must
                    ensure they pay the updated rates to naira.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            <div className={openTestTab === 3 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 12}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(12)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Do I need to be proficient in Finnish or take the IELTS exam
                    to enroll in this program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    No, you do not need to be proficient in Finnish or take the
                    IELTS exam to join this program.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 13}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(13)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    How does the admission process work for this program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    To gain admission, you'll need to pass a simple English
                    proficiency test and pay tuition fees.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 14}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(14)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can I bring my family with me while studying in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, you have the option to bring your family with you under
                    this program as long as you are able to provide their proof
                    of funds which covers their living expenses in Finland.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 15}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(15)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Is there a job placement guarantee for Nursing students in
                    Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, Nursing students have a guaranteed practical work
                    placement opportunity. This means you get to do an
                    internship for a period of time and upon successful
                    completion of your internship program, you will get a job
                    nut this depends on your attitude and devotion to doing your
                    internship very well.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 16}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(16)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can I work while studying Nursing in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, you can work and study simultaneously. By law in
                    Finland, students are allowed to work 30 hours per week.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 17}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(17)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    How long is the nursing program in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    The English language Nursing program is a two years program.
                    However the Finnish language Nursing is a 3 years program.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 18}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(18)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the process for obtaining a residence permit after
                    completing the program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You can apply for a residence permit after finishing your
                    studies.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 19}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(19)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    How are the tuition fees paid?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    The first year's fees are paid in full, while the
                    second-year fees can be paid in 8 instalments.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 20}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(20)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the exam assignment?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    The exam assesses your understanding of simple English
                    reading and comprehension, primary school-level mathematics.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 21}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(21)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Is the service fee of $100 for the exam?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    The service fee is not for the exam, it covers your visa
                    application process, documentation and school application.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 22}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(22)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Do I need prior Nursing education to apply for this program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    No, prior Nursing education is not required to apply.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 23}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(23)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the registration process, and is there a fee?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    To register, you need to pay a service charge of $100. Once
                    paid, your spot is guaranteed.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>
            <div className={openTestTab === 4 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 24}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(24)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can one work while studying the Vocational program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Students coming from outside of Finland with a student
                    residence permit are allowed to work part-time for up to 30
                    hours per week at most. You also get an average salary
                    between 10€- 11.30€/hr.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 25}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(25)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can one work immediately after finishing the Vocational
                    program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, you can start working immediately once you are done
                    with your vocational program. However this is determined by
                    companies you applied to but your chances of getting a job
                    is very high because of your studies in Finnish language.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 26}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(26)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Will an individual still enjoy free tuition after he/she is
                    done with the Vocational program and decides to further
                    his/her studies?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, you will still enjoy free tuition after you finish with
                    the Vocational program and decide to further your studies.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 27}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(27)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What happens if a student doesn&#39;t attend some language
                    classes during the week? Is there any way to gain access to
                    recordings?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Yes, there are recordings. Or they can take the weekly
                    module for the missed classes again next week or later. We
                    are teaching the same 8 modules in a running cycle, so even
                    if a student misses some lessons, they will be taught again
                    soon.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 28}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(28)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the duration of the Finnish language course?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    There are a total of four (4) levels in the course and a
                    student must finish all 4 levels. Fastest that students can
                    complete the WHOLE program is 8 months (6 months to achieve
                    a high enough level to apply to a school and residence
                    permit). No maximum duration. On average students will study
                    between 8-18 months with us.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 29}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(29)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What happens if a student fails exams at the end of any
                    level?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    A student can repeat an exam as many times as possible until
                    he/she passes. The first repeat (retake) will be free of
                    charge but will pay 50.00 Euro for each consecutive repeat.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 30}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(30)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can you please give some details on how the weekly language
                    classes are conducted?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    We have 5 lessons per week (Mon-Fri), 75minutes per lesson.
                    Monday and Tuesday are very important days and Friday is
                    about speaking. Each week follows a module/theme and each
                    day within a week has its own goal for learning. The classes
                    are potentially scheduled to start around 12:00 PM WAT ,
                    1:30 PM WAT or 3:30 PM WAT ( For later time you can contact
                    our customer service to discuss ) but students can either
                    join live classes and choose which days of the week they
                    want to attend or watch recordings.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 31}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(31)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Is the Finnish language self-taught online?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    No, you will be under the supervision and also the teachings
                    of Finnish language expert teachers. You be learning with
                    other students as well, possibly other parts of the world.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 42}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(42)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Do I need to know Finnish to study at a vocational school in
                    Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Many programs are offered in English, but mastering Finnish
                    opens doors to more options and enhances your integration.
                    Check specific program requirements and consider preparatory
                    courses if needed.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 43}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(43)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can I work while studying at a vocational school?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Absolutely! Part-time work is encouraged and often
                    integrated into programs through internships or practical
                    training. This valuable experience strengthens your resume
                    and career prospects.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 44}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(44)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What financial aid options are available for international
                    students?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Scholarships, grants, and student loans are given by Finnish
                    institutions and organizations like Edufi. Research options
                    early and explore possibilities based on your chosen program
                    and background.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 45}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(45)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the career opportunities after graduating from a
                    Finnish vocational school?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Finland boasts a high employment rate for vocational
                    graduates. Your acquired skills are highly sought-after,
                    paving the way for immediate job entry, further studies, or
                    even entrepreneurship in diverse fields.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            <div className={openTestTab === 5 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 32}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(32)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    At what stage/point can a student apply to join high school
                    in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Between February and Mid-March. A student must have
                    completed three (3) levels of language course by March then
                    apply for high school. This is the general intake, but when
                    some schools don&#39;t have enough applications, they will
                    keep open the rolling intake all year round for the students
                    to apply. (Rolling intake opens around May).
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 33}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(33)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    At what stage/point can a student apply to vocational school
                    in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    The intake for the vocational school is all-round the year.
                    This means once you are done with your language course. At
                    the 6 th month (3 rd level) you can begin applying to
                    partner school for entrance exam unlike the high school
                    which there are rolling intake between February and Mid-
                    March then May and Mid-June After acceptance to join high
                    school/Vocational School, student will continue with level
                    four (4) of the language course while applying for residence
                    permit. Level 4 is mainly to review the language and to help
                    students better understand the environment, culture, and way
                    of life in Finland. FF program will assist on the
                    application.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 34}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(34)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Is the program paying for the living costs of the students
                    while in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    No, parents/guardians are responsible to pay for the living
                    costs. According to the Finnish immigration department,
                    student’s living costs also known as Proof OF Funds
                    (accommodation, meals, and transport) is estimated at EUR
                    6,720 per year. However, there are few schools that provide
                    opportunity of free accommodation in school year or host
                    family.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 35}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(35)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the monthly rent range for student apartments?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Free to EUR 285 per month. On average most of schools range
                    from EUR 180 to 220 per month.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 36}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(36)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Is there a possibility that a Finnish School might not
                    accept a student, even though he/she has done and passed the
                    Language Exams?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Students can apply to up to 7 schools; therefore, it is VERY
                    UNLIKELY that they wouldn&#39;t get into any of them. In
                    case a student would not get into any school that they wish,
                    the students can continue applying for the rolling intake
                    (see question no. 12 for similar information).
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            <div className={openTestTab === 6 ? "block" : "hidden"}>
              <Fragment>
                <Accordion
                  open={open === 37}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(37)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can a student learn Finnish language course through other
                    channels then do exams with the program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    No. Finest Future Finnish Language Program has its own
                    unique course that a student must go through before taking
                    the exams that are also prepared according to the program.
                    Besides language training, we also combine much other
                    knowledge of culture, how to integrate into society and
                    equip the students with the skills they need in High School
                    and Vocational Studies like communication skills, team work
                    skills, presentation etc.
                  </AccordionBody>
                </Accordion>

                <Accordion
                  open={open === 38}
                  className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg "
                >
                  <AccordionHeader
                    onClick={() => handleOpen(38)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What is the application Timeline?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    a. Call for applications: Anytime. Application fee of 100
                    EUR is to be paid before interview. The fee includes one (1)
                    retake. For consecutive retakes, the applicant shall pay an
                    additional 50 EUR per retake. b. Entry Interview: Weekly;
                    every Tuesday for High School and Wednesday for Vocational
                    studies, c. Selected applicant approval or rejection: Within
                    one week of interview.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 39}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(39)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    Can a student postpone an acceptance letter?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    No. The acceptance letter cannot be postponed but postponing
                    arrival to Finland is possible.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 40}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(40)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the minimum academic qualifications required when
                    applying for the program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    GPA of at least 7.0 of lower secondary. You can provide
                    academic reports for already finished lower secondary
                    school.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 41}
                  className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(41)}
                    className="border border-white text-base font-medium text-[#170F49]"
                  >
                    What are the minimum academic qualifications required when
                    applying to join high school in Finland?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    The principal&#39;s in Finland are looking at the cumulative
                    GPA from lower secondary. In Finland all of the grades from
                    7th and 8th grade are added to the grades of 9th grade
                    (final grade before entering High School). By the time they
                    come to Finland, they need to have graduated from lower
                    secondary school in their home country - Basically they need
                    to be eligible for High School in their home country. But
                    when first applying to a high school in Finland, their GPA
                    and Finnish level are the most important factors. We
                    recommend having a GPA of at least 7.0
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            {/* <div className={openTestTab === 7 ? "block" : "hidden"}>
              <Fragment>
                <Accordion open={open === 41} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(42)} className='border border-white text-base font-medium text-[#170F49]'>
                    What are the monthly rates on the loan?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    As mentioned above, banks give rates based on your eligibility and they are usually between
                    2.5-3.5 % per month.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 43} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(43)} className='border border-white text-base font-medium text-[#170F49]'>
                    What is the repayment time for the loan?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    Repayment time usually Is between 12-18 months.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 44} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(44)} className='border border-white text-base font-medium text-[#170F49]'>
                    What is loan process?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    First student indicates interest to study the language. Then pays for the evaluation of
                    candidates’ English proficiency and soft skills. Once the student passes, an email from Finest Future
                    will be sent. Once you receive that email then you can proceed to apply for loan. If you fail, your
                    first re-take is free and subsequent ones are paid ( 50€ ) for each re-take
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 45} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(45)} className='border border-white text-base font-medium text-[#170F49]'>
                    What are the criteria to get a loan?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Various Banks with various policies. Two main factors are: <br />
                    Guarantor Loan: The guarantor must be a working-class citizen of your country. The
                    loan to be given will be 50% of the yearly income if guarantor earns 5000€ a year then
                    2500-euro loan will be offered.<br />
                    Collateral Loan: The loan will be given based on properties worth more than the
                    principal loan. Most cases are vehicles worth more than the loan you request for. You
                    can negotiate with banks for more information.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 46} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(46)} className='border border-white text-base font-medium text-[#170F49]'>
                    Do you provide loans?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                    Please note, we are not a financial institution that offers loans, you only apply through us to
                    our partner banks.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div> */}
          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
    </>
  );
}

export default Faq;
