import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import BacheloerProgram from "./BacheloerProgram";
import MasterProgramList from "../../Screens/Admin/MasterProgramList";
import MastersProgram from "./MastersProgram";

const StudyProgram = () => {
  const navigate = useNavigate();

  const initialState = {
    bachelorElement: true,
    mastersElement: false,
  };

  const [programValues, setProgramValues] = useState({
    ...initialState,
  });

  const handleBachelorState = () => {
    // e.preventDefault();
    setProgramValues({
      bachelorElement: true,
      mastersElement: false,
    });
  };

  const handleMastersState = () => {
    // e.preventDefault();
    setProgramValues({
      bachelorElement: false,
      mastersElement: true,
    });
  };

  const showProgramConnector = () => {
    return (
      <>
        {/* show payout */}
        {programValues.bachelorElement && (
          <>
            <div className="">
              <BacheloerProgram />
            </div>
          </>
        )}

        {/* show payout request */}
        {programValues.mastersElement && (
          <>
            <div className="">
              <MastersProgram/>
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <div>
      <Navbar />

      <div className="pt-[80px]">
        <div className="bg-[#098A3C] relative py-6 sm:px-16 md:px-14 px-6">
          <div
            className=" absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg md:h-40 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {" "}
            ←{" "}
          </div>

          <div className="flex items-center md:mt-0 mt-10 gap-7">
            <img src="/images/litStudyHero.svg" className="md:block hidden" alt="" />
            <h5 className="text-white md:text-start text-center text-[30px] md:text-[60px] ">Study Program</h5>
          </div>
        </div>

        <div className="md:pt-20 pt-10 flex justify-center">
          <div className="max-w-screen-[1500px] w-full mx-auto px-8 lg: ">
            <div className="md:flex gap-6">
              <div className="flex md:flex-col gap-3 mb-6">
                <div
                  className={`${
                    programValues.bachelorElement
                      ? "bg-[#098A3C] rounded-[10px] text-[16px] lg:text-[20px] text-white "
                      : "border-b-[0]"
                  }  text-center md:px-8 px-2 hover:text-white whitespace-nowrap w-full py-2 inline-block text-[16px] lg:text-[20px] font-semibold hover:rounded-[10px] hover:bg-[#036E03]/[80%] cursor-pointer`}
                  onClick={() => handleBachelorState()}
                >
                  Bachelor's Degree
                </div>

                <div
                  className={`${
                    programValues.mastersElement
                      ? "bg-[#098A3C] rounded-[10px] text-[16px] lg:text-[20px] text-white "
                      : "border-b-[0]"
                  }  text-center md:px-8 px-2 hover:text-white whitespace-nowrap w-full py-2 inline-block text-[16px] lg:text-[20px] font-semibold hover:rounded-[10px] hover:bg-[#036E03]/[80%] cursor-pointer`}
                  onClick={() => handleMastersState()}
                >
                  Masters Degree
                </div>
              </div>

                {/* Vertical Line */}
                <div className="md:block hidden">
  <div className="h-[500px] w-[2px] bg-gray-300"></div>
  </div>

              <div className="w-full">{showProgramConnector()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyProgram;
